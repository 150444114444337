// @ts-nocheck
import React from 'react';
import {Pressable, View, StyleSheet} from 'react-native';
import {colorSwatches} from '../styles';
import Text from './Text';
import WebcamCapture from '../modules/Auth/components/WebcamCapture';
import {Center, Modal} from 'native-base';
import FeatherIcon from 'react-native-vector-icons/Feather';

interface I_PhotoCaptureSheet_Props {
  isPanelActive: boolean;
  setIsActive: Function;
  onConfirmImage: Function;
  cropping?: boolean;
}

const PhotoCaptureSheet = ({
  isPanelActive,
  setIsActive,
  onConfirmImage,
  cropping = false,
}: I_PhotoCaptureSheet_Props) => {

  const showGalleryPicker = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png, image/jpeg';

    input.onchange = e => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = event => {
        const data = event.target.result;
        //@ts-ignore
        onConfirmImage(data.split(',')[1]);
      };
    };
    input.click();
  };

  return (
    <Modal isOpen={isPanelActive} onClose={() => setIsActive(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Update picture</Modal.Header>
        <View style={styles.container}>
          <Center style={styles.center}>
            <Pressable
              style={styles.pressable}
              onPress={() => {
                setIsActive(false);
                showGalleryPicker();
              }}>
              <FeatherIcon name="upload-cloud" size={50} />
              <Text>Choose file</Text>
            </Pressable>
            <WebcamCapture
              onCapture={imgData => {
                setIsActive(false);
                onConfirmImage(imgData);
              }}
            />
          </Center>
        </View>
      </Modal.Content>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    marginLeft: 12,
  },
  center: {
    marginTop: 12,
  },
  pressable: {
    borderWidth: 1,
    borderColor: colorSwatches.primary.color,
    borderRadius: 20,
    padding: 10,
    marginBottom: 20,
    width: 350,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dashed',
  },
});

export default PhotoCaptureSheet;
