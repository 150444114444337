import React, {useState} from 'react';
import {View, Linking, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {Menu, Card} from 'react-native-paper';
import {styles as globalStyles} from '../../../styles';
import {deriveDisplayName} from '../../../utils';
import IconButton from '../../../components/IconButton';
import {initiateSMS, initiateSendEmail} from '../../App/service';
import {showInformationMessage} from '../../../service/flashMessage';
import ZoomableImage from '../../../components/ZoomableImage';

const DirectoryItem = ({unitPeopleLink, currentUser}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const imageUrl = unitPeopleLink?.profilePicture
    ? `data:image/jpeg;base64,${unitPeopleLink?.profilePicture}`
    : 'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png';
  const phoneNumber = unitPeopleLink?.person.phone
    ? unitPeopleLink?.person.phone
    : unitPeopleLink.phone;
  const phoneAvailable = !!phoneNumber;
  const email = unitPeopleLink?.person.email
    ? unitPeopleLink?.person.email
    : unitPeopleLink.email;
  const emailAvailable = !!email;
  const isCurrentUser = currentUser === unitPeopleLink?.person?.id;

  return (
    <Card style={styles.card}>
      <View style={styles.container}>
        <ZoomableImage
          title={deriveDisplayName(unitPeopleLink)}
          style={styles.userImage}
          source={{
            uri: imageUrl,
          }}
        />
        <View style={styles.infoContainer}>
          <View style={styles.row}>
            <Text style={styles.displayName}>
              {deriveDisplayName(unitPeopleLink)}
            </Text>
            {isCurrentUser && (
              <View style={styles.currentUser}>
                <Text style={styles.currentUserText} numberOfLines={1}>
                  You
                </Text>
              </View>
            )}
          </View>
          {deriveDisplayName(unitPeopleLink?.person) !== ' ' && (
            <Text style={styles.profileName}>
              {`Profile name-  ${deriveDisplayName(unitPeopleLink?.person)}`}
            </Text>
          )}
          <Text style={styles.peopleType}>
            {unitPeopleLink?.peopleType?.name?.toUpperCase()}
          </Text>
        </View>
        <View style={styles.menuContainer}>
          <Menu
            visible={menuVisible}
            onDismiss={() => setMenuVisible(false)}
            anchor={
              <IconButton
                icon="ellipsis-vertical"
                iconColor={'#718096'}
                size={24}
                onPress={() => setMenuVisible(true)}
              />
            }>
            <>
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  Linking.openURL(`tel:${unitPeopleLink?.person?.phone}`);
                }}
                title={phoneAvailable ? 'Call' : 'Phone number N/A'}
                disabled={!phoneAvailable}
              />
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  initiateSMS(unitPeopleLink?.person?.phone);
                }}
                title={phoneAvailable ? 'Send Text' : 'Phone number N/A'}
                disabled={!phoneAvailable}
              />
            </>
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                initiateSendEmail(unitPeopleLink?.person?.email, '', '');
              }}
              title={emailAvailable ? 'Send Email' : 'Email addres N/A'}
              disabled={!emailAvailable}
            />
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                showInformationMessage('Will trigger approval process');
              }}
              title={'Add to my unit'}
            />
          </Menu>
        </View>
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    marginVertical: 8,
  },
  container: {
    flexDirection: 'row',
    padding: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoContainer: {
    flex: 1,
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  row: {
    flexDirection: 'row',
  },
  displayName: {
    fontSize: 18,
    flex: 1,
  },
  currentUser: {
    backgroundColor: '#E2E8F0',
    borderRadius: 4,
    paddingHorizontal: 4,
    marginHorizontal: 4,
  },
  currentUserText: {
    fontSize: 12,
  },
  profileName: {
    flex: 1,
  },
  peopleType: {
    fontSize: 12,
    marginVertical: 4,
  },
  userImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  menuContainer: {
    marginTop: 12,
    marginRight: 16,
  },
});

export default DirectoryItem;
