import React from 'react';
import { ScrollView, View, StyleSheet } from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';

const Welcome = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <View style={styles.innerView}>
          <Heading>Welcome to Asterix Community App. </Heading>
          <Text style={styles.text}>
            Click Create account to set up a new account. If you already have an
            account, click Sign in
          </Text>
        </View>
      </ScrollView>
      <HStack>
        <Button
          onPress={() => navigation.navigate('Account Setup Info')}
          style={styles.button}>
          Create account
        </Button>
        <Button
          style={[styles.button, styles.outlineButton]}
          onPress={() => navigation.navigate('Sign In')}
          variant="outline">
          Sign in
        </Button>
      </HStack>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
    marginTop: 48,
  },
  innerView: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  text: {
    marginTop: 32,
    fontSize: 18,
  },
  button: {
    marginHorizontal: 8,
    flex: 1,
  },
  outlineButton: {
    // Add any additional styles for the outline button if needed
  },
});

export default Welcome;
