// @ts-nocheck
import {Dispatch} from 'redux';
import PushNotification from 'react-native-push-notification';
import {Platform} from 'react-native';

import {Token, User} from './types';
import {signIn} from '../../api/people';
import {
  showErrorMessage,
  showInformationMessage,
  showSuccessMessage,
} from '../../service/flashMessage';
import {E_STORAGE_KEYS} from '../../@types';
import {residentApiWithAuth} from '../../api/resident';
import * as RootNavigation from '../../navigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {deleteAccount, deregisterDevice, registerDevice} from './api';
import {storeRememberMe, storeUser} from './service';
import isWeb from '../../utils/isWeb';
import getConfig from '../../utils/getConfig';
import {UserCreds} from '../../screens/more/types';

export const AUTH_SIGN_OUT_SUCCESS = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_LOADING = 'AUTH_SIGN_OUT_LOADING';
export const AUTH_SIGN_OUT_FAIL = 'AUTH_SIGN_OUT_LOADING';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_LOADING = 'AUTH_SIGN_IN_LOADING';
export const AUTH_SIGN_IN_FAIL = 'AUTH_SIGN_IN_FAIL';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_UPDATE_PROFILE_SUCCESS = 'AUTH_UPDATE_PROFILE_SUCCESS';
export const AUTH_UPDATE_PROFILE_LOADING = 'AUTH_UPDATE_PROFILE_LOADING';
export const AUTH_UPDATE_PROFILE_FAIL = 'AUTH_UPDATE_PROFILE_FAIL';
export const AUTH_SET_VERIFICATION_STATUS = 'AUTH_SET_VERIFICATION_STATUS';
export const AUTH_AGREE_TO_POLICY = 'AUTH_AGREE_TO_POLICY';

export const AUTH_SET_LAST_PERMISSION_CHECK = 'AUTH_SET_LAST_PERMISSION_CHECK';

export interface AuthSignOutLoading {
  type: typeof AUTH_SIGN_OUT_LOADING;
  error?: null;
}
export interface AuthSignOutSuccess {
  type: typeof AUTH_SIGN_OUT_SUCCESS;
}
export interface AuthSignOutFail {
  type: typeof AUTH_SIGN_OUT_FAIL;
  error: string | object;
}
export interface AuthSignInLoading {
  type: typeof AUTH_SIGN_IN_LOADING;
  loading: boolean;
  error?: null;
}
export interface AuthSignInSuccess {
  type: typeof AUTH_SIGN_IN_SUCCESS;
  user: User;
}
export interface AuthSignInFail {
  type: typeof AUTH_SIGN_IN_FAIL;
  error: string | object;
}

export interface AuthUpdateProfileFail {
  type: typeof AUTH_UPDATE_PROFILE_FAIL;
  error: string | object;
}
export interface AuthUpdateProfileLoading {
  type: typeof AUTH_UPDATE_PROFILE_LOADING;
  error?: null;
}
export interface AuthUpdateProfileSuccess {
  type: typeof AUTH_UPDATE_PROFILE_SUCCESS;
  user: User;
}

export interface AuthSetToken {
  type: typeof AUTH_SET_TOKEN;
  token: Token;
}

interface CellPhoneConfirmed {
  cellPhoneConfirmed: boolean;
}

interface EmailConfirmed {
  emailConfirmed: boolean;
}

export interface AuthSetVerificationStatus {
  type: typeof AUTH_SET_VERIFICATION_STATUS;
  status: CellPhoneConfirmed | EmailConfirmed;
}

export interface AuthAgreeToPolicy {
  type: typeof AUTH_AGREE_TO_POLICY;
}

export interface LastPermissionCheck {
  type: typeof AUTH_SET_LAST_PERMISSION_CHECK;
  lastPermmisionCheck: number;
}

export type AuthDispatchTypes =
  | AuthSignOutLoading
  | AuthSignOutSuccess
  | AuthSignOutFail
  | AuthSignInLoading
  | AuthSignInSuccess
  | AuthSignInFail
  | AuthUpdateProfileLoading
  | AuthUpdateProfileFail
  | AuthUpdateProfileSuccess
  | AuthSetToken
  | AuthSetVerificationStatus
  | AuthAgreeToPolicy
  | LastPermissionCheck;


export const SignIn =
  (username: string, password: string, rememberMe: boolean) =>
  async (dispatch: Dispatch<AuthDispatchTypes>) => {
    try {
      dispatch({type: AUTH_SIGN_IN_LOADING, loading: true});
      const user: User = await signIn(username, password);

      if (user) {
        // Store user in local storage for rehydrating Redux later
        await AsyncStorage.setItem(E_STORAGE_KEYS.USER, JSON.stringify(user));

        //save token
        await AsyncStorage.setItem(
          E_STORAGE_KEYS.AUTH_TOKEN,
          JSON.stringify({
            token: user.token,
            refreshToken: user.refreshToken,
          }),
        );

        //Save rememberMe
        await storeRememberMe(rememberMe.toString());
        // console.log('Should remember me', rememberMe);
        if (rememberMe) {
          await storeUser({username, password, id: user.id});
        }

        // Register the user for push notifications
        if (!isWeb) {
          const Pushy = require('pushy-react-native').default;

          await Pushy.register()
            .then(async deviceToken => {
              console.log('RES', deviceToken);
              //save token in asyncStorage
              await AsyncStorage.setItem(
                E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN,
                JSON.stringify({
                  deviceToken,
                }),
              );

              await registerDevice(user.token, deviceToken);
            })
            .catch(err => {
              showErrorMessage(err.message);
            });
        } else {
          const Pushy = require('pushy-sdk-web');

          await Pushy.register({appId: getConfig('MY_WORKS_APP_ID')})
            .then(async deviceToken => {
              console.log('RES', deviceToken);
              //save token in asyncStorage
              await AsyncStorage.setItem(
                E_STORAGE_KEYS.PUSHY_DEVICE_TOKEN,
                JSON.stringify({
                  deviceToken,
                }),
              );

              await registerDevice(user.token, deviceToken);
            })
            .catch(err => {
              console.log('err.message', err.message);
              showErrorMessage(err.message);
            });
        }

        //update redux
        dispatch({type: AUTH_SIGN_IN_SUCCESS, user: user});
        dispatch({
          type: AUTH_SET_TOKEN,
          token: {token: user.token, refreshToken: user.refreshToken},
        });
      }
    } catch (error) {
      dispatch({type: AUTH_SIGN_IN_FAIL, error: error});
      showErrorMessage(error.message);
    }
  };

export const UpdateUser =
  (user: User, userId: string) =>
  async (dispatch: Dispatch<AuthDispatchTypes>) => {
    const updatedUserObj = Object.assign({}, user);
    // delete updatedUserObj.driversLicenseImage;

    try {
      dispatch({type: AUTH_UPDATE_PROFILE_LOADING});

      let res = await residentApiWithAuth().post(
        `/people/${userId}`,
        updatedUserObj,
      );
      const result = res.data;
      if (result.errors) {
        dispatch({type: AUTH_UPDATE_PROFILE_FAIL, error: result.errors[0]});
        showErrorMessage(result.errors[0]);
      } else {
        dispatch({
          type: AUTH_UPDATE_PROFILE_SUCCESS,
          user: {
            ...result?.data,
            profilePicture: updatedUserObj.profilePicture,
            email: updatedUserObj?.email,
          },
        });
        RootNavigation.goBack();
      }
    } catch (error) {
      dispatch({type: AUTH_UPDATE_PROFILE_FAIL, error});
      showErrorMessage(error?.response?.data?.errors[0] || error.message);
    }
  };

export const VerifyCode =
  (type: string, code: string, userId: string) =>
  async (dispatch: Dispatch<AuthDispatchTypes>) => {
    try {
      const res = await residentApiWithAuth().post(
        `/people/confirm${type}/${userId}/${code}`,
      );
      const result = res.data;
      if (result.errors) {
        showErrorMessage(result.errors[0]);
      } else {
        const confirmedKey =
          type === 'Email' ? 'emailConfirmed' : 'cellPhoneConfirmed';
        const confirmed = result.data;
        dispatch({
          type: AUTH_SET_VERIFICATION_STATUS,
          status: {
            [confirmedKey]: confirmed,
          },
        });
        showSuccessMessage(`${type} Confirmed`);
      }
    } catch (error) {
      showErrorMessage(`${error}`);
    }
  };

export const SignOut = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
  try {
    await deregisterDevice();
  } catch (error) {
    showInformationMessage(error.message);
    dispatch({type: AUTH_SIGN_OUT_FAIL, error: error});
  } finally {
    await AsyncStorage.removeItem(E_STORAGE_KEYS.AUTH_TOKEN);
    PushNotification.cancelAllLocalNotifications();
    dispatch({type: E_STORAGE_KEYS.CLEAR_REDUX_PERSIST_ROOT});
    showInformationMessage('Please sign in again to continue using the app');
  }
};

export const DeleteAccount =
  (userCreds: UserCreds) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    try {
      await deleteAccount(userCreds);
      await AsyncStorage.removeItem(E_STORAGE_KEYS.AUTH_TOKEN);
      PushNotification.cancelAllLocalNotifications();
      dispatch({type: E_STORAGE_KEYS.CLEAR_REDUX_PERSIST_ROOT});
      showSuccessMessage('Account deleted successfully');
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

export const RefreshToken =
  (token: Token) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    try {
      dispatch({type: AUTH_SET_TOKEN, token});
    } catch (error) {}
  };

  export const AgreeToPolicy = () => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    dispatch({type: AUTH_AGREE_TO_POLICY});
    AsyncStorage.setItem(E_STORAGE_KEYS.AGREE_TO_POLICY, 'true');
  };

  export const SetLastPermissionCheck = (lastPermmisionCheck: number) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    dispatch({type: AUTH_SET_LAST_PERMISSION_CHECK, lastPermmisionCheck});
  };

interface DefaultStateI {
  user?: User;
  token: Token;
  loading: boolean;
  error?: string | object;
  updateProfileLoading?: boolean;
  updateProfileError?: string | object;
  agreedToPolicy: boolean;
  lastPermmisionCheck?: number;
}

const defaultState: DefaultStateI = {
  loading: false,
  token: null,
  user: null,
  updateProfileLoading: false,
  updateProfileError: null,
  agreedToPolicy: false,
  lastPermmisionCheck: null,
};

const authReducer = (
  state: DefaultStateI = defaultState,
  action: AuthDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case AUTH_SIGN_OUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AUTH_SIGN_OUT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_SIGN_IN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AUTH_SIGN_IN_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case AUTH_SET_TOKEN:
      return {
        ...state,
        token: action.token,
        loading: false,
      };
    case AUTH_UPDATE_PROFILE_LOADING:
      return {
        ...state,
        updateProfileLoading: true,
      };
    case AUTH_UPDATE_PROFILE_FAIL:
      return {
        ...state,
        updateProfileError: action.error,
        updateProfileLoading: false,
      };
    case AUTH_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileLoading: false,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    case AUTH_SET_VERIFICATION_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.status,
        },
      };
    case AUTH_SIGN_OUT_SUCCESS:
      return {
        ...state,
        token: null,
        loading: false,
        user: null,
      };
    case AUTH_AGREE_TO_POLICY:
    return {
      ...state,
      agreedToPolicy: true,
    }
    case AUTH_SET_LAST_PERMISSION_CHECK:
      return {
        ...state,
        lastPermmisionCheck: action.lastPermmisionCheck,
      };
    default:
      break;
  }
  return state;
};

export default authReducer;
