// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Linking,
  StyleSheet,
  useWindowDimensions,
  View,
  Text,
} from 'react-native';
import {TouchableRipple} from 'react-native-paper';
import {useDispatch} from 'react-redux';
import {Alert} from '../types';
import {EventNames} from '../../../@types/EventNames';
import {residentApiWithAuth} from '../../../api/resident';
import Avatar from '../../../components/Avatar';
import useColorMode from '../../../components/useColorMode';
import {VISITOR_SCREEN_TIMEOUT_DURATION} from '../../../constants';
import isWeb from '../../../utils/isWeb';
import ResponseBadge from './ReponseBadge';
import {HandleAlertButton} from '../alertsReducer';

const NotificationsItem = props => {
  const notification: Alert = props.notification;
  const {clickHandler} = props;
  const {colorMode} = useColorMode();
  const dispatch = useDispatch();
  const color = colorMode === 'light' ? '#fff' : '#000';
  const message = notification.message;
  const messageParts = message?.split(' ');
  let person = '';
  if (messageParts) {
    person = messageParts[0];
  }

  const insUnread = notification.read !== true;
  const [alertTimedOut, setAlertTimedOut] = useState(false);
  const [buttonsShown, setButtonsShown] = useState(false);
  const [image, setImage] = useState(null);
  const [loadingImage, setImageLoading] = useState(true);
  const {width} = useWindowDimensions();
  const buttonContainerWidth = width - 84;

  let textColor = '';
  if (colorMode === 'dark') {
    textColor = insUnread ? styles.textWhite : styles.textGray500;
  } else {
    textColor = insUnread ? styles.textBlack : styles.textGray500;
  }

  let action = notification.action;

  useEffect(() => {
    const isVisitorScreen =
      notification.eventName === EventNames.VISITOR_SCREEN;
    const repliedTo = !!action;
    const receivedInLast15Mins =
      new Date().getTime() - new Date(notification.time).getTime() >
      VISITOR_SCREEN_TIMEOUT_DURATION;

    if (isVisitorScreen) {
      if (receivedInLast15Mins && !repliedTo) {
        setAlertTimedOut(true);
        setButtonsShown(false);
      } else {
        setAlertTimedOut(false);
        setButtonsShown(true);
      }
    }
  }, [notification]);

  useEffect(() => {
    fetchImage(notification.id);
  }, [notification.id]);

  async function fetchImage(notificationId) {
    try {
      const res = await residentApiWithAuth().get(
        `/alertDetail/person/image/${notificationId}`,
      );
      const data = await res.data.data;
      setImage(data.image);
    } catch (error) {
    } finally {
      setImageLoading(false);
    }
  }

  return (
    <TouchableRipple
      onPress={() => clickHandler()}
      style={[
        notification.alertBackgroundColor !== null && {
          backgroundColor: notification.alertBackgroundColor,
        },
        styles.touchableRipple,
      ]}>
      <View style={styles.container}>
        <View>
          {loadingImage ? (
            <Avatar>
              <ActivityIndicator />
            </Avatar>
          ) : (
            <>
              {image ? (
                <Avatar
                  source={{
                    uri: `data:image/jpeg;base64,${image}`,
                  }}>
                  {notification.eventName.charAt(0)}
                </Avatar>
              ) : (
                <Avatar></Avatar>
              )}
            </>
          )}
        </View>
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.row}>
              {!!action && <ResponseBadge action={action} />}
              {alertTimedOut && (
                <View style={styles.timedOutContainer}>
                  <Text>Timed Out</Text>
                </View>
              )}
            </View>
            <View style={styles.messageContainer}>
              <Text style={[textColor, styles.messageText]}>
                {message}
              </Text>
            </View>
            <View style={styles.timeContainer}>
              <Text numberOfLines={5} style={[styles.timeText, textColor]}>
                {new Date(notification.time).toLocaleString()}
              </Text>
            </View>
            <View
              style={[
                styles.buttonContainer,
                {
                  width: buttonContainerWidth,
                  height: buttonsShown && !alertTimedOut ? 40 : 0,
                  marginBottom: buttonsShown && !alertTimedOut ? 12 : 0,
                },
              ]}>
              {notification.button1 && buttonsShown && (
                <TouchableRipple
                  onPress={() => {
                    setButtonsShown(false);
                    dispatch(
                      HandleAlertButton(
                        notification,
                        notification.button1.text,
                        parseInt(notification.button1.value, 10),
                      ),
                    );
                  }}>
                  <View style={styles.button1}>
                    <Text style={styles.buttonText}>
                      {notification.button1.text}
                    </Text>
                  </View>
                </TouchableRipple>
              )}
              {notification.button2 && buttonsShown && (
                <TouchableRipple
                  onPress={() => {
                    setButtonsShown(false);
                    dispatch(
                      HandleAlertButton(
                        notification,
                        notification.button2.text,
                        parseInt(notification.button2.value, 10),
                      ),
                    );
                  }}>
                  <View style={styles.button2}>
                    <Text style={styles.buttonText}>
                      {notification.button2.text}
                    </Text>
                  </View>
                </TouchableRipple>
              )}
              {notification.button3 &&
                (notification.phoneNumber !== '' ||
                  notification.phoneNumber !== null) &&
                notification.button3.text === 'Call' &&
                buttonsShown && (
                  <TouchableRipple
                    onPress={() =>
                      Linking.openURL(`tel:${notification.phoneNumber}`)
                    }>
                    <View style={styles.button3}>
                      <Text style={styles.buttonText}>
                        {notification.button3.text}
                      </Text>
                    </View>
                  </TouchableRipple>
                )}
            </View>
          </View>
        </View>
      </View>
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  touchableRipple: {
    paddingTop: 2,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
    paddingTop: 8,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
    marginLeft: 12,
  },
  timedOutContainer: {
    backgroundColor: '#eab308',
    marginBottom: 4,
    borderRadius: 16,
    textAlign: 'center',
    marginLeft: 16,
    paddingLeft: 4,
    paddingRight: 4,
  },
  messageContainer: {
    width: isWeb ? '55%' : '94%',
    display: 'flex',
  },
  messageText: {
    maxWidth: isWeb ? '75%' : 1000,
  },
  timeContainer: {
    width: isWeb ? '55%' : '94%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  timeText: {
    marginTop: 4,
    alignSelf: 'flex-end',
    fontSize: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
  },
  button1: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 8,
    backgroundColor: '#38a169',
    borderRadius: 4,
  },
  button2: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 8,
    backgroundColor: '#e53e3e',
    borderRadius: 4,
  },
  button3: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 8,
    backgroundColor: '#4299e1',
    borderRadius: 4,
  },
  buttonText: {
    color: '#fff',
    textAlign: 'center',
    marginRight: 8,
  },
  textWhite: {
    color: '#fff',
  },
  textGray500: {
    color: '#6b7280',
  },
  textBlack: {
    color: '#000',
  },
});

export default NotificationsItem;
