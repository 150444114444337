import React from 'react';
import { StyleSheet, Image, Pressable, ScrollView, View } from 'react-native';
import Container from '../../components/Container';
import { Navbar } from '../../components';
import { useNavigation } from '@react-navigation/native';
import { Card } from 'react-native-paper';
import Text from '../../components/Text';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Box from '../../components/Box';
import useColorModeValue from '../../components/useColorModeValue';
import VersionCode from '../../modules/Auth/components/VersionCode';
import { initiateSendEmail, openUrl } from '../../modules/App/service';

const About = () => {
  const navigation = useNavigation();

  return (
    <Container>
      <Navbar navigation={navigation} title="About" />
      <ScrollView showsHorizontalScrollIndicator={false}>
        <Card style={styles.card}>
          <Box bg={useColorModeValue('gray.50', 'gray.700')} style={styles.box}>
            <Pressable style={styles.pressable}>
              <View style={styles.row}>
                <FeatherIcon name="info" color="#718096" size={20} />
                <Text style={styles.text}>Version</Text>
              </View>
              <VersionCode />
            </Pressable>
            <Pressable
              onPress={() => initiateSendEmail('info@asterixsoftware.com', '', '')}
              style={styles.pressable}>
              <FeatherIcon name="mail" color="#718096" size={20} />
              <Text style={styles.text}>Email</Text>
            </Pressable>
            <Pressable
              style={styles.pressable}
              onPress={() => openUrl('https://www.asterixsoftware.com')}>
              <FeatherIcon name="globe" color="#718096" size={20} />
              <Text style={styles.text}>Website</Text>
            </Pressable>
            <Pressable
              style={styles.pressable}
              onPress={() => openUrl('https://www.youtube.com/@AsterixSoftware')}>
              <FeatherIcon name="youtube" color="#718096" size={20} />
              <Text style={styles.text}>Youtube</Text>
            </Pressable>
          </Box>
        </Card>

        <View style={styles.imageContainer}>
          <Image
            resizeMode="center"
            source={require('../../images/asterix_logo.png')}
            style={styles.image}
          />
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 8,
    marginTop: 16,
  },
  box: {
    paddingBottom: 8,
  },
  pressable: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 8,
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  text: {
    fontSize: 18,
    paddingLeft: 8,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {},
});

export default About;
