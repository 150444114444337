// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
  View,
  StyleSheet,
  PushNotificationIOS,
} from 'react-native';
import Text from '../../../components/Text';
import Icon from 'react-native-vector-icons/Feather';
import {useDispatch, useSelector} from 'react-redux';
import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import {styles as appStyles} from '../../../styles';
import VersionCode from '../components/VersionCode';
import {RootStore} from '../../../redux/store';
import {AUTH_SIGN_IN_LOADING, SignIn} from '../../Auth/reducer';
import {getRememberMe, getUser} from '../../../local';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Heading from '../../../components/Heading';
import Input from '../../../components/Input';
import {RootState} from '../../../redux/reducers';
import ScreenWrapper from '../components/ScreenWrapper';
import ScreenContent from '../components/ScreenContent';
import {useToast} from 'native-base';
import { E_NAVIGATION_DESTINATIONS } from '../../../@types';
import PushNotification from 'react-native-push-notification';
import Pushy from 'pushy-react-native';

const SignInScreen = () => {
  const navigation = useNavigation();

  const {params} = useRoute();
  const email = params?.email;
  const registeredUserName = !!email
    ? email
    : useSelector((state: RootState) => state.register?.username);

  const [userName, setUserName] = useState('');

  const [password, setPassword] = useState('');
  const [passwordHidden, setpasswordHidden] = useState(true);
  const [rememberMeChecked, setRememberMeChecked] = useState(false);

  const isLoading = useSelector((state: RootStore) => state.auth.loading);
  const dispatch = useDispatch();

  useFocusEffect(
    React.useCallback(() => {
      dispatch({type: AUTH_SIGN_IN_LOADING, loading: false});
      if (!registeredUserName) {
        updateStateWithLocalStore();
      }
    }, [registeredUserName]),
  );

  useEffect(() => {
    setUserName(registeredUserName);
    setPassword('');
  }, [registeredUserName]);

  const updateStateWithLocalStore = async () => {
    try {
      const rememberMeString = await getRememberMe();
      let rememberMe = rememberMeString === 'true' ? true : false;
      setRememberMeChecked(rememberMe);
      if (rememberMe) {
        const user = await getUser();
        if (user != null) {
          setUserName(user.username);
          setPassword(user.password);
          setpasswordHidden(true);
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleSubmit = () => {
    Keyboard.dismiss();
    dispatch(SignIn(userName?.trim(), password?.trim(), rememberMeChecked));
  };

  return (
    <ScreenWrapper>
      <ScreenContent>
        <View style={styles.headingContainer}>
          <Heading testID="SignScreenLabelText">Sign In </Heading>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.inputContainer}>
            <Input
              style={styles.input}
              onChangeText={text => setUserName(text)}
              value={userName}
              placeholder={'Enter username'}
              autoCorrect={false}
              autoCapitalize="none"
              InputLeftElement={
                <Icon color="gray" name="user" size={24} style={styles.icon} />
              }
              testID="LoginUserNameInput"
            />
          </View>
          <View style={styles.passwordContainer}>
            <Input
              style={styles.input}
              onChangeText={text => setPassword(text)}
              value={password}
              placeholder={'Enter password'}
              autoCorrect={false}
              autoCapitalize="none"
              secureTextEntry={passwordHidden}
              InputLeftElement={
                <Icon color="gray" name="lock" size={24} style={styles.icon} />
              }
              InputRightElement={
                <TouchableOpacity
                  onPress={() => setpasswordHidden(!passwordHidden)}>
                  <Icon
                    name={passwordHidden ? 'eye-off' : 'eye'}
                    size={24}
                    color="gray"
                    style={styles.icon}
                  />
                </TouchableOpacity>
              }
            />
          </View>
          <View style={styles.optionsContainer}>
            <View style={styles.checkboxContainer}>
              <Checkbox
                style={styles.checkbox}
                value={rememberMeChecked ? 'true' : 'false'}
                isChecked={rememberMeChecked}
                onChange={value => {
                  setRememberMeChecked(value);
                }}
                aria-label="Remember me"
              />
              <Text style={appStyles.checkBoxLabel}>Remember me</Text>
            </View>
            <Button
              onPress={() => handleSubmit()}
              isLoading={isLoading}
              isDisabled={isLoading}
              isLoadingText="Signing in ..."
              size="lg"
              py="3"
              style={styles.signInButton}>
              Sign in
            </Button>

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Button
                onPress={() =>
                  navigation.navigate(E_NAVIGATION_DESTINATIONS.FORGOT_PASSWORD)
                }
                variant="link"
                isDisabled={isLoading}
                size="lg"
                isLoadingText="Signing in ..."
                style={styles.linkButton}>
                Forgot password
              </Button>
              <Button
                variant="link"
                size="lg"
                style={styles.linkButton}
                onPress={() =>
                  navigation.navigate(E_NAVIGATION_DESTINATIONS.FORGOT_USERNAME)
                }>
                Forgot username
              </Button>
            </View>

            <Button
              onPress={() => navigation.navigate('Account Setup Info')}
              variant="link"
              size="lg"
              isDisabled={isLoading}
              isLoadingText="Signing in ..."
              style={styles.linkButton}>
              Don't have an account? Create here
            </Button>
            <Button
              onPress={() => {
                navigation.navigate('Trainning Video');
              }}
              variant="link"
              size="lg"
              isDisabled={isLoading}
              style={styles.linkButton}>
              Install Help, Tap Here
            </Button>
          </View>
        </View>
        <VersionCode />
      </ScreenContent>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  headingContainer: {
    marginHorizontal: 24,
    marginTop: 32,
  },
  contentContainer: {
    paddingTop: 64,
    paddingBottom: 32,
    paddingHorizontal: 16,
    flex: 1,
    justifyContent: 'space-around',
  },
  inputContainer: {
    paddingTop: 64,
  },
  input: {
    flex: 1,
    marginLeft: 12,
    fontSize: 18,
  },
  icon: {
    margin: 8,
  },
  passwordContainer: {
    marginTop: 48,
    justifyContent: 'space-between',
    flex: 1,
  },
  optionsContainer: {
    paddingVertical: 8,
    marginTop: 32,
    justifyContent: 'space-between',
  },
  checkboxContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  checkbox: {
    marginTop: 8,
    marginRight: 4,
  },
  signInButton: {
    marginTop: 48,
  },
  linkButton: {
    marginTop: 24,
  },
});

export default SignInScreen;
