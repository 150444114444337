import React, { useState } from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import { RegisterActionBar, RegisterPrevButton } from '../../../components';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import { residentApiWithAuth } from '../../../api/resident';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/store';
import Ionicons from 'react-native-vector-icons/Ionicons';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import { useForm } from 'react-hook-form';
import TextInput from '../../../components/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';

const emailConfirmationValidationSchema = yup.object().shape({
  emailConfirmationCode: yup.string().required('Code is required'),
});

const EmailConfirmation = ({ navigation, route }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const registerValues = useSelector((state: RootStore) => state?.register);
  const { personId } = route.params;
  const { control, handleSubmit } = useForm({
    defaultValues: {
      emailConfirmationCode: '',
    },
    resolver: yupResolver(emailConfirmationValidationSchema)
  });

  const { width } = useWindowDimensions();

  const resendEmailCode = async () => {
    setIsResending(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/sendEmailCode/${personId}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('The code has been sent to your email');
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsResending(false);
    }
  };

  const confirmEmailCode = async ({ emailConfirmationCode }: { emailConfirmationCode: string }) => {
    setIsConfirming(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/confirmEmail/${personId}/${emailConfirmationCode}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('Phone confirmed successfully');
        navigation.navigate('Success');
      }
    } catch (error) {
      showErrorMessage('Invalid code');
    } finally {
      setIsConfirming(false);
    }
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeAreaView,
          isWeb && { backgroundColor: '#ffffff', width: width / 3 },
        ]}>
        <ScrollView
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled">
          <RegisterActionBar
            step={5}
            hasSkip={true}
            onSkip={() => navigation.navigate('Success')}
          />
          <View style={styles.container}>
            <Heading size="lg">Email Confirmation</Heading>
            {isResending && (
              <View style={styles.sendingCodeContainer}>
                <Text style={styles.sendingCodeText}>
                  Sending code ...
                </Text>
              </View>
            )}

            <View style={styles.confirmationTextContainer}>
              <Ionicons
                name="checkmark-circle-outline"
                color="#47B275"
                size={24}
                style={styles.icon}
              />
              <Text style={styles.confirmationText}>
                {`Type the code you received via ${registerValues.email}.`}
              </Text>
            </View>

            <View style={styles.inputContainer}>
              <TextInput
                //@ts-ignore
                control={control}
                name="emailConfirmationCode"
                placeholder="e.g 1234X"
                label="Email code"
              />
              <Button
                onPress={() => resendEmailCode()}
                style={styles.resendButton}
                variant="link">
                Resend Code
              </Button>
            </View>
          </View>
        </ScrollView>
        <HStack style={styles.actionButtonsContainer}>
          <RegisterPrevButton />

          <Button
            // isDisabled={!isValid}
            onPress={handleSubmit(confirmEmailCode)}
            isLoading={isConfirming}
            isLoadingText="Confirming code ...."
            style={styles.nextButton}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    marginVertical: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  container: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  sendingCodeContainer: {
    flexDirection: 'row',
    marginTop: 32,
  },
  sendingCodeText: {
    marginBottom: 16,
    marginLeft: 8,
    fontSize: 18,
  },
  confirmationTextContainer: {
    flexDirection: 'row',
    marginTop: 32,
  },
  icon: {
    marginTop: 4,
  },
  confirmationText: {
    marginTop: 4,
    marginLeft: 8,
    fontSize: 18,
  },
  inputContainer: {
    marginTop: 8,
    flex: 1,
  },
  resendButton: {
    marginTop: 24,
    flex: 1,
  },
  actionButtonsContainer: {
    margin: 24,
    marginTop: 32,
  },
  nextButton: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default EmailConfirmation;
