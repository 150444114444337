// @ts-nocheck
import React, {useState} from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {View, ScrollView, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {Navbar} from '../../../components';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {Card} from 'react-native-paper';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import VStack from '../../../components/VStack';
import {
  checkUnitPeopleCode,
  linkPersonToUnitLink,
} from '../../People/peopleReducer';
import QRScanner from '../components/QRScanner';
import {useRoute} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import {GetUnitScreenData} from '../../Units/unitsReducer';
import {HStack} from 'native-base';
import {pasteFromClipboard} from '../../../service/clipboard';
import isWeb from '../../../utils/isWeb';
import Container from '../../../components/Container';
import FormContainer from '../../../components/FormContainer';
import {checkPeopleLinkCode} from '../../../api/connector';

const AddWithUnitCode = ({navigation}) => {
  const route = useRoute();
  //@ts-ignore
  const profileLinkCode = route?.params?.profileLinkCode
    ? route?.params?.profileLinkCode
    : '';
  const currentUnitLink = useSelector(
    (state: RootStore) =>
      state.units.unitLinks.filter(link => link.isDefault === true)[0],
  );
  const [personShown, setPersonShown] = useState(false);
  const [profileCode, setProfileCode] = useState(profileLinkCode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [person, setPerson] = useState(null);
  const [isSubmittingLink, setIsSubmittingLink] = useState(false);

  const dispatch = useDispatch();

  /**
   * Person requesting to be linked to a UnitPeopleLinkProfile
   * personId is current user(filled by backend)
   * unitPeopleLinkId(unitPeopleLink.id/ person.id)
   * @param person
   */
  const updateLink = async person => {
    try {
      const data = {
        unitPeopleLinkId: person.id,
      };
      //When linking as an owner, we want to popup allowAutomatedCalls dialog.

      await linkPersonToUnitLink(data);
      dispatch(GetUnitScreenData());
      showSuccessMessage('Link request sent');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  const checkUnitCodeValidity = async () => {
    if(profileCode?.toLowerCase()?.startsWith('pc') ) {
      checkPeopleCodeValidity();
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await checkUnitPeopleCode(profileCode);
      if (res) {
        setPerson(res);
        setPersonShown(true);

        setIsSubmittingLink(true);
        updateLink(res).then(() => {
          setIsSubmittingLink(false);
        });
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchCopiedText = async () => {
    const text = await pasteFromClipboard();
    setProfileCode(text);
  };

  const checkPeopleCodeValidity = async () => {
    setIsSubmitting(true);
    try {
      const connector = await checkPeopleLinkCode(profileCode);
      if (connector) {
        navigation.navigate('Link person to unit', {
          isFromNotificationTray: false,
          peopleId: connector.person.id,
          unitId: currentUnitLink.unit.id,
          isDirectLink: true,
        });
      }
    } catch (error) {
      showErrorMessage('Link code not found');
    } finally {
      setIsSubmitting(false);
    }
  };

  const PeopleItem = ({person}) => {
    return (
      <Card style={styles.card}>
        <View style={styles.cardContent}>
          <VStack>
            <Text style={styles.personName}>
              {person.personName ? person.personName : 'No name'}
            </Text>
            <Text style={styles.personType}>{person.peopleType}</Text>
            <Text style={styles.linkStatus}>
              {`Link status-${
                person.linkApproved ? person.linkApproved : 'Unknown'
              }`}
            </Text>
          </VStack>
          {person.isAvailable ? (
            <Button isLoading={isSubmittingLink} isLoadingText="Submitting">
              Link
            </Button>
          ) : (
            <Text>Already used</Text>
          )}
        </View>
      </Card>
    );
  };

  return (
    <Container hasDrawer>
      <Navbar navigation={navigation} title="Add with invite code" />
      <FormContainer>
        <ScrollView style={styles.scrollView}>
          {personShown ? (
            <>
              <View style={styles.successMessage}>
                <Ionicons
                  name="checkmark-circle-outline"
                  color="#47B275"
                  size={24}
                />
                <Text style={styles.successText}>
                  Code entered successfully .
                </Text>
              </View>
              {person && <PeopleItem person={person} key={person.id} />}
              <View style={styles.spacer} />
            </>
          ) : (
            <>
              <Text style={styles.label}>Type the code:</Text>
              <Input
                mt={4}
                mb={4}
                value={profileCode}
                onChangeText={text => setProfileCode(text)}
                InputRightElement={
                  <Button
                    variant="ghost"
                    startIcon={
                      <MaterialCommunityIcons
                        name="content-paste"
                        color="gray"
                        size={24}
                      />
                    }
                    onPress={fetchCopiedText}>
                    Paste
                  </Button>
                }
                style={styles.input}
              />
              <Text style={styles.orText}>or</Text>
              {!isWeb && (
                <QRScanner
                  setProfileCode={(qrCode: string) => {
                    setProfileCode(qrCode);
                  }}
                />
              )}
              <Text style={styles.infoText}>
                This code is how you link.
              </Text>
              <Text style={styles.infoText}>
                You can link your Profile in this app to units, your unit your
                friends units, a unit you are renting, and your permission in
                that unit determine what you can do there.
              </Text>
              <Text style={styles.infoText}>
                Putting code in here can also link a person to your unit. When
                you enter someone else's code here you will need to give them a
                type, such as Tenant, Guest, Vendor, or others. They (if
                approved by the office) are inked to your unit.
              </Text>
              <Text style={styles.infoText}>
                Use the Generate Link Code in the Others Page to Generate a code
                to give to management or others.
              </Text>
            </>
          )}
        </ScrollView>
        {!personShown && (
          <HStack>
            <Button
              onPress={() => checkUnitCodeValidity()}
              style={styles.submitButton}
              py="4"
              isLoading={isSubmitting}
              isLoadingText="Submitting ...">
              Submit
            </Button>
          </HStack>
        )}
      </FormContainer>
    </Container>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    marginVertical: 8,
  },
  cardContent: {
    flexDirection: 'row',
    padding: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  personName: {
    fontSize: 18,
  },
  personType: {
    padding: 8,
  },
  linkStatus: {
    padding: 8,
  },
  scrollView: {
    marginHorizontal: 16,
    marginBottom: 24,
    paddingBottom: 24,
  },
  successMessage: {
    flexDirection: 'row',
  },
  successText: {
    marginBottom: 16,
    marginLeft: 8,
  },
  spacer: {
    height: 96,
  },
  label: {
    marginTop: 4,
  },
  input: {
    fontSize: 24,
    textAlign: 'center',
  },
  orText: {
    marginVertical: 16,
    textAlign: 'center',
  },
  infoText: {
    fontSize: 18,
    marginTop: 12,
  },
  submitButton: {
    marginHorizontal: 16,
    marginBottom: 96,
    flex: 1,
  },
});

export default AddWithUnitCode;
