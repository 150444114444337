import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';

const RequireApproval = ({approvals, onClickApprovalsRequired}) => {
  const approvalsLength = approvals.length;
  return (
    <>
      {approvalsLength > 0 && (
        <TouchableOpacity
          style={styles.button}
          onPress={() => onClickApprovalsRequired()}>
          <Text style={styles.text}>
            {`${approvalsLength} approval${approvalsLength > 1 ? 's' : ''} required`}
          </Text>
        </TouchableOpacity>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingVertical: 8, // equivalent to py-2 in Tailwind
  },
  text: {
    color: 'black', // Add any other styles you need for the text
  },
});

export default RequireApproval;
