import {Dispatch} from 'redux';
import {RegistrationData} from '../../@types/RegistrationData';

export const SET_REGISTER_DATA = 'SET_REGISTER_ DATA';
export const RE_SET_REGISTER_DATA = 'RE_SET_REGISTER_ DATA';

export interface SetRegisterData {
  type: typeof SET_REGISTER_DATA;
  payload: RegistrationData;
}
export interface ResetRegisterData {
  type: typeof RE_SET_REGISTER_DATA;
}

export const setRegisterData =
  (payload: RegistrationData) =>
  async (dispatch: Dispatch<SetRegisterData>) => {
    dispatch({type: SET_REGISTER_DATA, payload});
  };

export const resetRegisterData =
  () => async (dispatch: Dispatch<ResetRegisterData>) => {
    dispatch({type: RE_SET_REGISTER_DATA});
  };

export type RegisterDispatchTypes = SetRegisterData | ResetRegisterData;

const defaultState: RegistrationData = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  phone1: '',
  driversLicenseImage: null,
  password: '',
  profilePicture: null,
  addressLine1: null,
  addressLine2: null,
  doNotBuildFaceTemplate: false,
  dlNumber: null,
  dateOfBirth: null,
  expiration: null,
  gender: null,
  linkCode: null,
  emailExists: null,
};

const registerReducer = (
  state: RegistrationData = defaultState,
  action: RegisterDispatchTypes,
): RegistrationData => {
  switch (action.type) {
    case SET_REGISTER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RE_SET_REGISTER_DATA:
      return {
        ...defaultState,
      };
    default:
      break;
  }
  return state;
};

export default registerReducer;
