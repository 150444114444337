// @ts-nocheck
import React, {useCallback, useState} from 'react';
import {Pressable, ScrollView, TouchableOpacity, View} from 'react-native';
import Text from '../../components/Text';
import {Card} from 'react-native-paper';
import useTailwind from '../../components/useTailwind';
import {colorSwatches, styles} from '../../styles';
import {useSelector} from 'react-redux';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Ionicon from 'react-native-vector-icons/Ionicons';
import Octicon from 'react-native-vector-icons/Octicons';
import {useDispatch} from 'react-redux';
import useColorModeValue from '../../components/useColorModeValue';
import {DeleteAccount, SignOut} from '../../modules/Auth/reducer';
import {RootStore} from '../../redux/store';
import {permissionToBool} from '../../utils';
import {E_NAVIGATION_DESTINATIONS, User} from '../../@types';
import {
  LoadingSpinner,
  NavbarWithAction,
  VerifyEmailAndPhone,
} from '../../components';
import VersionCode from '../../modules/Auth/components/VersionCode';
import Box from '../../components/Box';
import Accordion from '../../components/Accordion';
import {fetchUnitById} from '../../api/units';
import {Unit} from '../../@types/Unit';
import {useFocusEffect} from '@react-navigation/native';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Container from '../../components/Container';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import ZoomableImage from '../../components/ZoomableImage';
import InviteCodeGenerator from './components/InviteCodeGenerator';
import DeleteAccountModal from './components/DeleteAccountModal';
import {UserCreds} from './types';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import {RootState} from '../../redux/reducers';

const PersonCard = ({
  person,
  navigation,
}: {
  person: User;
  navigation: {navigate: Function};
}) => {
  
  const tailwind = useTailwind();

  return (
    <Card style={tailwind('mx-2 mt-4')}>
      <Box
        bg={useColorModeValue('gray.50', 'gray.700')}
        style={tailwind('pb-2')}>
        <Text style={styles.settingsLabel}>MY PROFILE</Text>
        <View style={tailwind('flex-row justify-between p-2')}>
          <Pressable
            onPress={() => {
              navigation.navigate(E_NAVIGATION_DESTINATIONS.PICTURE_ZOOM, {
                title: 'Profile picture',
                uri: person?.profilePicture
                  ? `data:image/jpeg;base64,${person?.profilePicture}`
                  : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
              });
            }}>
            <ZoomableImage
              title="Your profile"
              source={{
                uri: person?.profilePicture
                  ? `data:image/jpeg;base64,${person?.profilePicture}`
                  : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
              }}
              style={styles.settingsUserImage}
            />
          </Pressable>
          <View style={tailwind('flex-1 ml-4')}>
            <Text style={styles.userName}>{person?.firstName}</Text>
            <Text style={styles.email}>{person?.username}</Text>
            <Text style={styles.email}>{person?.email}</Text>
            <Text style={styles.email}>{person?.phone1}</Text>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => navigation.navigate('Edit Profile')}
              style={[
                tailwind('mb-2 px-8 py-2  rounded'),
                {backgroundColor: colorSwatches.primary.color},
              ]}>
              <Text style={tailwind('text-white text-center')}>Edit</Text>
            </TouchableOpacity>
          </View>
        </View>
        <VerifyEmailAndPhone person={person} />
      </Box>
    </Card>
  );
};

const UnitCard = ({
  navigation,
  unitName,
  picture,
  buildingName,
  displayName,
  onEditPress,
  loading,
}) => {
  const tailwind = useTailwind();

  const unitLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0];

  if (!unitLink) {
    return null;
  }

  return (
    <Card style={tailwind('mx-2 mt-4')}>
      <Box
        bg={useColorModeValue('gray.50', 'gray.700')}
        style={tailwind('pb-2')}>
        <Text style={styles.settingsLabel}>SELECTED UNIT</Text>
        {loading ? (
          <View style={{height: 80}}>
            <LoadingSpinner />
          </View>
        ) : (
          <View style={tailwind('flex-row justify-between p-2')}>
            <ZoomableImage
              title={unitName}
              source={
                picture
                  ? {uri: `data:image/jpeg;base64,${picture}`}
                  : require('../../images/unit.jpeg')
              }
              style={styles.settingsUnitImage}
            />
            <View style={tailwind('flex-1 ml-4')}>
              <Text style={styles.userName} numberOfLines={1}>
                {unitName}
              </Text>
              <Text style={[styles.userName, {fontSize: 13}]} numberOfLines={1}>
                {displayName}
              </Text>
              <Text numberOfLines={1} style={styles.email}>
                {buildingName}
              </Text>
            </View>
            {permissionToBool(unitLink?.unitPermission?.charAt(1)) && (
              <View>
                <TouchableOpacity
                  onPress={() => onEditPress()}
                  style={[
                    tailwind('mb-2 px-8 py-2 rounded'),
                    {backgroundColor: colorSwatches.primary.color},
                  ]}>
                  <Text style={tailwind('text-white text-center')}>Edit</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        )}
      </Box>
    </Card>
  );
};

const MenusSection = ({navigation}) => {
  const tailwind = useTailwind();
  const unitLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0];

  return (
    <Card style={tailwind('mx-2 mt-4')}>
      <Box
        bg={useColorModeValue('gray.50', 'gray.700')}
        style={tailwind('pb-2')}>
        <Text style={styles.settingsLabel}>MENUS</Text>
        {permissionToBool(unitLink?.unitPermission?.charAt(0)) && (
          <Pressable
            style={tailwind('flex-row py-3 px-2 justify-between')}
            onPress={() => navigation.navigate('Units')}>
            <>
              <View style={tailwind('flex-row')}>
                <FeatherIcon name={'home'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6')}>Units</Text>
              </View>
            </>
          </Pressable>
        )}
        {permissionToBool(unitLink?.peoplePermission?.charAt(0)) && (
          <Pressable
            style={tailwind('flex-row py-3 px-2 justify-between')}
            onPress={() => navigation.navigate('People')}>
            <>
              <View style={tailwind('flex-row')}>
                <FeatherIcon name={'users'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6')}>People</Text>
              </View>
            </>
          </Pressable>
        )}
        {permissionToBool(unitLink?.deliveryPermission?.charAt(0)) && (
          <Pressable
            style={tailwind('flex-row py-3 px-2 justify-between')}
            onPress={() => navigation.navigate('Deliveries')}>
            <>
              <View style={tailwind('flex-row')}>
                <FeatherIcon name={'truck'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6')}>Deliveries</Text>
              </View>
            </>
          </Pressable>
        )}
        {/* {permissionToBool(unitLink?.deliveryPermission?.charAt(0)) && (
          <Pressable
            style={tailwind('flex-row py-3 px-2 justify-between')}
            onPress={() => navigation.navigate(E_NAVIGATION_DESTINATIONS.DOCUMENTS)}>
            <>
              <View style={tailwind('flex-row')}>
                <FeatherIcon name={'file-text'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6')}>Documents</Text>
              </View>
            </>
          </Pressable>
        )} */}
        <Accordion style={tailwind('border-0')}>
          <Accordion.Item>
            <Accordion.Summary>
              <FeatherIcon name={'align-justify'} color="#718096" size={20} />
              <Text style={tailwind('text-lg px-6 flex-1')}>
                Other unit Info
              </Text>
              <Accordion.Icon />
            </Accordion.Summary>
            <Accordion.Details>
              {permissionToBool(unitLink?.vehiclePermission?.charAt(0)) && (
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() =>
                    navigation.navigate(E_NAVIGATION_DESTINATIONS.VEHICLES)
                  }>
                  <>
                    <View style={tailwind('flex-row')}>
                      <Ionicon
                        name={'car-sport-outline'}
                        color="#718096"
                        size={20}
                      />
                      <Text style={tailwind('text-lg px-6')}>Vehicles</Text>
                    </View>
                  </>
                </Pressable>
              )}
              {permissionToBool(unitLink?.petPermission?.charAt(0)) && (
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() =>
                    navigation.navigate(E_NAVIGATION_DESTINATIONS.PETS)
                  }>
                  <>
                    <View style={tailwind('flex-row')}>
                      <Ionicon name={'paw-outline'} color="#718096" size={20} />
                      <Text style={tailwind('text-lg px-6')}>Pets</Text>
                    </View>
                  </>
                </Pressable>
              )}
              {permissionToBool(unitLink?.storagePermission?.charAt(0)) && (
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() =>
                    navigation.navigate(E_NAVIGATION_DESTINATIONS.STORAGES)
                  }>
                  <>
                    <View style={tailwind('flex-row')}>
                      <FeatherIcon name={'archive'} color="#718096" size={20} />
                      <Text style={tailwind('text-lg px-6')}>Storage</Text>
                    </View>
                  </>
                </Pressable>
              )}
              {permissionToBool(unitLink?.parkingPermission?.charAt(0)) && (
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() =>
                    navigation.navigate(E_NAVIGATION_DESTINATIONS.PARKINGS)
                  }>
                  <>
                    <View style={tailwind('flex-row')}>
                      <FeatherIcon name={'layout'} color="#718096" size={20} />
                      <Text style={tailwind('text-lg px-6')}>Parking</Text>
                    </View>
                  </>
                </Pressable>
              )}
              {permissionToBool(unitLink?.gateCardPermission?.charAt(0)) && (
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() =>
                    navigation.navigate(E_NAVIGATION_DESTINATIONS.GATE_CARDS)
                  }>
                  <>
                    <View style={tailwind('flex-row')}>
                      <FeatherIcon
                        name={'credit-card'}
                        color="#718096"
                        size={20}
                      />
                      <Text style={tailwind('text-lg px-6')}>Gate cards</Text>
                    </View>
                  </>
                </Pressable>
              )}
            </Accordion.Details>
          </Accordion.Item>
        </Accordion>
        {unitLink?.canViewUnitHistory && (
          <Accordion style={tailwind('border-0')}>
            <Accordion.Item>
              <Accordion.Summary style={tailwind('text-lg')}>
                <FeatherIcon name={'clock'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6 flex-1')}>History</Text>
                <Accordion.Icon />
              </Accordion.Summary>
              <Accordion.Details>
                {unitLink?.allowedToChekoutKey &&
                  unitLink.canViewUnitHistory && (
                    <Pressable
                      style={tailwind('flex-row py-3 px-2 justify-between')}
                      onPress={() =>
                        navigation.navigate(E_NAVIGATION_DESTINATIONS.KEY_LOGS)
                      }>
                      <>
                        <View style={tailwind('flex-row')}>
                          <FeatherIcon name={'key'} color="#718096" size={20} />
                          <Text style={tailwind('text-lg px-6')}>Key Logs</Text>
                        </View>
                      </>
                    </Pressable>
                  )}
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() =>
                    navigation.navigate(E_NAVIGATION_DESTINATIONS.VISITOR_LOGS)
                  }>
                  <>
                    <View style={tailwind('flex-row')}>
                      <FeatherIcon name={'user'} color="#718096" size={20} />
                      <Text style={tailwind('text-lg px-6')}>
                        Visitor History
                      </Text>
                    </View>
                  </>
                </Pressable>
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() =>
                    navigation.navigate(E_NAVIGATION_DESTINATIONS.DOOR_LOGS)
                  }>
                  <>
                    <View style={tailwind('flex-row')}>
                      <MaterialCommunityIcons
                        name={'door'}
                        color="#718096"
                        size={20}
                      />
                      <Text style={tailwind('text-lg px-6')}>
                        Access History
                      </Text>
                    </View>
                  </>
                </Pressable>
                {unitLink?.allowedToClaimPackages && (
                  <Pressable
                    style={tailwind('flex-row py-3 px-2 justify-between')}
                    onPress={() =>
                      navigation.navigate(
                        E_NAVIGATION_DESTINATIONS.PACKAGES_HISTORIES,
                      )
                    }>
                    <>
                      <View style={tailwind('flex-row')}>
                        <FeatherIcon name={'gift'} color="#718096" size={20} />
                        <Text style={tailwind('text-lg px-6')}>
                          Packages History
                        </Text>
                      </View>
                    </>
                  </Pressable>
                )}
              </Accordion.Details>
            </Accordion.Item>
          </Accordion>
        )}

        {permissionToBool(unitLink?.violationsPermission?.charAt(0)) && (
          <Pressable
            style={tailwind('flex-row py-3 px-2 justify-between')}
            onPress={() =>
              navigation.navigate(E_NAVIGATION_DESTINATIONS.VIOLATIONS)
            }>
            <>
              <View style={tailwind('flex-row')}>
                <Ionicon name={'flame-outline'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6')}>Violations</Text>
              </View>
            </>
          </Pressable>
        )}

        {/* {true && (
          <TouchableRipple
            style={tailwind('flex-row py-3 px-2 justify-between')}
            onPress={() =>
              showInformationMessage(
                'This will allow you to report a maintenance issue.',
              )
            }>
            <>
              <View style={tailwind('flex-row')}>
                <FeatherIcon name={'tool'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6')}>
                  Report Maintenance Issue
                </Text>
              </View>
            </>
          </TouchableRipple>
        )} */}

        {/* {true && (
          <TouchableRipple
            style={tailwind('flex-row py-3 px-2 justify-between')}
            onPress={() =>
              showInformationMessage(
                'This will allow you to report a security issue.',
              )
            }>
            <>
              <View style={tailwind('flex-row')}>
                <FeatherIcon name={'shield'} color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6')}>
                  Report Security Issue
                </Text>
              </View>
            </>
          </TouchableRipple>
        )} */}

        {/* {unitLink?.resident && (
          <Accordion style={tailwind('border-0')}>
            <Accordion.Item>
              <Accordion.Summary style={tailwind('text-lg')}>
                <FeatherIcon name="folder" color="#718096" size={20} />
                <Text style={tailwind('text-lg px-6 flex-1')}>Directory</Text>
                <Accordion.Icon />
              </Accordion.Summary>
              <Accordion.Details>
                <Pressable
                  style={tailwind('flex-row py-3 px-2 justify-between')}
                  onPress={() => navigation.navigate('Directory')}>
                  <View style={tailwind('flex-row')}>
                    <FeatherIcon name={'search'} color="#718096" size={20} />
                    <Text style={tailwind('text-lg px-6')}>Search person</Text>
                  </View>
                </Pressable>
                <Pressable
                  onPress={() =>
                    navigation.navigate(
                      E_NAVIGATION_DESTINATIONS.EDIT_DIRECTORY_SETTINGS,
                    )
                  }
                  style={tailwind('flex-row py-3 px-2 justify-between')}>
                  <View style={tailwind('flex-row')}>
                    <FeatherIcon name={'edit-2'} color="#718096" size={20} />
                    <Text style={tailwind('text-lg px-6')}>
                      Edit my directory settings
                    </Text>
                  </View>
                </Pressable>
              </Accordion.Details>
            </Accordion.Item>
          </Accordion>
        )} */}

        {/* {unitLink?.getNotifications && ( */}
        {/* <Pressable
          style={tailwind('flex-row py-3 px-2 justify-between')}
          onPress={() =>
            navigation.navigate(E_NAVIGATION_DESTINATIONS.ALERTS_PREFERENCES)
          }>
          <>
            <View style={tailwind('flex-row')}>
              <FeatherIcon name={'settings'} color="#718096" size={20} />
              <Text style={tailwind('text-lg px-6')}>
                Notifications preferences
              </Text>
            </View>
          </>
        </Pressable> */}
        {/* )} */}
      </Box>
    </Card>
  );
};

const ExtrasSection = ({openNotifSettings, openAbout, openHowTo, openPrivacyPolicy}) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);

  const onYesPressed = () => {
    dispatch(SignOut());
  };
  const onNoPressed = () => {
    console.log('Cancel Pressed');
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteAccount = async (userCreds: UserCreds) => {
    if (!userCreds.username) {
      showErrorMessage('Please enter your username');
    } else if (!userCreds.password) {
      showErrorMessage('Please enter your password');
    } else {
      setDeleting(true);
      dispatch(DeleteAccount(userCreds)).finally(() => {
        setDeleting(false);
        setDeleteModalVisible(false);
      });
    }
  };

  return (
    <Card style={settingsStyles.card}>
      <Box
        bg={useColorModeValue('gray.50', 'gray.700')}
        style={settingsStyles.box}>
        <Text style={styles.settingsLabel}>EXTRAS</Text>
        <Pressable
          style={settingsStyles.pressable}
          onPress={() => setModalVisible(!modalVisible)}>
          <View style={settingsStyles.row}>
            <FeatherIcon name={'user-plus'} color="#718096" size={20} />
            <Text style={settingsStyles.text}>Generate invite code</Text>
          </View>
        </Pressable>
        <Pressable
          style={settingsStyles.pressable}
          onPress={openNotifSettings}>
          <View style={settingsStyles.row}>
            <FeatherIcon name={'shield'} color="#718096" size={20} />
            <Text style={settingsStyles.text}>Device permissions</Text>
          </View>
        </Pressable>
        <Pressable
          style={settingsStyles.pressable}
          onPress={openAbout}>
          <View style={settingsStyles.row}>
            <FeatherIcon name={'info'} color="#718096" size={20} />
            <Text style={settingsStyles.text}>About</Text>
          </View>
        </Pressable>
        <Pressable
          style={settingsStyles.pressable}
          onPress={openHowTo}>
          <View style={settingsStyles.row}>
            <FeatherIcon name={'help-circle'} color="#718096" size={20} />
            <Text style={settingsStyles.text}>How to</Text>
          </View>
        </Pressable>
        <Pressable
          style={settingsStyles.pressable}
          onPress={openPrivacyPolicy}>
          <View style={settingsStyles.row}>
            <Octicon name={'shield-lock'} color="#718096" size={20} />
            <Text style={settingsStyles.text}>Privacy policy</Text>
          </View>
        </Pressable>
        
        <Pressable
          style={settingsStyles.pressable}
          onPress={() => setDeleteModalVisible(true)}>
          <View style={settingsStyles.row}>
            <FeatherIcon name={'trash'} color="#718096" size={20} />
            <Text style={settingsStyles.text}>Delete Account</Text>
          </View>
        </Pressable>
        <Pressable
          style={settingsStyles.pressable}
          onPress={() => setIsOpen(!isOpen)}>
          <View style={settingsStyles.row}>
            <FeatherIcon name={'log-out'} color="#718096" size={20} />
            <Text style={settingsStyles.text}>Sign out</Text>
          </View>
        </Pressable>
      </Box>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onNoPressed={onNoPressed}
        onYesPressed={onYesPressed}
        description="Are you sure you wish to logout?"
      />
      <InviteCodeGenerator
        modalVisible={modalVisible}
        closeModal={() => setModalVisible(false)}
      />
      <DeleteAccountModal
        visible={deleteModalVisible}
        closeModal={() => setDeleteModalVisible(false)}
        onPressDelete={(userCreds: UserCreds) => deleteAccount(userCreds)}
      />
    </Card>
  );
};

const settingsStyles = {
  card: {
    marginHorizontal: 8,
    marginTop: 16,
    marginBottom: 16,
    borderRadius: 8,
  },
  box: {
    paddingBottom: 8,
  },
  pressable: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 8,
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  text: {
    fontSize: 18,
    paddingLeft: 24,
  },
};

const SettingsScreen = ({navigation, route}) => {
  const person = useSelector((state: RootStore) => state.auth.user);
  const currentUnitLink = useSelector(
    (state: RootState) =>
      state.units.unitLinks?.filter(link => link.isDefault === true)[0],
  );
  const unitPeopleLinks = useSelector(
    (state: RootState) => state.units.unitLinks,
  );
  const [unit, setUnit] = useState<Unit>(null);
  const [loadingUnit, setLoadingUnit] = useState(true);
  const tailwind = useTailwind();

  useFocusEffect(
    useCallback(() => {
      if (currentUnitLink) {
        fetchUnit(currentUnitLink?.unit?.id);
      }
    }, [currentUnitLink]),
  );

  const fetchUnit = async (id: string) => {
    setLoadingUnit(true);
    try {
      const res = await fetchUnitById(id);
      setUnit(res);
    } catch (error) {
      // showErrorMessage(error.message);
    } finally {
      setLoadingUnit(false);
    }
  };

  const openNotifSettings = () => {
    navigation.navigate(E_NAVIGATION_DESTINATIONS.NOTIFICATION_SETTINGS);
  };

  const openAbout = () => {
    navigation.navigate(E_NAVIGATION_DESTINATIONS.ABOUT);
  };

  const openHowTo = () => {
    navigation.navigate(E_NAVIGATION_DESTINATIONS.HOWTO);
  };

  const openPrivacyPolicy = () => {
    navigation.navigate(E_NAVIGATION_DESTINATIONS.PRIVACY_POLICY);
  }

  return (
    <Container>
      <NavbarWithAction
        navigation={navigation}
        title="Others"
        hasAccessCard
        onClickAccessCard={() =>
          navigation.navigate(E_NAVIGATION_DESTINATIONS.ID_CARD)
        }
        hasBackArrow={unitPeopleLinks.length === 0 || !currentUnitLink}
        fullScreen
      />
      <ScrollView keyboardShouldPersistTaps="handled">
        <PersonCard person={person} navigation={navigation} />
        {unit ? (
          <UnitCard
            loading={loadingUnit}
            navigation={navigation}
            unitName={unit.unit}
            buildingName={unit.buildingName}
            picture={unit.picture}
            displayName={unit.unitDisplay}
            onEditPress={() => {
              navigation.navigate('Edit Unit', {
                unit,
              });
            }}
          />
        ) : null}
        <MenusSection navigation={navigation} />
        <ExtrasSection
          openNotifSettings={openNotifSettings}
          openAbout={openAbout}
          openHowTo={openHowTo}
          openPrivacyPolicy={openPrivacyPolicy}
        />
        <View style={{paddingBottom: 180, marginTop: 10}}>
          <VersionCode />
        </View>
      </ScrollView>
    </Container>
  );
};

export default SettingsScreen;
