import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import HStack from '../../../components/HStack';
import {Modal, Select} from 'native-base';
import {makeId} from '../../../utils';

export default function FilterPanel({
  panelActive,
  setPanelActive,
  updateParams,
}) {
  const statuses = [
    {
      label: 'Out',
      value: 'Out',
    },
    {
      label: 'Returned',
      value: 'Returned',
    },
  ];
  const [selectedStatus, setSelectedStatus] = useState(null);

  const setFilters = () => {
    updateParams({status: selectedStatus});
    setPanelActive(false);
  };

  const resetFilters = () => {
    updateParams({status: null});
    setSelectedStatus(null);
    setPanelActive(false);
  };

  const KeyLogsFilterSection = () => {
    return (
      <View>
        <Text style={styles.panelTitle}>Filter visitor logs</Text>
        <View style={styles.filterContainer}>
          <Text style={styles.statusText}>Status</Text>
          <Select
            maxWidth={350}
            minWidth={200}
            selectedValue={selectedStatus}
            onValueChange={value => {
              setSelectedStatus(value);
            }}
            placeholder="*Select status*">
            {statuses.map(event => (
              <Select.Item
                key={makeId(16)}
                label={event.label}
                value={event.value}
              />
            ))}
          </Select>
        </View>
        <HStack style={styles.buttonContainer}>
          <Button
            colorScheme="primary"
            mr={2}
            py={4}
            style={styles.button}
            onPress={() => setFilters()}>
            Apply
          </Button>
          <Button
            onPress={() => resetFilters()}
            colorScheme="primary"
            variant="outline"
            py={4}
            style={styles.button}>
            Reset
          </Button>
        </HStack>
      </View>
    );
  };

  return (
    <Modal isOpen={panelActive} onClose={() => setPanelActive(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Filter</Modal.Header>
        <Modal.Body>
          <KeyLogsFilterSection />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const styles = StyleSheet.create({
  panelTitle: {
    // Add your styles here
  },
  filterContainer: {
    margin: 8,
    marginBottom: 0,
    justifyContent: 'space-between',
  },
  statusText: {
    marginTop: 16,
  },
  buttonContainer: {
    flex: 1,
    marginTop: 24,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
  },
});
