// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import {FlatList, View, RefreshControl, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {
  GetDeliveries,
  DeleteDelivery,
  setDeliveriesTabSelected,
} from '../deliveriesReducer';
import {useNavigation} from '@react-navigation/native';
import Center from '../../../components/Center';
import {showDeleteUnitPrompt} from '../../../utils/showDeletePrompt';
import DeliveryItem from '../components/DeliveryItem';
import {LoadingSpinner, NavbarWithAction} from '../../../components';
import Container from '../../../components/Container';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';

const Deliveries: React.FC = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {deliveries, loading} = useSelector(
    (state: RootStore) => state.deliveries,
  );
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    dispatch(GetDeliveries());
  }, []);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(setDeliveriesTabSelected(true));
    });

    return unsubscribe;
  }, [navigation]);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    dispatch(GetDeliveries());
    setRefreshing(false);
  }, []);

  return (
    <Container>
      <NavbarWithAction
        hasSignOut={false}
        navigation={navigation}
        hasBackArrow
        title="deliveries"
        showCurrentUnit
        hasAdd={false}
        onClickAdd={() => navigation.navigate('Add delivery', {delivery: null})}
        showUnitPicture={false}
        onClickFilter={() => null}
        hasFilter={false}
        hasAccessCard
        onClickAccessCard={() =>
          navigation.navigate(E_NAVIGATION_DESTINATIONS.ID_CARD)
        }
        fullScreen
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <FlatList
          showsVerticalScrollIndicator={false}
          refreshControl={
            <RefreshControl onRefresh={onRefresh} refreshing={refreshing} />
          }
          data={deliveries}
          keyExtractor={(item, _) => item.id}
          style={styles.flatList}
          ListFooterComponent={<View style={styles.footer} />}
          ListEmptyComponent={
            <Center style={styles.center}>
              You have no deliveries at the moment
            </Center>
          }
          renderItem={({item}) => (
            <DeliveryItem
              delivery={item}
              onSelectDelete={() =>
                showDeleteUnitPrompt('delivery', () => {
                  dispatch(DeleteDelivery(item.id));
                })
              }
              itemClickHandler={() =>
                navigation.navigate('Delivery details', {delivery: item})
              }
              onSelectEdit={() =>
                navigation.navigate('Add delivery', {delivery: item})
              }
            />
          )}
        />
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  flatList: {
    marginVertical: 12,
    marginHorizontal: 8,
  },
  footer: {
    height: 32,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Deliveries;
