// @ts-nocheck
import React from 'react';
import {View, Alert, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useState, useEffect} from 'react';
import {RootStore} from '../redux/store';
import {GetListPeopleTypes} from '../redux/reducers/listPeopleTypesReducer';
import {useUpdatePermissions} from '../hooks/permissions';
import {
  getCurrentUnitLink,
  mapPermissionStringToSwitches,
  mapSwitchesToPermissionString,
} from '../utils';
import AsterixSwitch from './AsterixSwitch';
import Input from './Input';
import Select from './Select';
import Accordion from './Accordion';
import Text from '../components/Text';
import PermissionInfoText from './PermissionInfoText';
import ConfirmationDialog from './ConfirmationDialog';
import {SubmitButton} from '.';
import {UnitLink} from '../@types/UnitLink';
import {ListPeopleType} from '../@types/ListPeopleType';

const directoryScopes = ['Unit', 'Community', 'Building', 'Site'];

const Permissions = ({
  initialPeopleState,
  updateParentState,
  isEditMode,
  isCreate,
  renderCustomComponent,
  hasUpdateButton = false,
}) => {
  const [unitPeopleState, setUnitPeopleState] =
    useState<UnitLink>(initialPeopleState);
  const {listPeopleTypes, loading} = useSelector(
    (state: RootStore) => state.listPeopleTypes,
  );
  const [permissions, setPermissions] = useState([]);
  const [togglesDisabled, setTogglesDisabled] = useState(!isEditMode);
  const dispatch = useDispatch();
  const {mutate, isLoading} = useUpdatePermissions();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const [currentPeopleType, setCurrentPeopleType] = useState(null);
  let defaultUnitLink = getCurrentUnitLink();

  useEffect(() => {
    setPermissions(mapPermissionStringToSwitches(unitPeopleState));
    dispatch(GetListPeopleTypes());
  }, []);

  const updatePermissions = (peopleTypeId: string) => {
    const personType: ListPeopleType = listPeopleTypes.filter(
      type => type.id === peopleTypeId,
    )[0];

    const nextState: UnitLink = {
      ...unitPeopleState,
      peopleType: {
        id: personType.id,
        name: personType.peopleType,
        residentCanAdd: personType.residentCanAdd,
      },
      addForeverDateFrame: personType.addForeverDateFrame,
      canManagePeople: personType.normallyCanManagePeople,
      allowedToChekoutKey: personType.normallyAllowedToChekoutKey,
      allowedToClaimPackages: personType.normallyAllowedToClaimPackages,
      allowedToAuthorizeEntry: personType.normallyallowedToAuthorizeEntry,
      alwaysNeedsEntrancePermission: !personType.autoCheckIn,
      canViewLog: personType.normallyCanViewLog,
      peoplePermission: personType.normallyPeoplePermission,
      unitPermission: personType.normallyUnitPermission,
      vehiclePermission: personType.normallyVehiclePermission,
      petPermission: personType.normallyPetPermission,
      storagePermission: personType.normallyStoragePermission,
      parkingPermission: personType.normallyParkingPermission,
      violationsPermission: personType.normallyViolationsPermission,
      deliveryPermission: personType.normallyDeliveryPermission,
      gateCardPermission: personType.normallyGateCardPermission,
      getNotifications: personType.normallyGetsNotifications,
      alwaysCall: defaultUnitLink.allowedToAuthorizeEntry
        ? personType.normallyAlwaysCall
        : unitPeopleState.alwaysCall,
      canEditself: personType.normallyCanEditSelf,
      allowedtoApprove: personType.normallyAllowedtoApprove,
      resident: personType.residentDefault,
      canViewUnitHistory: personType.normallyCanViewUnitHistory,
      allowedToRequestAccess: personType.normallyallowedToRequestAccess,
      privateFilesManage: personType.normallyPrivateFilesManage,
      privateFilesAccess: personType.normallyPrivateFilesAccess,
      ownerFilesAccess: personType.normallyOwnerFilesAccess,
      managePublicFiles: personType.normallyManagePublicFiles,
    };

    setPermissions(mapPermissionStringToSwitches(nextState));
    setUnitPeopleState(nextState);
    updateParentState(nextState);
    setTogglesDisabled(true);
  };

  const showManagementPrompt = (peopleTypeObj: ListPeopleType) => {
    setCurrentPeopleType(peopleTypeObj);
    setIsOpen(true);
  };

  const handlePermissionsToggle = newPermissions => {
    const resourcePermissions = {};
    newPermissions.forEach(permission => {
      resourcePermissions[permission.title.toLowerCase() + 'Permission'] =
        permission.permissions.map(perm => (perm.value ? '1' : '0')).join('');
    });
    setUnitPeopleState({
      ...unitPeopleState,
      ...resourcePermissions,
    });
  };

  return (
    <>
      {isEditMode ? (
        <>
          <Text style={styles.textInputLabel}>People type</Text>
          <Select
            placeholder="Select people type "
            minWidth={200}
            selectedValue={
              unitPeopleState?.peopleType?.id
                ? unitPeopleState?.peopleType?.id
                : undefined
            }
            onValueChange={value => {
              const peopleTypeObj = listPeopleTypes?.filter(
                item => item.id === value,
              )[0];
              !peopleTypeObj?.residentCanAdd
                ? showManagementPrompt(peopleTypeObj)
                : updatePermissions(value);
            }}>
            {listPeopleTypes?.map(peopleType => (
              <Select.Item
                key={peopleType.id}
                label={
                  peopleType?.residentCanAdd
                    ? peopleType?.peopleType
                    : `${peopleType?.peopleType} - ( requires management approval )`
                }
                value={peopleType.id}
              />
            ))}
          </Select>
        </>
      ) : (
        <View style={styles.row}>
          <Text style={styles.textInputLabel}>People type</Text>
          <Text style={styles.detailsText}>
            {unitPeopleState.peopleType.name}
          </Text>
        </View>
      )}
      {!!renderCustomComponent && renderCustomComponent()}
      {!!unitPeopleState.peopleType && (
        <>
          <Accordion style={styles.mt4}>
            <Accordion.Item>
              <Accordion.Summary>
                Permissions
                <Accordion.Icon />
              </Accordion.Summary>
              <Accordion.Details>
                <>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Resident"
                      description="Resident"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.resident}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          resident: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          resident: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Needs help, in emergency ?"
                      description="Needs help, in emergency  description ?"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.needsHelpInEmergency}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          needsHelpInEmergency: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          needsHelpInEmergency: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Mail to ?"
                      description="Mail to description ?"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.mailTo}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          mailTo: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          mailTo: value,
                        });
                      }}
                    />
                  </View>
                </>
                <>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Always call for gate authorizations ?"
                      description="Always call for gate authorizations ?"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.alwaysCall}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          alwaysCall: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          alwaysCall: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Get notifications ?"
                      description="Get notifications description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.getNotifications}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          getNotifications: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          getNotifications: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Allowed to approve ?"
                      description="Allowed to approve description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedtoApprove}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedtoApprove: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedtoApprove: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Emergency contact ?"
                      description="Emergency contact description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.emergencyContact}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          emergencyContact: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          emergencyContact: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Can manage people ?"
                      description="Can manage description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.canManagePeople}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          canManagePeople: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          canManagePeople: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Allowed to checkout key ?"
                      description="Allowed to checkout description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToChekoutKey}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToChekoutKey: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToChekoutKey: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title=" Allowed to claim packages ?"
                      description="Allowed to claim packages description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToClaimPackages}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToClaimPackages: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToClaimPackages: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Allowed to authorize entry ?"
                      description="Allowed to authorize entry description"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToAuthorizeEntry}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToAuthorizeEntry: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToAuthorizeEntry: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Always needs entrance permission ?"
                      description="Always needs entrance permission"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.alwaysNeedsEntrancePermission}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          alwaysNeedsEntrancePermission: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          alwaysNeedsEntrancePermission: value,
                        });
                      }}
                    />
                  </View>
                  <View style={styles.row}>
                    <PermissionInfoText
                      title="Allowed to request access ?"
                      description="Allowed to request access permission"
                    />
                    <AsterixSwitch
                      value={unitPeopleState.allowedToRequestAccess}
                      disabled={togglesDisabled}
                      onValueChange={value => {
                        setUnitPeopleState({
                          ...unitPeopleState,
                          allowedToRequestAccess: value,
                        });
                        updateParentState({
                          ...unitPeopleState,
                          allowedToRequestAccess: value,
                        });
                      }}
                    />
                  </View>
                  <>
                    <Text style={styles.textInputLabel}>
                      Access instructions
                    </Text>
                    {isEditMode ? (
                      <Input
                        placeholder="Enter access instructions"
                        value={unitPeopleState.accessInstructions}
                        multiline
                        onChangeText={text => {
                          setUnitPeopleState({
                            ...unitPeopleState,
                            accessInstructions: text,
                          });
                          updateParentState({
                            ...unitPeopleState,
                            accessInstructions: text,
                          });
                        }}
                      />
                    ) : (
                      <Text>{unitPeopleState.accessInstructions}</Text>
                    )}
                  </>
                  {isEditMode && (
                    <View style={styles.row}>
                      <PermissionInfoText
                        title="Can edit self ?"
                        description="Can edit self description"
                      />
                      <AsterixSwitch
                        value={unitPeopleState.canEditself}
                        onValueChange={value => {
                          setUnitPeopleState({
                            ...unitPeopleState,
                            canEditself: value,
                          });
                          updateParentState({
                            ...unitPeopleState,
                            canEditself: value,
                          });
                        }}
                        disabled
                      />
                    </View>
                  )}
                </>
              </Accordion.Details>
            </Accordion.Item>
          </Accordion>
          {isEditMode && hasUpdateButton && !isCreate && (
            <SubmitButton
              title={isLoading ? 'Updating ...' : 'Update'}
              onPress={() => {
                const perms = mapSwitchesToPermissionString(permissions);
                const newObj = {
                  alwaysCall: unitPeopleState.alwaysCall,
                  getNotifications: unitPeopleState.getNotifications,
                  allowedToApprove: !!unitPeopleState.allowedtoApprove,
                  emergencyContact: unitPeopleState.emergencyContact,
                  canManagePeople: unitPeopleState.canManagePeople,
                  allowedToChekoutKey: unitPeopleState.allowedToChekoutKey,
                  allowedToClaimPackages:
                    unitPeopleState.allowedToClaimPackages,
                  allowedToAuthorizeEntry:
                    unitPeopleState.allowedToAuthorizeEntry,
                  alwaysNeedsEntrancePermission:
                    unitPeopleState.alwaysNeedsEntrancePermission,
                };
                mutate({
                  unitPeopleLinkId: unitPeopleState.id,
                  payload: newObj,
                });
              }}
            />
          )}
        </>
      )}
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onNoPressed={onClose}
        onYesPressed={() => updatePermissions(currentPeopleType.id)}
        description={`Adding ${currentPeopleType?.peopleType} will require management approval`}
        negativeLabel={'CANCEL'}
        positiveLabel={'OK '}
        title="Alert"
      />
    </>
  );
};

const styles = StyleSheet.create({
  textInputLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  detailsText: {
    fontSize: 16,
    marginLeft: 8,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  mt4: {
    marginTop: 16,
  },
});

export default Permissions;
