import React from 'react';
import {StyleSheet} from 'react-native';
import Button from './Button';
import {goBack} from '../navigation';

const RegisterPrevButton = () => {
  return (
    <Button
      variant="outline"
      py="4"
      onPress={() => goBack()}
      style={styles.button}>
      Previous
    </Button>
  );
};

const styles = StyleSheet.create({
  button: {
    marginRight: 8,
    flex: 1,
  },
});

export default RegisterPrevButton;
