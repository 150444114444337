import {IInputProps, Input as NativeBaseInput} from 'native-base';
import React from 'react';
import { colorSwatches } from '../styles';

const Input = (props: IInputProps) => {
  return (
    <NativeBaseInput
      style={[props.style, {fontSize: 18}]}
      _disabled={{
        backgroundColor: colorSwatches.gray.color,
      }}
      _readOnly={{
        backgroundColor: colorSwatches.gray.color,
      }}
      {...props}
    />
  );
};

export default Input;
