// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from 'react';
import {View, FlatList, LogBox, StyleSheet} from 'react-native';
import {ActivityIndicator, Card} from 'react-native-paper';
import Text from '../../../components/Text';
import {styles} from '../../../styles';
import {NavbarWithAction} from '../../../components';
import Center from '../../../components/Center';
import {useFocusEffect} from '@react-navigation/native';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {GetGateCards} from '../gateCardsReducer';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import Container from '../../../components/Container';

const GateCardItem = ({gatecard, itemClickHandler}) => {
  return (
    <Card style={localStyles.card} onPress={() => itemClickHandler()}>
      <View style={localStyles.cardContent}>
        <View style={localStyles.cardTextContainer}>
          <Text style={localStyles.cardNumber}>{gatecard.cardNumber}</Text>
          <Text>{gatecard.cardHolderName}</Text>
          <View style={localStyles.cardFooter}>
            <Text style={localStyles.footerText}>{'hello this'}</Text>
          </View>
        </View>
      </View>
    </Card>
  );
};

const GateCards = ({navigation}) => {
  const {gatecards, loading} = useSelector(
    (state: RootStore) => state.gatecards,
  );
  const dispatch = useDispatch();

  const ignoreListsWarnings = () => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  };

  useFocusEffect(
    useCallback(() => {
      dispatch(GetGateCards());
      ignoreListsWarnings();
    }, []),
  );

  const MainComponent = () => {
    return (
      <>
        {loading ? (
          <ActivityIndicator
            animating={true}
            color={styles.primaryColor.color}
            style={localStyles.loadingIndicator}
          />
        ) : (
          <>
            <FlatList
              data={gatecards}
              style={localStyles.flatList}
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={<Center>No gatecards found</Center>}
              ListFooterComponent={<View style={localStyles.listFooter} />}
              renderItem={({item}) => (
                <GateCardItem
                  gatecard={item}
                  itemClickHandler={() =>
                    navigation.navigate(
                      E_NAVIGATION_DESTINATIONS.GATE_CARD_DETAILS,
                      {gateCard: item},
                    )
                  }
                />
              )}
            />
          </>
        )}
      </>
    );
  };

  return (
    <Container>
      <NavbarWithAction
        navigation={navigation}
        hasBackArrow
        title="Gatecards"
        showCurrentUnit
        fullScreen
      />
      <MainComponent />
    </Container>
  );
};

const localStyles = StyleSheet.create({
  card: {
    marginVertical: 8,
    borderRadius: 8,
  },
  cardContent: {
    flexDirection: 'row',
    padding: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTextContainer: {
    flex: 1,
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  cardNumber: {
    fontSize: 18,
  },
  cardFooter: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  },
  footerText: {
    marginLeft: 4,
    fontSize: 12,
  },
  loadingIndicator: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  flatList: {
    padding: 8,
    flex: 1,
  },
  listFooter: {
    height: 96,
  },
});

export default GateCards;
