// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View, TouchableOpacity, Image, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Icon from 'react-native-vector-icons/Feather';
import {DisabledInput, Navbar, Permissions} from '../../../components';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {styles as globalStyles} from '../../../styles';
import {initialLinkState} from '../utils';
import {useDispatch, useSelector} from 'react-redux';
import {createUnitPeopleLink} from '../../../api/links';
import {RootStore} from '../../../redux/store';
import {showAlert} from '../../../service';
import {GetPeople} from '../../../modules/People/peopleReducer';
import FormContainer from '../../../components/FormContainer';
import Input from '../../../components/Input';
import Accordion from '../../../components/Accordion';
import Button from '../../../components/Button';
import isWeb from '../../../utils/isWeb';
import PhotoCaptureSheet from '../../../components/PhotoCaptureSheet';
import Container from '../../../components/Container';
import formatPhoneNumber from '../../../utils/phoneNumberFormatter';

const CreatePeopleAndLink = ({navigation}) => {
  const unitLink = useSelector(
    (state: RootStore) => state?.units?.unitLinks,
  ).filter(link => link.isDefault === true)[0];
  const [linkState, setLinkState] = useState({
    ...initialLinkState,
    unitId: unitLink.unit.id,
    peopleId: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [picturePanelActive, setPicturePanelActive] = useState(false);
  const dispatch = useDispatch();

  const createUnitPeopleAndLink = async linkData => {
    // Delete peopleType object from permissions component
    try {
      const data = {
        ...linkData,
        unitId: unitLink.unit.id,
        dateFrames: [],
        peopleTypeId: linkData.peopleType.id,
        peopleType: null,
      };
      const link = await createUnitPeopleLink(data);
      showSuccessMessage('Person added successfully');
      if (!linkData.addForeverDateFrame) {
        promptAddDateFrames(link.id, unitLink.unit.id);
      } else {
        navigation.goBack();
      }
      dispatch(GetPeople());
    } catch (e) {
      showErrorMessage(e.toString());
    }
    setIsSubmitting(false);
  };

  const promptAddDateFrames = (linkId: string, unitId: string) => {
    showAlert(
      'Add dateframes',
      'Do you wish to add dateframes to this person ?',
      () => navigation.goBack(),
      () =>
        navigation.navigate('Create Person DateFrame', {
          unitPeopleLinkId: linkId,
          unitId,
        }),
    );
  };

  return (
    <Container>
      <Navbar navigation={navigation} title="Create and link person" />
      <FormContainer>
        <ScrollView
          style={styles.scrollView}
          showsVerticalScrollIndicator={false}>
          <Permissions
            initialPeopleState={linkState}
            updateParentState={childState => {
              setLinkState({
                ...childState,
                unitId: unitLink.unit.id,
                firstName: linkState.firstName,
                lastName: linkState.lastName,
                city: linkState.city,
                zip: linkState.zip,
                state: linkState.state,
                peopleId: null,
                company: linkState.company,
                phone: linkState.phone,
                email: linkState.email,
                profilePicture: linkState.profilePicture,
              });
            }}
            isEditMode
            isCreate={true}
            renderCustomComponent={() => {
              return (
                <>
                  <DisabledInput
                    title="Unit *"
                    value={
                      unitLink.unitNickname
                        ? unitLink.unitNickname
                        : unitLink.unit.unitName
                    }
                  />
                  <>
                    <Text style={styles.textInputLabel}>First name</Text>
                    <Input
                      placeholder="Enter first name"
                      value={linkState.firstName}
                      onChangeText={text =>
                        setLinkState({
                          ...linkState,
                          firstName: text,
                        })
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>Last name</Text>
                    <Input
                      placeholder="Enter last name"
                      value={linkState.lastName}
                      onChangeText={text =>
                        setLinkState({
                          ...linkState,
                          lastName: text,
                        })
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>Company</Text>
                    <Input
                      placeholder="Enter company"
                      value={linkState.company}
                      onChangeText={text =>
                        setLinkState({
                          ...linkState,
                          company: text,
                        })
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>Phone</Text>
                    <Input
                      placeholder="Enter phone"
                      value={linkState.phone}
                      keyboardType="phone-pad"
                      onChangeText={text =>
                        setLinkState({
                          ...linkState,
                          phone: formatPhoneNumber(text),
                        })
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>Email</Text>
                    <Input
                      placeholder="Enter email"
                      value={linkState.email}
                      keyboardType="email-address"
                      onChangeText={text =>
                        setLinkState({
                          ...linkState,
                          email: text,
                        })
                      }
                    />
                  </>
                  <View>
                    <Text style={styles.profilePictureLabel}>Profile picture</Text>
                    <TouchableOpacity
                      onPress={() => {
                        setPicturePanelActive(true);
                      }}
                      style={styles.pictureUploadContainer}>
                      {linkState.profilePicture ? (
                        <Image
                          source={{
                            uri: `data:image/jpeg;base64,${linkState.profilePicture}`,
                          }}
                          style={styles.profilePicture}
                        />
                      ) : (
                        <Icon
                          name="image"
                          size={24}
                          style={styles.pictureUploadIcon}
                        />
                      )}
                    </TouchableOpacity>
                  </View>
                </>
              );
            }}
          />
          <Accordion allowMultiple style={styles.accordion}>
            <Accordion.Item>
              <Accordion.Summary>
                Advanced personal information
                <Accordion.Icon />
              </Accordion.Summary>
              <Accordion.Details>
                <View>
                  <>
                    <Text style={styles.textInputLabel}>Address Line 1</Text>
                    <Input
                      placeholder="Enter Address Line 1"
                      value={linkState.addressLine1}
                      onChangeText={text =>
                        setLinkState({...linkState, addressLine1: text})
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>Address Line 2</Text>
                    <Input
                      placeholder="Enter Address Line 2"
                      value={linkState.addressLine2}
                      onChangeText={text =>
                        setLinkState({...linkState, addressLine2: text})
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>City</Text>
                    <Input
                      placeholder="Enter city"
                      value={linkState.city}
                      onChangeText={text =>
                        setLinkState({...linkState, city: text})
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>State</Text>
                    <Input
                      placeholder="Enter state"
                      value={linkState.state}
                      onChangeText={text =>
                        setLinkState({...linkState, state: text})
                      }
                    />
                  </>
                  <>
                    <Text style={styles.textInputLabel}>Zip</Text>
                    <Input
                      placeholder="Enter zip"
                      value={linkState.zip}
                      onChangeText={text =>
                        setLinkState({...linkState, zip: text})
                      }
                    />
                  </>
                </View>
              </Accordion.Details>
            </Accordion.Item>
          </Accordion>
        </ScrollView>
        <PhotoCaptureSheet
          isPanelActive={picturePanelActive}
          setIsActive={(val: boolean) => setPicturePanelActive(val)}
          onConfirmImage={(imgData: string) =>
            setLinkState({...linkState, profilePicture: imgData})
          }
        />
        <Button
          style={isWeb ? styles.webButton : styles.mobileButton}
          size="lg"
          py="3"
          isLoading={isSubmitting}
          isLoadingText="Submitting ..."
          onPress={() => {
            if (!linkState.unitId) {
              showErrorMessage('Please select a unit');
            }
            if (linkState.peopleType === '') {
              showErrorMessage('Please select people type');
            }
            if (linkState.unitId && linkState.peopleType) {
              setIsSubmitting(true);
              createUnitPeopleAndLink(linkState);
            }
          }}>
          Add person
        </Button>
      </FormContainer>
    </Container>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  textInputLabel: {
    marginBottom: 4,
    fontSize: 16,
    fontWeight: 'bold',
  },
  profilePictureLabel: {
    marginVertical: 8,
    fontSize: 16,
    fontWeight: 'bold',
  },
  pictureUploadContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 16,
    marginVertical: 8,
  },
  profilePicture: {
    borderRadius: 8,
    width: 128,
    height: 96,
  },
  pictureUploadIcon: {
    color: '#ccc',
  },
  accordion: {
    marginTop: 16,
  },
  webButton: {
    marginTop: 24,
  },
  mobileButton: {
    marginBottom: 76,
    marginHorizontal: 8,
  },
});

export default CreatePeopleAndLink;
