import React from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Ionicons from 'react-native-vector-icons/Ionicons';
import IconButton from '../../../components/IconButton';

const RegisterProgress = ({navigation}) => {
  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <IconButton
          icon={'close-outline'}
          size={30}
          style={styles.iconButton}
          onPress={() => navigation.goBack()}
        />
        <View style={styles.innerContainer}>
          <Heading>Registration </Heading>
          <View style={styles.row}>
            <Ionicons
              name="checkmark-circle-outline"
              color="#47B275"
              size={24}
              style={styles.icon}
            />
            <Text style={styles.text}>
              Congratulations, your account has been set up successfully.
            </Text>
          </View>
        </View>
      </ScrollView>
      <HStack>
        <Button
          onPress={() => navigation.navigate('Sign In')}
          style={styles.button}>
          Sign In
        </Button>
      </HStack>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  iconButton: {
    // Add any specific styles for IconButton if needed
  },
  innerContainer: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  row: {
    flexDirection: 'row',
    marginTop: 32,
  },
  icon: {
    marginTop: 4,
  },
  text: {
    marginBottom: 16,
    marginLeft: 8,
    fontSize: 18,
  },
  button: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default RegisterProgress;
