import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import VStack from '../../../components/VStack';
import HStack from '../../../components/VStack';
import {colorSwatches} from '../../../styles';

const Legend = () => {
  return (
    <VStack style={styles.vStack}>
      <Text style={styles.legendText}>Legend</Text>
      <HStack style={styles.hStack}>
        <View style={[styles.circle, {backgroundColor: colorSwatches.secondary.color}]} />
        <Text style={styles.text}>
          Dates marked in green are approved.
        </Text>
      </HStack>
      <HStack style={styles.hStack}>
        <View style={[styles.circle, {backgroundColor: colorSwatches.gray.color}]} />
        <Text style={styles.text}>
          Dates marked in grey are not approved.
        </Text>
      </HStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  vStack: {
    paddingHorizontal: 8,
    marginTop: 16,
  },
  legendText: {
    fontSize: 16,
  },
  hStack: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 36
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  text: {
    marginLeft: 8,
  },
});

export default Legend;
