import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/reducers';
import MissingPermission from './MissingPermission';
import { SetLastPermissionCheck } from '../../../modules/Auth/reducer';

const PermissionsBanner = ({onPress}) => {
  const [_hasMissingPermissions, _setHasMissingPermissions] = useState(false);
  const {lastPermmisionCheck} = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [permissions, setPermissions] = useState([
    {
      id: 'Notifications',
      label: 'Notifications',
      message: null,
      description:
        'Allows us to let you know you have a Visitor and or Package.',
    },
    {
      id: 'Camera',
      label: 'Camera',
      message: null,
      description: 'For taking your picture and other pictures',
    },
  ]);

  useEffect(() => {
    checkMultiplePermissions();
  }, [lastPermmisionCheck]);

  useEffect(() => {
    const _hasMissingPermissions = permissions.some(
      permission => permission.message === 'denied' || permission.message === 'prompt',
    );
    _setHasMissingPermissions(_hasMissingPermissions);
  }, [permissions]);

  const checkMultiplePermissions = async () => {
    // @ts-ignore
    await navigator.permissions.query({name: 'notifications'}).then(result => {
      setPermissions(prevPermissions =>
        prevPermissions.map(permission =>
          permission.id === 'Notifications'
            ? {...permission, message: result.state}
            : permission,
        ),
      );
    });

    // @ts-ignore
    await navigator.permissions.query({name: 'camera'}).then(result => {
      setPermissions(prevPermissions =>
        prevPermissions.map(permission =>
          permission.id === 'Camera'
            ? {...permission, message: result.state}
            : permission,
        ),
      );
    });
    // @ts-ignore
    dispatch(SetLastPermissionCheck(new Date().getTime()));
  };


  return (
    <MissingPermission
      onPress={onPress}
      _hasMissingPermissions={_hasMissingPermissions}
    />
  );
};

export default PermissionsBanner;
