// @ts-nocheck
/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {EmptyList, MySearchbar, NavbarWithAction} from '../../../components';
import {useNavigation} from '@react-navigation/native';
import {usePaginatedPackageHistories} from '../../../hooks/packages';
import {LoadingSpinner} from '../../../components';
import {FlatList, View} from 'react-native';
import {
  E_NAVIGATION_DESTINATIONS,
  PackageHistoryQueryParams,
} from '../../../@types';
import PackagesItem from '../components/PackageItem';
import {getCurrentUnitLink} from '../../../utils';
import FilterPackageHistory from '../components/FilterPackageHistory';
import Container from '../../../components/Container';

export default function PackagesHistory() {
  const navigation = useNavigation();
  const currentUnitId = getCurrentUnitLink()?.unit?.id;
  const initialQueryParams: PackageHistoryQueryParams = {
    pageNumber: 1,
    pageSize: 15,
    dateFrom: null,
    dateTo: null,
    thumbWidth: 46,
    thumbHeight: 46,
    unitId: currentUnitId,
    allClaimednUnclaimed: true,
    claimedOnly: false,
    searchKey: null,
  };
  const [queryParams, setQueryParams] =
    React.useState<PackageHistoryQueryParams>(initialQueryParams);
  const {
    isLoading,
    data,
    refetch,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = usePaginatedPackageHistories(queryParams);
  const [swipeablePanelActive, setSwipeablePanelActive] = React.useState(false);
  const [isNavbarShown, setIsNavbarShown] = React.useState(true);


  function loadMore() {
    if (hasNextPage) {
      fetchNextPage();
    }
  }

  return (
    <Container>
      {isNavbarShown ? (
        <NavbarWithAction
          title="Packages history"
          navigation={navigation}
          hasFilter={!isLoading && !isFetchingNextPage}
          onClickFilter={() => setSwipeablePanelActive(true)}
          hasBackArrow
          hasSearch
          onClickSearch={() => setIsNavbarShown(false)}
          fullScreen
        />
      ) : (
        <MySearchbar
          placeholder={'Search by tracking number'}
          setValue={(val: string) =>
            setQueryParams({...queryParams, searchKey: val})
          }
          resetSearch={() => {
            setQueryParams(initialQueryParams);
            setIsNavbarShown(true);
          }}
        />
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FlatList
          contentContainerStyle={{padding: 16}}
          data={data?.pages.map(pageItem => pageItem.data).flat()}
          ListEmptyComponent={
            <EmptyList message="No packages for you at the moment" />
          }
          renderItem={({item}) => (
            <PackagesItem
              packageItem={item}
              itemClickHandler={() =>
                navigation.navigate(E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS, {
                  packageItem: item,
                })
              }
            />
          )}
          onRefresh={refetch}
          refreshing={isRefetching}
          onEndReached={loadMore}
          onEndReachedThreshold={0.3}
          ListFooterComponent={isFetchingNextPage ? <LoadingSpinner /> : null}
        />
      )}
      <FilterPackageHistory
        panelActive={swipeablePanelActive}
        setPanelActive={(newState: boolean) =>
          setSwipeablePanelActive(newState)
        }
        updateFilters={(filters: {
          startDate: string;
          endDate: string;
          selectedUnit: string;
          selectedStatus: boolean;
          claimedOnly: boolean;
        }) =>
          setQueryParams({
            ...queryParams,
            dateFrom: filters.startDate,
            dateTo: filters.endDate,
            unitId: filters.selectedUnit,
            allClaimednUnclaimed: filters.selectedStatus,
            claimedOnly: filters.claimedOnly,
          })
        }
      />
    </Container>
  );
}
