import React, { useCallback } from 'react';
import {
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
  StyleSheet,
  Keyboard,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Icon from 'react-native-vector-icons/Feather';
import { RegisterActionBar, RegisterPrevButton } from '../../../components';
import { useDispatch } from 'react-redux';
import { RegistrationData } from '../types';
import { resetRegisterData, setRegisterData } from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from '../../../components/TextInput';
import { checkEmail, emailUsername } from '../api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useFocusEffect } from '@react-navigation/native';
import { showErrorMessage, showSuccessMessage } from '../../../service/flashMessage';

const emailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email'),
});

const EmailCheck = ({ navigation }) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(emailValidationSchema),
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog = () => setDialogOpen(false);
  const [usernameMatchesEmail, setUsernameMatchesEmail] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [sendingUsername, setSendingUsername] = React.useState(false);

  const handleEmailSubmit = async (values: { email: string }) => {
    Keyboard.dismiss();
    setLoading(true);
    const emailExists = await checkEmail(values.email);

     //@ts-ignore
    dispatch(setRegisterData({emailExists: emailExists.emailExists}));

    setLoading(false);
    if (emailExists.emailExists) {
      setUsernameMatchesEmail(emailExists.asUserName); 
      setDialogOpen(true);
      return;
    }

    const registrationData: RegistrationData = {
      email: values.email,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Phone');
  };

  const continueWithDuplicateEmail = () => {
    const registrationData: RegistrationData = {
      email: control._formValues.email,
    };
    //@ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Phone');
  };

  const sendUsername  = async(email: string) => {
    setSendingUsername(true);
   try {
    await emailUsername(email);
    showSuccessMessage(`Username sent to ${email}`);
   } catch (error) {
    showErrorMessage(error.message);
   }
   finally {
    setSendingUsername(false);
   }
  }

  useFocusEffect(
    useCallback(() => {
      //@ts-ignore
      dispatch(resetRegisterData());
    }, []),
  );

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeAreaView,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={1} />
        <ScrollView
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled">
          <View style={styles.view}>
            <Heading size="lg">Email</Heading>
            <Text style={styles.text}>
              Type in a valid email address. We will send you a code to confirm
              your email.
            </Text>
            <Text style={styles.text}>The code is case sensitive.</Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="email"
              placeholder="e.g johndoe@xyz.com"
              label="Email"
              keyboardType="email-address"
              autoCapitalize="none"
              InputRightElement={
                <TouchableOpacity style={styles.icon}>
                  <Icon name={'mail'} size={24} color="grey" />
                </TouchableOpacity>
              }
              autoFocus
            />
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <RegisterPrevButton />
          <Button
            isLoading={loading}
            isLoadingText="Checking..."
            onPress={handleSubmit(handleEmailSubmit)}
            style={styles.button}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        onNoPressed={closeDialog}
        onYesPressed={() =>
          usernameMatchesEmail
            ? navigation.navigate('Sign In', {email: control._formValues.email})
            : navigation.navigate('Sign In')
        }
        title="Email is already in use"
        description={
        sendingUsername ?  'Sending username ...' :  usernameMatchesEmail
            ? `A profile username already exists for this email. \nIf you continue, you can't use this as your username. \nLogin takes you to login page.`
            : 'Email is already in use, please select an option. \n- Edit email to use a different email or login to continue. \n- Email username sends your username to your email. \n- Login takes you to login page.'
        }
        negativeLabel={'EDIT EMAIL'}
        positiveLabel="LOGIN "
        centerButton
        centerButtonLabel={ 
          usernameMatchesEmail ? 'CONTINUE ' : sendingUsername ? 'LOADING...' : 'EMAIL\nUSERNAME'
        }
        onCenterButtonPressed={() =>
          usernameMatchesEmail ? continueWithDuplicateEmail() : sendUsername(control._formValues.email)
        }
      />
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    marginVertical: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  view: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  text: {
    marginTop: 16,
    fontSize: 18,
  },
  icon: {
    paddingHorizontal: 8,
  },
  hStack: {
    margin: 24,
    marginTop: 32,
    marginBottom: 36
  },
  button: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default EmailCheck;
