import React from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Input from '../../../components/Input';
import {yupResolver} from '@hookform/resolvers/yup' 
import {useDispatch, useSelector} from 'react-redux';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {RootState} from '../../../redux/reducers';
import {RegistrationData} from '../types';
import {setRegisterData} from '../registerReducer';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import { useForm } from 'react-hook-form';
import TextInput from '../../../components/TextInput';

const firstNameValidationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
});

const Name = ({navigation}) => {
  const dispatch = useDispatch();
  const {firstName, lastName, dlNumber, addressLine1, expiration, dateOfBirth} =
    useSelector((state: RootState) => state?.register);

  const {control, handleSubmit} = useForm({
    defaultValues:{
      firstName,
      lastName,
      //@ts-ignore
      dlNumber,
      addressLine1
    },
    resolver: yupResolver(firstNameValidationSchema)
  })  
  const {width} = useWindowDimensions();

  const handleDetailsSubmit = (values: {
    firstName: string;
    lastName: string;
    addressLine1: string;
    dlNumber: string;
  }) => {
    const registrationData: RegistrationData = {
      firstName: values.firstName,
      lastName: values.lastName,
      addressLine1: values.addressLine1,
      dlNumber: values.dlNumber,
    };
    // @ts-ignore
    dispatch(setRegisterData(registrationData));
    navigation.navigate('Photo Capture');
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeAreaView,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={6} />
        <ScrollView keyboardShouldPersistTaps="handled">
          <View style={styles.container}>
            <Heading size="lg">Personal details </Heading>
            <Text style={styles.description}>
              Please look over all information to make sure it is accurate.
            </Text>
            <TextInput
              //@ts-ignore
              control={control}
              name="firstName"
              label="First name *"
            />
            <TextInput
              //@ts-ignore
              control={control}
              name="lastName"
              label="Last name *"
            />
            <TextInput
              //@ts-ignore
              control={control}
              name="dlNumber"
              label="Driver's license number"
            />
            <View style={styles.inputContainer}>
              <Text style={styles.label}>Birth date </Text>
              <Input
                style={styles.input}
                value={dateOfBirth}
              />
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.label}>
                Driver's license expiry date
              </Text>
              <Input
                style={styles.input}
                value={expiration}
              />
            </View>
            <TextInput
              //@ts-ignore
              control={control}
              name="addressLine1"
              label="Address"
            />
          </View>
        </ScrollView>
        <HStack style={styles.buttonContainer}>
          <RegisterPrevButton />
          <Button
            // isDisabled={!isValid}
            onPress={handleSubmit(handleDetailsSubmit)}
            style={styles.button}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    marginVertical: 16,
  },
  container: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  description: {
    marginTop: 32,
    fontSize: 18,
    marginBottom: 16,
  },
  inputContainer: {
    flex: 1,
    marginTop: 24,
  },
  label: {
    marginVertical: 8,
  },
  input: {
    marginTop: 8,
  },
  buttonContainer: {
    margin: 24,
    marginTop: 32,
  },
  button: {
    flex: 1,
  },
});

export default Name;
