import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Text from '../../../components/Text';
import {Card, Divider} from 'react-native-paper';
import {colorSwatches, styles} from '../../../styles';
import {LoadingSpinner, Navbar} from '../../../components';
import {KeyLog} from '../types';
import {useKeyLog} from '../../../hooks/keylogs';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';

const KeyLogDetails = ({navigation, route}) => {
  const {isLoading, data} = useKeyLog(route.params?.keyLogItem?.id);
  const keyLog: KeyLog = data;


  const pictureIn = keyLog?.pictureIn
    ? `data:image/jpeg;base64,${keyLog?.pictureIn}`
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAG1BMVEXr6+vY2Njp6ena2trd3d3g4ODj4+Pm5ubW1tbKf8WKAAADqklEQVR4nO3a25biIBBGYUMOzPs/8aiQSOAHk8zYTbn2d9m0LsqC4pDcbgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY9w83S2u1u5D+0926f+ah2jyqnkZY/Mom/vnpuFF5OlNswFpBMMwZ61u3DVbDHEZhlYM+/iHsTpXu5Xl6G432ea8Nc9x/4oQhjFpzRNsMYlFCnfjtN1qgitDSNJUpvC+ovxmdy9QMbzSNInGsfV1HSqnYRKEV42DsYkoI1zLqW78igjjkiDqzLdEGKqJqkLfEmGoprIKmYtQR9GK0Fot1eUy5Kk1gg1pVJNmFbJDLerNCM2dgvVkq0dobRqq09PQrDTmpqHOVAhDViFja8WTCGOuthhMoUxidV9q5/zr054WM7F+tkiXCt9tVXXzM6TXBXBRbLau50vJFqCP39FlkH6LZ1xPulnRfM21bO+95tZN23d0uP7vRt4U0+hHEcfD8va/+ys9+4G3Vo7k3nvaFZMk42u6soz3djOVr+JbwpYpzs7sA3HWjvMaeF5+eiuvRd1MRpnzurO7v5d7oL6SKPZoJztY7tT7moliq3luK/3PX/BpajN9quCrffqnOnuJivBMqZAHqs9194L2Ffd78kbgc929QEZ4fCLpW50P9ve8xon+CAOnfi+H2eFhKj/d12qh+3i4msoR0NnmW46zMgvOe7HBkdf8vb2CImtFFuH98Bfk7xDpCH+w94eo+9E0wrjT3prSDMkI+9qW3t4+uJ7LK41XjPKR+C/E8IaoNVuEiy6W21j9YyCFcibGXjp9w5+EUf4AnS0VQVlOwzjUa+UukNpHe5OnKkwl/Rxm+59nKEX++wywOAY/x2A7wDWY7MfpbLFPTEU3q1Nw/0Psfpze1vqdZM14TrEDAYYQk8nadYC3beELd2uHAoxZjHdvBt6Jds4v8dHDwQDjXLx/bqncyvXqbZHJQjSn8tqM1Nv17yGVN58qutzDtMnn+A39LoE1JyZhYG0qVt6JaujwuNR0cow+9L8Qps7U0S2JpurphRTaKjany0xgKImXUmhpJp4vpIGdcnpxkBpaEy8OUju1pnX11GZld3plMQysTMTrEfb1ULSOCImwf/Va+nh2OI31Umulllbu2MY5XqU5r59FGdq2iRuMac4e+y7iZzCTwmKcjrPajvl8c2dlRxPE10rvM2+S4QV+Xt97HvMkG+Ae7114967fz/9SL2gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX+wvqtkVJSpt2GwAAAAASUVORK5CYII=';

  const pictureOut = keyLog?.pictureOut
    ? `data:image/jpeg;base64,${keyLog.pictureOut}`
    : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAG1BMVEXr6+vY2Njp6ena2trd3d3g4ODj4+Pm5ubW1tbKf8WKAAADqklEQVR4nO3a25biIBBGYUMOzPs/8aiQSOAHk8zYTbn2d9m0LsqC4pDcbgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY9w83S2u1u5D+0926f+ah2jyqnkZY/Mom/vnpuFF5OlNswFpBMMwZ61u3DVbDHEZhlYM+/iHsTpXu5Xl6G432ea8Nc9x/4oQhjFpzRNsMYlFCnfjtN1qgitDSNJUpvC+ovxmdy9QMbzSNInGsfV1HSqnYRKEV42DsYkoI1zLqW78igjjkiDqzLdEGKqJqkLfEmGoprIKmYtQR9GK0Fot1eUy5Kk1gg1pVJNmFbJDLerNCM2dgvVkq0dobRqq09PQrDTmpqHOVAhDViFja8WTCGOuthhMoUxidV9q5/zr054WM7F+tkiXCt9tVXXzM6TXBXBRbLau50vJFqCP39FlkH6LZ1xPulnRfM21bO+95tZN23d0uP7vRt4U0+hHEcfD8va/+ys9+4G3Vo7k3nvaFZMk42u6soz3djOVr+JbwpYpzs7sA3HWjvMaeF5+eiuvRd1MRpnzurO7v5d7oL6SKPZoJztY7tT7moliq3luK/3PX/BpajN9quCrffqnOnuJivBMqZAHqs9194L2Ffd78kbgc929QEZ4fCLpW50P9ve8xon+CAOnfi+H2eFhKj/d12qh+3i4msoR0NnmW46zMgvOe7HBkdf8vb2CImtFFuH98Bfk7xDpCH+w94eo+9E0wrjT3prSDMkI+9qW3t4+uJ7LK41XjPKR+C/E8IaoNVuEiy6W21j9YyCFcibGXjp9w5+EUf4AnS0VQVlOwzjUa+UukNpHe5OnKkwl/Rxm+59nKEX++wywOAY/x2A7wDWY7MfpbLFPTEU3q1Nw/0Psfpze1vqdZM14TrEDAYYQk8nadYC3beELd2uHAoxZjHdvBt6Jds4v8dHDwQDjXLx/bqncyvXqbZHJQjSn8tqM1Nv17yGVN58qutzDtMnn+A39LoE1JyZhYG0qVt6JaujwuNR0cow+9L8Qps7U0S2JpurphRTaKjany0xgKImXUmhpJp4vpIGdcnpxkBpaEy8OUju1pnX11GZld3plMQysTMTrEfb1ULSOCImwf/Va+nh2OI31Umulllbu2MY5XqU5r59FGdq2iRuMac4e+y7iZzCTwmKcjrPajvl8c2dlRxPE10rvM2+S4QV+Xt97HvMkG+Ae7114967fz/9SL2gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAX+wvqtkVJSpt2GwAAAAASUVORK5CYII=';

  return (
    <Container>
      <Navbar navigation={navigation} title="Key Log details" />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ScrollView
            style={{ marginHorizontal: 8, paddingVertical: 16 }}
            showsVerticalScrollIndicator={false}>
            <Card style={{ padding: 8, marginBottom: 32 }}>
              <View style={{ flexDirection: 'row', marginVertical: 12 }}>
          <Text style={styles.textLabel}>Status</Text>
          <Text style={styles.detailsText}>{keyLog?.status}</Text>
              </View>
              <Divider />
              <View style={{ flexDirection: 'row', marginVertical: 12 }}>
          <Text style={styles.textLabel}>Key given to</Text>
          <Text style={styles.detailsText}>{keyLog?.keyGivenTo}</Text>
              </View>
              <Divider />
              <View style={{ flexDirection: 'row', marginVertical: 12 }}>
          <Text style={styles.textLabel}>Contact Email</Text>
          <Text style={styles.detailsText}>{keyLog?.contactEmail}</Text>
              </View>
              <Divider />
              <View style={{ flexDirection: 'row', marginVertical: 12 }}>
          <Text style={styles.textLabel}>Contact Cell</Text>
          <Text style={styles.detailsText}>{keyLog?.contactCell}</Text>
              </View>
              <Divider />
              <View style={{ flexDirection: 'row', marginVertical: 12 }}>
          <Text style={styles.textLabel}>Keys On ring</Text>
          <Text style={styles.detailsText}>{keyLog?.keysOnRing}</Text>
              </View>

              <View style={detailStyles.sectionContainer}>
          {keyLog?.whoGaveKey.id && (
            <>
              <View style={{ flexDirection: 'row', marginVertical: 12 }}>
                <Text style={styles.textLabel}>Who Gave Key</Text>
                <Text
            style={
              styles.detailsText
            }>{`${keyLog?.whoGaveKey?.firstName} ${keyLog?.whoGaveKey?.lastName}`}</Text>
              </View>
              <Divider />
            </>
          )}
          <View style={{ flexDirection: 'row', marginVertical: 12 }}>
            <Text style={styles.textLabel}>Checked out note</Text>
            <Text style={styles.detailsText}>
              {keyLog?.checkedOutNote}
            </Text>
          </View>
          <View style={{ flexDirection: 'row', marginVertical: 12 }}>
            <Text style={styles.textLabel}>Time key out</Text>
            <Text style={styles.detailsText}>{keyLog?.timeKeyOut}</Text>
          </View>
          <Divider />
          <View style={{ flexDirection: 'row', marginVertical: 12 }}>
            <Text style={styles.textLabel}>Key date time out</Text>
            {keyLog?.keyDateTimeOut ? (
              <Text style={styles.detailsText}>
                {new Date(keyLog?.keyDateTimeOut).toLocaleString()}
              </Text>
            ) : (
              <Text style={styles.detailsText}>Not Provided</Text>
            )}
          </View>
          <Divider />
          <View style={{ flexDirection: 'row', marginVertical: 12 }}>
            <Text style={styles.textLabel}>
              Excpected return date time
            </Text>
            {keyLog?.expectedReturnDatTime ? (
              <Text style={styles.detailsText}>
                {new Date(keyLog?.expectedReturnDatTime).toLocaleString()}
              </Text>
            ) : (
              <Text style={styles.detailsText}>Not Provided</Text>
            )}
          </View>
          <Divider />
          <Text style={styles.textLabel}>Picture Out</Text>
          <ZoomableImage
            title="Picture Out"
            source={{
              uri: pictureOut,
            }}
            style={styles.image}
          />
              </View>

              <View style={detailStyles.sectionContainer}>
          {keyLog?.whoPutKeyBack.id && (
            <>
              <View style={{ flexDirection: 'row', marginVertical: 12 }}>
                <Text style={styles.textLabel}>Who put key back</Text>
                <Text style={styles.detailsText}>
            {`${keyLog?.whoPutKeyBack?.firstName} ${keyLog?.whoPutKeyBack?.lastName}`}
                </Text>
              </View>
              <Divider />
            </>
          )}
          <View style={{ flexDirection: 'row', marginVertical: 12 }}>
            <Text style={styles.textLabel}>Check in note</Text>
            <Text style={styles.detailsText}>
              {keyLog?.checkedInNote}
            </Text>
          </View>
          <Divider />
          <View style={{ flexDirection: 'row', marginVertical: 12 }}>
            <Text style={styles.textLabel}>Key date time in</Text>
            {keyLog?.keyDateTimeIn ? (
              <Text style={styles.detailsText}>
                {new Date(keyLog?.keyDateTimeIn).toLocaleString()}
              </Text>
            ) : (
              <Text style={styles.detailsText}>Not Provided</Text>
            )}
          </View>
          <Text style={styles.textLabel}>Picture In</Text>
          <ZoomableImage
            title="Picture In"
            source={{
              uri: pictureIn,
            }}
            style={styles.image}
          />
              </View>
            </Card>
          </ScrollView>
        </>
      )}
    </Container>
  );
};

const detailStyles = StyleSheet.create({
  sectionContainer: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colorSwatches.gray.color,
    padding: 6,
  },
});

export default KeyLogDetails;
