import HStack from './HStack';
import Select from './Select';
import React, {useEffect, useState} from 'react';
import {View, TouchableOpacity} from 'react-native';
import Text from '../components/Text';
import Ionicon from 'react-native-vector-icons/Ionicons';

type DateFrame = {
  id: string;
  unitId: string;
  type: string | null;
  startDate: string;
  endDate: string | null;
  shortDescription: string | null;
  notes: string | null;
  status: string | null;
  projectId: string | null;
  reservationId: string | null;
  overlapAllowed: string | null;
};

const DateFrameChooser = ({
  dateFrames,
  selectHandler,
  addClickHandler,
  selectedDateFrame,
}) => {
  const [_selectedDateFrame, _setSelectedDateFrame] =
    useState(selectedDateFrame);

  const onSelectedItemsChange = (dateFrame: string) => {
    _setSelectedDateFrame(dateFrame);
    selectHandler(dateFrame);
  };

  useEffect(() => {
    _setSelectedDateFrame(selectedDateFrame);
  }, [selectedDateFrame]);


  return (
    <View style={{flex: 1}}>
      <Text style={{marginTop: 16, marginBottom: 8}}>Date frame</Text>
      <HStack>
        <Select
          minWidth={270}
          selectedValue={selectedDateFrame}
          placeholder="Select a dateframe"
          onValueChange={val => onSelectedItemsChange(val)}>
          {dateFrames?.map(dateFrame => (
            <Select.Item
              key={dateFrame?.id}
              label={
                dateFrame?.shortDescription
                  ? dateFrame?.shortDescription
                  : `${new Date(
                      dateFrame?.startDate,
                    ).toLocaleDateString()}-${new Date(
                      dateFrame?.endDate,
                    ).toLocaleDateString()}   `
              }
              value={dateFrame.id}
            />
          ))}
        </Select>
        <View style={{marginLeft: 12}}>
          <TouchableOpacity
            onPress={() => addClickHandler()}
            style={{
              marginBottom: 8,
              paddingHorizontal: 8,
              paddingVertical: 8,
              backgroundColor: '#68d391',
              borderRadius: 4,
            }}>
            <HStack>
              <Ionicon name="add" size={20} color="#fff" />
              <Text
                style={{
                  color: '#fff',
                  textAlign: 'center',
                }}>
                Add new
              </Text>
            </HStack>
          </TouchableOpacity>
        </View>
      </HStack>
    </View>
  );
};

export default DateFrameChooser;
