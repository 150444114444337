import React from 'react';
import {Card} from 'react-native-paper';
import {View, ImageBackground, useWindowDimensions, StyleSheet} from 'react-native';
// import QRCode from 'react-native-qrcode-svg';
import Text from '../../../components/Text';
import ZoomableImage from '../../../components/ZoomableImage';
import QRCode from 'react-native-qrcode-svg';

const AccessCard = ({
  personProfilePicture,
  unitPeopleLinkName,
  personName,
  personType,
  allowedInToday,
  id,
  unitDisplay,
  siteName,
  buildingName, 
  communityName,
  unitStatus
}) => {

  const {width} = useWindowDimensions();

  return (
    <Card style={styles.card}>
      <ImageBackground
        source={require('../assets/card_background.jpg')}
        resizeMode="cover"
        imageStyle={styles.imageBackground}
        style={styles.imageBackgroundContainer}>
        <View style={styles.topBar}>
          <View style={styles.topBarInner}></View>
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.siteNameText}>
            {siteName}
          </Text>
          <View style={styles.row}>
            <View style={styles.zoomableImageContainer}>
              <ZoomableImage
                title="My picture in unit"
                style={styles.zoomableImage}
                source={{uri: personProfilePicture}}
              />
            </View>
            <View style={[styles.detailsContainer, {width: width - 200}]}>
              <Text style={styles.personTypeText}>
                {personType}
              </Text>
              <Text style={styles.unitText}>
                {`Unit: ${unitDisplay}`}
              </Text>
              <Text style={styles.buildingText}>
                {`Unit Status: ${unitStatus}`}
              </Text>
              <Text style={styles.buildingText}>
                {`Building: ${buildingName}`}
              </Text>
              <Text style={styles.communityText} numberOfLines={2}>
                {`Community: ${communityName}`}
              </Text>
              <View style={styles.accessContainer}>
                <Text style={styles.accessText}>
                  Access Today
                </Text>
                <View style={styles.accessIndicatorContainer}>
                  <View style={[styles.accessIndicator, {backgroundColor: allowedInToday ? 'green' : 'red'}]}>
                    <Text style={styles.accessIndicatorText} numberOfLines={1}>
                      Today
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Text style={styles.personNameText}>
            {`${personName} (${unitPeopleLinkName})`}
          </Text>
        </View>
      </ImageBackground>
      <View style={styles.qrCodeContainer}>
        <QRCode value={`P${id}`} size={100} />
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 8,
    marginBottom: 16,
    borderRadius: 6,
    marginTop: 16,
  },
  imageBackground: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  imageBackgroundContainer: {
    height: 400,
    width: '100%',
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
  },
  topBarInner: {
    width: 71,
    height: 20,
    backgroundColor: '#ffffff',
    borderRadius: 12,
  },
  contentContainer: {
    flexDirection: 'column',
    margin: 16,
    marginTop: 16,
  },
  siteNameText: {
    color: '#ffffff',
    fontWeight: '600',
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: 16,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  zoomableImageContainer: {
    borderRadius: 6,
    width: 170,
  },
  zoomableImage: {
    width: 170,
    height: 200,
    borderRadius: 5,
    borderWidth: 6,
    borderColor: '#ffffff',
  },
  detailsContainer: {
    marginLeft: 16,
  },
  personTypeText: {
    color: '#ffffff',
    fontWeight: '700',
    fontSize: 21,
  },
  unitText: {
    fontWeight: '700',
    color: '#ffffff',
    marginTop: 16,
  },
  buildingText: {
    color: '#ffffff',
    marginTop: 16,
  },
  communityText: {
    color: '#ffffff',
    marginTop: 16,
  },
  accessContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  accessText: {
    color: '#ffffff',
    marginTop: 16,
    marginBottom: 10,
  },
  accessIndicatorContainer: {
    flexDirection: 'row',
    height: 24,
    marginLeft: 4,
    marginTop: 8,
  },
  accessIndicator: {
    borderRadius: 12,
    paddingHorizontal: 8,
  },
  accessIndicatorText: {
    fontSize: 12,
    color: '#ffffff',
    marginHorizontal: 4,
  },
  personNameText: {
    fontSize: 18,
    fontWeight: '700',
    color: '#ffffff',
    paddingTop: 16,
    paddingBottom: 16,
  },
  qrCodeContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 16,
  },
});

export default AccessCard;
