import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import IconButton from '../../../components/IconButton';
import {Center} from 'native-base';
import ScreenContent from '../components/ScreenContent';
import isWeb from '../../../utils/isWeb';

const AccountSetupInfo = ({navigation}) => {
  return (
    <Center style={styles.center}>
      <ScreenContent>
        <IconButton
          icon={'close-outline'}
          size={30}
          style={styles.iconButton}
          onPress={() => navigation.goBack()}
        />
        <View style={styles.content}>
          <Heading>Account setup information </Heading>
          <Text style={styles.text}>
            To setup a new account, we will require you to provide :
          </Text>

          <HStack style={styles.hStack}>
            <View style={styles.circle}>
              <Text style={styles.circleText}>1.</Text>
            </View>
            <Text style={styles.hStackText}>
              Your personal information eg name .
            </Text>
          </HStack>

          <HStack style={styles.hStack}>
            <View style={styles.circle}>
              <Text style={styles.circleText}>2.</Text>
            </View>
            <Text style={styles.hStackText}>
              A picture of your driver's license.
            </Text>
          </HStack>
       
          <HStack style={styles.hStack}>
            <View style={styles.circle}>
              <Text style={styles.circleText}>3.</Text>
            </View>
            <Text style={styles.hStackText} numberOfLines={3}>
              Communication information e.g email and cell phone number .
            </Text>
          </HStack>

          <HStack style={styles.hStack}>
            <View style={styles.circle}>
              <Text style={styles.circleText}>4.</Text>
            </View>
            <Text style={styles.hStackText}>A photo of you.</Text>
          </HStack>

          <Button
            onPress={() => navigation.navigate('Trainning Video')}
            variant="link"
            size="lg"
            style={styles.button}>
            Install Help, Tap Here
          </Button>
          {isWeb && (
            <Button
              size="lg"
              py="4"
              mb="4"
              mt="16"
              width={'full'}
              onPress={() => navigation.navigate('Email Check')}
              style={styles.webButton}>
              Get started
            </Button>
          )}
        </View>
      </ScreenContent>
      {!isWeb && (
        <HStack mx="4">
          <Button
            size="lg"
            py="4"
            mb="4"
            width={'full'}
            onPress={() => {
              navigation.navigate('Email Check')
            }}
            style={styles.webButton}>
            Get started
          </Button>
        </HStack>
      )}
    </Center>
  );
};

const styles = StyleSheet.create({
  center: {
    marginBottom: 16,
    flex: 1,
    marginTop: 16,
  },
  iconButton: {},
  content: {
    marginHorizontal: 24,
    marginTop: 16,
    flex: 1,
  },
  text: {
    marginTop: 24,
    fontSize: 18,
  },
  hStack: {
    marginTop: 24,
  },
  circle: {
    borderWidth: 1,
    borderColor: '#E5E7EB',
    borderRadius: 50,
    width: 40,
    height: 40,
  },
  circleText: {
    textAlign: 'center',
    marginTop: 8,
  },
  hStackText: {
    marginTop: 8,
    marginLeft: 8,
    fontSize: 18,
  },
  button: {
    marginTop: 48,
    textAlign: 'center',
  },
  webButton: {
    marginHorizontal: 24,
  },
});

export default AccountSetupInfo;
