// @ts-nocheck
import  Button from './Button';
import  Checkbox from './Checkbox';
import Input from './Input';
import Select from './Select';
import  TextArea from './TextArea';
import  VStack from './VStack';
import React from 'react';
import {useState} from 'react';
import {View, TouchableOpacity, ScrollView, useWindowDimensions} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import {
  createDateFrame,
  dateFrameRequestAccess,
  updatePersonDateFrames,
  updateUnitLinkDateFrame,
} from '../api/dataframe';
import {showErrorMessage, showSuccessMessage} from '../service/flashMessage';
import {styles} from '../styles';
import {ThemedSwipeablePanel} from '.';
import {toLocalDateTime} from '../utils';
import {CalendarDateFrame} from '../@types/DateFrame';
import Text from '../components/Text';
import DateTimeInput from './DateTimeInput';
import isWeb from '../utils/isWeb';

const dataFrameInitialState = {
  id: '',
  unitId: '',
  type: 'Visitor',
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  shortDescription: '',
  notes: '',
  status: 'Active',
  // projectId: '',
  // reservationId: '',
  overlapAllowed: false,
};

interface CreateDateFramePanelProps {
  unitId: string;
  unitPeopleLinkId: string;
  navigation: Object;
  panelActive: boolean;
  setPanelActive: Function;
  onSubmitSuccess: Function;
  isLoggedInUser: boolean;
  editMode: boolean;
  initialState?: CalendarDateFrame;
}

export default function CreateDateFramePanel({
  unitId,
  unitPeopleLinkId,
  navigation,
  panelActive,
  setPanelActive,
  onSubmitSuccess,
  isLoggedInUser,
  editMode,
  initialState,
}: CreateDateFramePanelProps) {
  const [dateFrameState, setDateFrameState] = useState({
    ...dataFrameInitialState,
    unitId,
  });
  const [startPickerDateVisible, setStartDatePickerVisible] = useState(false);
  const [endDatePickerVisible, setEndDatePickerVisible] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  const {width} = useWindowDimensions();

  const handleStartDateConfirm = (date) => {
   // setStartDatePickerVisible(false);

    const newDate = new Date(date);
    newDate.setDate(date.getDate() + (parseInt(numberOfDays, 10) - 1));

    setDateFrameState({...dateFrameState, startDate: date, endDate: newDate});
  };

  const handleEndDateConfirm = (date) => {
   // setEndDatePickerVisible(false);
    const diffTime = new Date(date) - new Date(dateFrameState.startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setNumberOfDays(diffDays + 1);
    setDateFrameState({...dateFrameState, endDate: date});
  };

  const collectUserInput = async () => {
    setSubmitting(true);
    let dateFrame = {...dateFrameState};

    const start = new Date(dateFrame.startDate);
    const end = new Date(dateFrame.endDate);

    dateFrame = {
      ...dateFrame,
      startDate: toLocalDateTime(start, true),
      endDate: toLocalDateTime(end, false),
    };

    if (!dateFrameState.shortDescription) {
      const shortDescription = `${new Date(
        dateFrameState.startDate,
      ).toDateString()}- ${new Date(dateFrameState.endDate).toDateString()}`;
      dateFrame = {
        ...dateFrame,
        shortDescription: shortDescription,
      };
    }

    const dateFrameData = {
      unitId,
      ...dateFrame,
      description: dateFrame.shortDescription,
      shortDescription: dateFrame.shortDescription,
    };

    if (editMode) {
      try {
        await updateUnitLinkDateFrame(dateFrameState?.id, dateFrameData);
      } catch (error) {
        showErrorMessage(error.message);
      }
    } else {
      try {
        if (isLoggedInUser) {
          await dateFrameRequestAccess(dateFrameData);
        } else {
          const newDateFrame = await createDateFrame(dateFrameData);

          const dateFrameObj = {
            unitPeopleLinkId,
            description: dateFrame.shortDescription,
            dateFrameIds: [newDateFrame?.id],
          };

          await updatePersonDateFrames(dateFrameObj);
        }

        showSuccessMessage('Date frame created and linked to person');
        onSubmitSuccess();
      } catch (error) {
        showErrorMessage(error.message);
      }
    }
    setPanelActive(false);
    setSubmitting(false);
  };

  React.useEffect(() => {
    if (editMode) {
      setDateFrameState({
        ...initialState.dateFrame,
        startDate: new Date(initialState.dateFrame.startDate).toISOString(),
        endDate: new Date(initialState.dateFrame.endDate).toISOString(),
      });
      const diffTime =
        new Date(initialState.dateFrame.endDate) -
        new Date(initialState.dateFrame.startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setNumberOfDays(diffDays);
    }
  }, [editMode, initialState]);

  return (
    <ThemedSwipeablePanel
      onlyLarge
      fullWidth={!isWeb}
      style={{
        maxWidth: isWeb ? 1100 : width - 40,
      }}
      isActive={panelActive}
      onClose={() => setPanelActive(false)}
      showCloseButton>
      <>
        <ScrollView
          style={{paddingHorizontal: 8, paddingVertical: 16}}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}>
          <>
            <Text style={styles.panelTitle}>{`${
              editMode ? 'Edit dateframe' : 'Create and link new dateframe'
            } `}</Text>
            {editMode && (
              <Text
                style={{
                  backgroundColor: '#fecaca',
                  borderRadius: 8,
                  paddingVertical: 4,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                This will affect everybody who is linked to this unit
              </Text>
            )}
            <>
              <Text
                style={{
                  marginTop: 16,
                  marginBottom: 8,
                  flex: 1,
                }}>
                Date frame type
              </Text>
              <Select
                placeholder="Select dateframe type"
                minWidth={300}
                selectedValue={dateFrameState.type}
                onValueChange={val =>
                  setDateFrameState({...dateFrameState, type: val})
                }>
                <Select.Item label="Reservation" value="Reservation" />
                <Select.Item label="Lease" value="Lease" />
                <Select.Item label="Visitor" value="Visitor" />
              </Select>
            </>
            <DateTimeInput
              label="From"
              value={new Date(dateFrameState.startDate)}
              onConfirm={date => handleStartDateConfirm(date)}
              mode="date"
            />
            <VStack>
              <Text style={[styles.textInputLabel, {marginTop: 8}]}>Days</Text>
              <Input
                placeholder="Enter number of days"
                value={numberOfDays.toString()}
                keyboardType="number-pad"
                onChangeText={text => {
                  setNumberOfDays(parseInt(text === '' ? 0 : text, 10));
                  const date = new Date(dateFrameState.startDate);
                  date.setDate(
                    date.getDate() + parseInt(text === '' ? 0 : text, 10),
                  );
                  setDateFrameState({...dateFrameState, endDate: date});
                }}
                minWidth={200}
                InputRightElement={
                  <TouchableOpacity
                    style={{paddingHorizontal: 16}}
                    onPress={() => {
                      let newDays = numberOfDays + 1;
                      setNumberOfDays(newDays);
                      const date = new Date(dateFrameState.startDate);
                      date.setDate(date.getDate() + newDays - 1);
                      setDateFrameState({...dateFrameState, endDate: date});
                    }}>
                    <Icon name="plus" size={24} color="#007bff" />
                  </TouchableOpacity>
                }
                InputLeftElement={
                  <TouchableOpacity
                    disabled={numberOfDays === 1}
                    style={{paddingHorizontal: 16, flex: 1}}
                    onPress={() => {
                      let newDays =
                        numberOfDays - 1 >= 1 ? numberOfDays - 1 : 1;
                      setNumberOfDays(newDays);
                      const date = new Date(dateFrameState.startDate);
                      date.setDate(date.getDate() + newDays - 1);
                      setDateFrameState({...dateFrameState, endDate: date});
                    }}>
                    <Icon name="minus" size={24} color="#007bff" />
                  </TouchableOpacity>
                }
              />
            </VStack>
            <DateTimeInput
              label="To"
              value={new Date(dateFrameState.endDate)}
              onConfirm={date => handleEndDateConfirm(date)}
              mode="date"
            />
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 4,
                marginTop: 8,
              }}>
              <Text style={styles.checkBoxLabel}>Allow overlap ?</Text>
              <Checkbox
                value={dateFrameState.overlapAllowed ? true : false}
                isChecked={dateFrameState.overlapAllowed}
                onChange={value =>
                  setDateFrameState({
                    ...dateFrameState,
                    overlapAllowed: value,
                  })
                }
                aria-label="Allowed to overlap"
              />
            </View>
            <Text style={styles.textInputLabel}>Short description</Text>
            <Input
              placeholder="Enter short description"
              value={dateFrameState.shortDescription}
              onChangeText={text =>
                setDateFrameState({...dateFrameState, shortDescription: text})
              }
            />
            <>
              <Text style={styles.textInputLabel}>Notes</Text>
              <TextArea
                placeholder="Enter notes"
                value={dateFrameState.notes}
                style={styles.multiLinetextInput}
                multiline
                onChangeText={text =>
                  setDateFrameState({...dateFrameState, notes: text})
                }
              />
            </>
          </>
        </ScrollView>
        <Button
          onPress={collectUserInput}
          style={{
            marginHorizontal: 8,
            paddingVertical: 16,
            marginBottom: 64,
          }}
          mt="3"
          isDisabled={submitting}
          isLoading={submitting}
          isLoadingText="Submitting">
          {editMode ? 'Update' : 'Create and Link'}
        </Button>
      </>
    </ThemedSwipeablePanel>
  );
}
