import React from 'react';
import {LoadingSpinner} from '../../../components';
import {FlatList, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import VersionCode from '../../Auth/components/VersionCode';
import {colorSwatches} from '../../../styles';

export default function NoUnits({
  searchMode,
  isLoading,
  handleUnitCode,
  handlePeopleCode,
}: {
  searchMode: boolean;
  isLoading: boolean;
  handleUnitCode: () => void;
  handlePeopleCode: () => void;
}) {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (searchMode) {
    return (
      <Text style={styles.centerText}>
        No units found, try adjusting your search
      </Text>
    );
  }
  const data = [
    {
      id: 1,
      text: (
        <Text>
          Click here to use <Text style={{fontWeight: '900'}}>code </Text> you
          received in your welcome email to a unit.
        </Text>
      ),
      onPress: handleUnitCode,
      backgroundColor: colorSwatches.green.color,
    },
    {
      id: 2,
      text: (
        <Text>
          Click here to generate{' '}
          <Text style={{fontWeight: '900'}}> unit link code </Text>and get ADDED
          as a Guest or vendor to the unit.
        </Text>
      ),
      onPress: handlePeopleCode,
      backgroundColor: colorSwatches.primary.color,
    },
  ];

  return (
    <>
      <FlatList
        style={styles.list}
        data={data}
        renderItem={({item}) => (
          <Button
            key={item.id}
            style={[styles.button, {backgroundColor: item.backgroundColor}]}
            onPress={item.onPress}>
            <Text style={styles.buttonText}>
              {item.text}
            </Text>
          </Button>
        )}
        ListHeaderComponent={
          <>
            <Text style={styles.headerText}>
              Congratulations on creating your profile, now we need to get your
              unit(s) linked so you can be informed and in control.
            </Text>
            <Text style={styles.subHeaderText}>
              Select an option to continue:
            </Text>
          </>
        }
        ListFooterComponent={
          <Text style={styles.footerText}>
            Guests and Vendors are added to the unit by the owner.
          </Text>
        }
      />
      <View style={styles.versionCodeContainer}>
        <VersionCode />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  centerText: {
    textAlign: 'center',
  },
  list: {
    marginHorizontal: 16,
  },
  button: {
    marginTop: 8,
    borderRadius: 4,
  },
  buttonText: {
    padding: 16,
    color: '#fff',
  },
  headerText: {
    fontSize: 18,
  },
  subHeaderText: {
    fontSize: 18,
    marginVertical: 16,
  },
  footerText: {
    marginTop: 32,
    fontSize: 18,
  },
  versionCodeContainer: {
    position: 'relative',
    marginTop: 100,
  },
});
