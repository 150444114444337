// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import DateTimeInput from '../../../components/DateTimeInput';
import {fetchLinksByPerson} from '../../../api/links';
import {RootStore} from '../../../redux/store';
import {FilterAlerts, GetAlerts} from '../alertsReducer';
import {ApprovalFilterSection} from '../../../components';
import {makeId} from '../../../utils';
import {EventNames} from '../../../@types/EventNames';
import Button from '../../../components/Button';
import HStack from '../../../components/HStack';
import Select from '../../../components/Select';
import {Modal} from 'native-base';
import {showErrorMessage} from '../../../service/flashMessage';

export default function FilterPanel({panelActive, setPanelActive, activeTab}) {
  const personId = useSelector((state: RootStore) => state.auth?.user?.id);
  const [units, setUnits] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);
  
  const dispatch = useDispatch();

  const eventNames = [
    {
      label: 'Unit Link',
      value: EventNames.UNITLINK,
    },
    {
      label: 'UnitLink Denied',
      value: EventNames.UNITLINK_DENIED,
    },
    {
      label: 'UnitLink Approved',
      value: EventNames.UNITLINK_APPROVED,
    },
    {
      label: 'Visitor Screen',
      value: EventNames.VISITOR_SCREEN,
    },
    {
      label: 'Visitor Entrance Approved',
      value: EventNames.VISITOR_APPROVED_ENTRANCE,
    },
    {
      label: 'Visitor Entrance Denied',
      value: EventNames.VISITOR_DENIED_ENTRANCE,
    },
    {
      label: 'Request Access',
      value: EventNames.REQUEST_ACCESS,
    },
    {
      label: 'Request Access Approved',
      value: EventNames.REQUEST_ACCESS_APPROVED,
    },
    {
      label: 'RequestAccessDenied',
      value: EventNames.REQUEST_ACCESS_DENIED,
    },
    {
      label: 'People Link',
      value: EventNames.PEOPLELINK,
    },
    {
      label: 'People Link Approved',
      value: EventNames.PEOPLELINK_APPROVED,
    },
    {
      label: 'People Link Denied',
      value: EventNames.PEOPLELINK_DENIED,
    },
  ];

  const fetchUnits = async () => {
    try {
      const unitLinks = await fetchLinksByPerson(1, personId);
      const unitLinksMap = unitLinks.map(unitLink => {
        return {
          id: unitLink.unit.id,
          name: unitLink.unitNickname
            ? unitLink.unitNickname
            : unitLink.unit.unitName,
        };
      });
      setUnits(unitLinksMap);
    } catch (error) {}
  };

  useEffect(() => {
    fetchUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilters = () => {
    const startTime = selectedStartDate
      ? new Date(selectedStartDate).toISOString()
      : null;
    const endTime = selectedEndDate
      ? new Date(selectedEndDate).toISOString()
      : null;

    if (new Date(endTime).getTime() < new Date(startTime).getTime()) {
      showErrorMessage('End date must be greater than start time');
      return;
    }

    const filterArgs = {
      unit: selectedUnit,
      eventName: selectedType,
      startDate: startTime,
      endDate: endTime,
    };
    dispatch(FilterAlerts(filterArgs));

    dispatch(GetAlerts(filterArgs));

    setPanelActive(false);
  };

  const resetFilters = () => {
    dispatch(
      FilterAlerts({
        unit: null,
        eventName: null,
        startDate: null,
        endDate: null,
      }),
    );
    dispatch(GetAlerts());
    setSelectedUnit('');
    setSelectedType('');
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setPanelActive(false);
  };

  const NotificationFilterSection = () => {
    return (
      <View>
        <View style={panelStyles.selectContainer}>
          <Text style={panelStyles.text}>Unit</Text>
          <Select
            maxWidth={350}
            minWidth={200}
            selectedValue={selectedUnit}
            placeholder="*Select unit *"
            onValueChange={value => {
              setSelectedUnit(value);
            }}>
            {units.map(unit => (
              <Select.Item
                label={`${unit.name} `}
                value={unit.id}
                key={unit.id}
              />
            ))}
          </Select>
        </View>
        <View style={panelStyles.selectContainer}>
          <Text style={panelStyles.text}>Event</Text>
          <Select
            maxWidth={350}
            minWidth={200}
            selectedValue={selectedType}
            onValueChange={value => {
              setSelectedType(value);
            }}
            placeholder="*Select type *">
            {eventNames.map(event => (
              <Select.Item
                key={makeId(16)}
                label={event.label}
                value={event.value}
              />
            ))}
          </Select>
        </View>
        <DateTimeInput
          label="From"
          value={selectedStartDate}
          onConfirm={date => {
            setSelectedStartDate(date);
          }}
          mode="date"
          maximumDate={new Date()}
        />
        <DateTimeInput
          label="To"
          value={selectedEndDate}
          onConfirm={date => {
            setSelectedEndDate(date);
          }}
          mode="date"
          maximumDate={new Date()}
        />
        <HStack style={panelStyles.hStack}>
          <Button
            colorScheme="primary"
            mr={2}
            py={4}
            style={panelStyles.button}
            onPress={() => setFilters()}>
            Apply
          </Button>
          <Button
            onPress={() => resetFilters()}
            colorScheme="primary"
            variant="outline"
            py={4}
            style={panelStyles.button}>
            Reset
          </Button>
        </HStack>
      </View>
    );
  };

  return (
    <Modal isOpen={panelActive} onClose={() => setPanelActive(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Filter</Modal.Header>
        <Modal.Body>
          {activeTab === 'Notifications' ? (
            <NotificationFilterSection />
          ) : (
            <ApprovalFilterSection
              units={units}
              closePanel={() => setPanelActive(false)}
            />
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const panelStyles = StyleSheet.create({
  selectContainer: {
    margin: 8,
    marginBottom: 0,
    justifyContent: 'space-between',
  },
  text: {
    marginTop: 16,
  },
  hStack: {
    flex: 1,
    marginTop: 24,
  },
  button: {
    flex: 1,
  },
});
