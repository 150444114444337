// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {ScrollView, View, StyleSheet, ActivityIndicator} from 'react-native';
import Text from '../../../components/Text';
import {
  Card,
  Divider,
  TouchableRipple,
} from 'react-native-paper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {ApprovalSection, Navbar} from '../../../components';
import {styles as globalStyles} from '../../../styles';
import {Approval} from '../../../@types/Approval';
import {residentApiWithAuth} from '../../../api/resident';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import Container from '../../../components/Container';

const ApprovalDetail = ({navigation, route}) => {
  const {id, unitId} = route.params;
  const [approval, setApproval] = useState<Approval>({});
  const [isLoading, setIsLoading] = useState(true);

  //if this person's unitlink is allowed to approve for this unit, show  buttons
  const unitLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  )?.filter(link => link.unit.id === unitId)[0];
  const requiresResidentApproval =
    unitLink?.allowedtoApprove && approval.residentApprovalStatus === 'Pending';

  const residentApprovalDate = approval?.residentApprovalDateTime
    ? new Date(approval.residentApprovalDateTime).toLocaleDateString()
    : 'Date not available';
  const managementApprovalDate = approval?.managementApprovalDateTime
    ? new Date(approval.managementApprovalDateTime).toLocaleDateString()
    : 'Date not available';

  //Refresh approval on [Approve, Deny]

  useEffect(() => {
    fetchApproval(id);
  }, [id]);

  const fetchApproval = async (approvalId: string) => {
    setIsLoading(true);
    try {
      const res = await residentApiWithAuth().get(`/approvals/${approvalId}`);
      const result = await res.data;
      if (result) {
        setApproval(result);
      }
    } catch (error) {
      showErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  //Refresh approval history on [Approve, Deny]
  async function handleApprove(approve: boolean) {
    setIsLoading(true);
    try {
      const res = await residentApiWithAuth().post('/approvals', {
        id,
        approve,
      });
      const result = res.data;
      if (result.errors) {
        showErrorMessage(result.errors[0]);
      } else {
        await fetchApproval(id);
        navigation.goBack();
        showSuccessMessage('Your response has been sent');
      }
    } catch (error) {
      const message = error.response.data.errors[0];
      showErrorMessage(message);
    }
    setIsLoading(false);
  }

  return (
    <Container>
      <Navbar navigation={navigation} title="Approval details" />
      <ScrollView style={styles.container}>
        {isLoading ? (
          <ActivityIndicator style={styles.loadingIndicator} />
        ) : (
          <Card style={styles.card}>
            <Text>{approval.message}</Text>
            <ApprovalSection approval={approval} />
            <View style={styles.row}>
              <Text style={styles.textLabel}>Requester name </Text>
              <Text style={styles.detailsText}>
                {approval.approvalRequesterName}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.textLabel}>Request date </Text>
              <Text style={styles.detailsText}>
                {new Date(
                  approval.approvalRequestDateTime,
                ).toLocaleDateString()}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.textLabel}>Description</Text>
              <Text style={styles.detailsText}>{approval.description}</Text>
            </View>
            <Divider />

            <View style={styles.row}>
              <Text style={styles.textLabel}>Resident Approval Status</Text>
              <Text style={styles.detailsText}>
                {approval.residentApprovalStatus}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.textLabel}>Resident Approval Name</Text>
              <Text style={styles.detailsText}>
                {approval.residentApprovalName}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.textLabel}>Resident Approval Date time</Text>
              <Text style={styles.detailsText}>{residentApprovalDate}</Text>
            </View>
            <Divider />

            <View style={styles.row}>
              <Text style={styles.textLabel}>Management Approval Status</Text>
              <Text style={styles.detailsText}>
                {approval.managementApprovalStatus}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.textLabel}>Management Approval Name</Text>
              <Text style={styles.detailsText}>
                {approval.managementApprovalName}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.textLabel}>
                Management Approval Date time
              </Text>
              <Text style={styles.detailsText}>{managementApprovalDate}</Text>
            </View>
            <Divider />
            <View style={styles.row}>
              <Text style={styles.textLabel}>Management Approval Name</Text>
              <Text style={styles.detailsText}>
                {approval.managementApprovalName}
              </Text>
            </View>

            <View style={styles.row}>
              <Text style={styles.textLabel}>Type</Text>
              <Text style={styles.textLabel}>{approval.type}</Text>
            </View>
            <Divider />
            <View style={styles.row}>
              <Text style={styles.textLabel}>Unit name</Text>
              <Text style={styles.textLabel}>{approval?.unit?.unitName}</Text>
            </View>
            <Divider />
            {requiresResidentApproval && (
              <View style={styles.buttonContainer}>
                <TouchableRipple onPress={() => handleApprove(true)}>
                  <View style={[styles.button, styles.approveButton]}>
                    <Ionicons
                      name="checkmark-circle-outline"
                      size={16}
                      style={styles.icon}
                    />
                    <Text style={styles.buttonText}>
                      Approve
                    </Text>
                  </View>
                </TouchableRipple>
                <TouchableRipple onPress={() => handleApprove(false)}>
                  <View style={[styles.button, styles.denyButton]}>
                    <Ionicons name="close" size={16} style={styles.icon} />
                    <Text style={styles.buttonText}>
                      {'Deny '}
                    </Text>
                  </View>
                </TouchableRipple>
              </View>
            )}
          </Card>
        )}
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  loadingIndicator: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    padding: 8,
    marginVertical: 16,
  },
  row: {
    flexDirection: 'row',
    marginVertical: 8,
  },
  textLabel: {
    fontWeight: 'bold',
  },
  detailsText: {
    marginLeft: 8,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 8,
  },
  button: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderRadius: 4,
  },
  approveButton: {
    backgroundColor: 'green',
  },
  denyButton: {
    backgroundColor: 'red',
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    marginRight: 8,
  },
  icon: {
    marginRight: 8,
  },
});

export default ApprovalDetail;
