import React from 'react';
import {Text, Pressable} from 'react-native';
import { colorSwatches } from '../../../styles';

const MissingPermission = ({onPress, _hasMissingPermissions}) => {
  if (!_hasMissingPermissions) return null;
  
  return (
    <Pressable
    onPress={onPress}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: colorSwatches.red[400],
        paddingRight: 10,
        paddingVertical: 10,
      }}>
      <Text
        style={{
          color: colorSwatches.white.color,
          padding: 10,
          flex: 1,
          textAlign: 'center'
        }}>
        You have some missing permissions
      </Text>
    </Pressable>
  );
};

export default MissingPermission;
