import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Card, Menu } from 'react-native-paper';
import Text from '../../../components/Text';
import IconButton from '../../../components/IconButton';
import { Delivery } from '../../../@types';
import {
  deriveDisplayName,
  getCurrentUnitLink,
  permissionToBool,
} from '../../../utils';

export default function DeliveryItem({
  delivery,
  onSelectEdit,
  onSelectDelete,
  itemClickHandler,
}: {
  delivery: Delivery;
  itemClickHandler: () => void;
  onSelectDelete: () => void;
  onSelectEdit: () => void;
}) {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const startDate = new Date(
    delivery?.dateFrame?.startDate,
  ).toLocaleDateString();
  const endDate = new Date(delivery?.dateFrame?.endDate).toLocaleDateString();

  const currentUnitLink = getCurrentUnitLink();

  return (
    <Card style={styles.card} onPress={() => itemClickHandler()}>
      <View style={styles.container}>
        <View style={styles.infoContainer}>
          <View style={styles.row}>
            <Text style={styles.companyText}>
              {delivery.company ? delivery.company : 'Company not provided'}
            </Text>
          </View>
          <Text>{`Category - ${
            delivery.category ? delivery.category : 'Not provided'
          }`}</Text>
          <Text>{`By ${deriveDisplayName(delivery?.personAuthorizing)}`}</Text>
          <Text>
            {startDate} - {endDate}
          </Text>
        </View>
        {(permissionToBool(currentUnitLink?.deliveryPermission.charAt(1)) ||
          permissionToBool(currentUnitLink?.deliveryPermission.charAt(4))) && (
          <View style={styles.menuContainer}>
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <IconButton
                  icon="ellipsis-vertical"
                  size={24}
                  iconColor={'#718096'}
                  onPress={() => setMenuVisible(true)}
                />
              }>
              {permissionToBool(
                currentUnitLink?.deliveryPermission.charAt(1),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectEdit();
                  }}
                  title="Edit"
                />
              )}
              {permissionToBool(
                currentUnitLink?.deliveryPermission.charAt(4),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectDelete();
                  }}
                  title="Delete"
                />
              )}
            </Menu>
          </View>
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    marginVertical: 8,
  },
  container: {
    flexDirection: 'row',
    padding: 8,
    marginVertical: 4,
    justifyContent: 'space-between',
  },
  infoContainer: {
    flex: 1,
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  row: {
    flexDirection: 'row',
  },
  companyText: {
    fontSize: 18,
  },
  menuContainer: {
    marginTop: 12,
    marginRight: 16,
  },
});
