import React, {useEffect, useState} from 'react';
import ScreenWrapper from '../components/ScreenWrapper';
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import isWeb from '../../../utils/isWeb';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {Heading, HStack} from 'native-base';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {residentApiWithAuth} from '../../../api/resident';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {colorSwatches} from '../../../styles';
import {initiateSMS} from '../../App/service';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import {useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';

const confirmationValidationSchema = yup.object().shape({
  code: yup.string().required('Code is required'),
});

const ConfirmationCodesEntry = ({navigation, route}) => {
  const registerValues = useSelector((state: RootStore) => state.register);
  const {personId, errors} = route.params;
  const [code, setCode] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState({
    email: false,
    phone: false,
  });
  const [resendingEmailCode, setResendingEmailCode] = useState(false);
  const [resendingPhoneCode, setResendingPhoneCode] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const {width} = useWindowDimensions();
  const {control, handleSubmit} = useForm({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(confirmationValidationSchema),
  });
  const [open, setOpen] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState(errors);
  const [email, setEmail] = useState(registerValues.email);
  const [phone, setPhone] = useState(registerValues.phone1);
  const [updatingPhone, setUpdatingPhone] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false);
  const [updatingEmail, setUpdatingEmail] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog = () => setDialogOpen(false);

  useEffect(() => {
    if (phoneErrors?.length > 0) {
      setOpen(true);
    }
  }, [phoneErrors]);

  const updateUserDetails = async (data: {email?: string; phone1?: string}) => {
    try {
      let res = await residentApiWithAuth().post(`/people/${personId}`, data);
      return res.data.data;
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const confirmCode = async () => {
    setConfirming(true);
    const phoneVerification = residentApiWithAuth().post(
      `/people/confirmPhone/${personId}/${code}`,
    );

    const emailVerification = residentApiWithAuth().post(
      `/people/confirmEmail/${personId}/${code}`,
    );

    Promise.allSettled([phoneVerification, emailVerification])
      .then(result => {
        if (
          result[0].status === 'rejected' &&
          result[1].status === 'rejected'
        ) {
          showErrorMessage('Invalid code');
          return;
        } else {
          if (result[0].status === 'fulfilled') {
            setVerificationStatus({
              ...verificationStatus,
              phone: true,
            });
            showSuccessMessage('Phone verified sucessfully.');
          } else if (result[1].status === 'fulfilled') {
            setVerificationStatus({
              ...verificationStatus,
              email: true,
            });
            showSuccessMessage('Email verified sucessfully.');
          }
        }
      })
      .catch(e => {
        console.log('e', e);
      })
      .finally(() => {
        setCode('');
        setConfirming(false);

        if (verificationStatus.email && verificationStatus.phone) {
          handleNext();
        }
      });
  };

  const resendPhoneCode = async () => {
    setResendingPhoneCode(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/sendPhoneCode/${personId}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('The code has been sent to your cell phone');
      }
    } catch (error) {
      const errors = error.response.data.errors;
      setPhoneErrors(errors);
      showErrorMessage(error.message);
    } finally {
      setResendingPhoneCode(false);
    }
  };

  const resendEmailCode = async () => {
    setResendingEmailCode(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/sendEmailCode/${personId}`,
      );

      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('The code has been sent to your email');
      }
    } catch (error) {
      console.log('error', error.request);
      showErrorMessage(error.message);
    } finally {
      setResendingEmailCode(false);
    }
  };

  const onNext = () => {
    if (verificationStatus.email && verificationStatus.phone) {
      handleNext();
    } 
    else {
      setDialogOpen(true);
    }
  };

  const handleNext = () => {
    if(isWeb){  
      navigation.navigate('Success');
    }
    else{
     navigation.navigate('Add Contact');
    }
  }

  const onClose = () => setOpen(false);

  const onYesPressed = () => {};

  const onNoPressed = () => {
    // Send text message to [954-449-6239]
    initiateSMS('954-449-6239', 'START');
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          {
            flex: 1,
            marginVertical: 16,
          },

          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView
          style={{marginVertical: 8, marginBottom: 16}}
          keyboardShouldPersistTaps="handled">
          <RegisterActionBar
            step={7}
            hasSkip={true}
            onSkip={() => handleNext()}
          />
          <View style={{marginHorizontal: 24, marginTop: 16}}>
            <Heading size="lg">Confirm phone & email</Heading>
          </View>
          <View style={[codeEntryStyles.inputContainer, {marginTop: -4}]}>
            <TextInput
              //@ts-ignore
              control={control}
              name="phoneConfirmationCode"
              placeholder="e.g 1234X"
              label="Code  "
              value={code}
              onChangeText={text => setCode(text)}
            />
            <Button
              py={3}
              style={{marginLeft: 10}}
              isLoading={confirming}
              isLoadingText="Confirming ..."
              onPress={confirmCode}>
              Submit
            </Button>
          </View>
          <View>
            <View
              style={[
                codeEntryStyles.sectionContainer,
                {
                  marginTop: isWeb ? 0 : -16,
                  paddingTop: 10,
                  marginHorizontal: 16,
                },
              ]}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <View
                  style={{
                    backgroundColor: verificationStatus.email
                      ? colorSwatches.green.color
                      : colorSwatches.yellow.color,
                    marginLeft: 8,
                    borderRadius: 16,
                  }}>
                  <Text style={codeEntryStyles.statusText}>
                    {verificationStatus.email
                      ? 'Email verified'
                      : 'Email not verified'}
                  </Text>
                </View>
                {!verificationStatus.email && (
                  <Button variant="link" onPress={resendEmailCode}>
                    {resendingEmailCode ? 'Resending code ...' : 'Resend code'}
                  </Button>
                )}
              </View>
              <Text style={{fontSize: 12, marginHorizontal: 16}}>
                * If you did not receive an email verification check you Spam or
                Junk folder.
              </Text>
              <View
                style={[
                  codeEntryStyles.inputContainer,
                  {marginTop: -10, marginBottom: -20},
                ]}>
                <TextInput
                  //@ts-ignore
                  control={control}
                  name="email"
                  placeholder="e.g john@example.com"
                  label="Email  "
                  value={email}
                  onChangeText={text => setEmail(text)}
                  isDisabled={!editingEmail}
                  isReadOnly={!editingEmail}
                />
                <Button
                  // py={3}
                  style={{marginLeft: 10}}
                  isLoading={updatingEmail}
                  isLoadingText="Updating ..."
                  onPress={() => {
                    if (editingEmail) {
                      setUpdatingEmail(true);
                      updateUserDetails({email})
                        .then(res => {
                          if (!!res) {
                            showSuccessMessage('Email updated sucessfully');
                            resendEmailCode();
                          }
                        })
                        .finally(() => {
                          setUpdatingEmail(false);
                          setEditingEmail(false);
                        });
                    } else {
                      setEditingEmail(true);
                    }
                  }}>
                  {editingEmail ? 'Update' : 'Edit'}
                </Button>
              </View>
            </View>

            <View
              style={[
                codeEntryStyles.sectionContainer,
                {
                  paddingTop: 10,
                  marginHorizontal: 16,
                },
              ]}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  // marginTop: -10,
                }}>
                <View
                  style={{
                    backgroundColor: verificationStatus.phone
                      ? colorSwatches.green.color
                      : colorSwatches.yellow.color,
                    marginLeft: 8,
                    borderRadius: 16,
                  }}>
                  <Text style={codeEntryStyles.statusText}>
                    {verificationStatus.phone
                      ? 'Cell phone verified'
                      : 'Cell phone not verified'}
                  </Text>
                </View>
                {!verificationStatus.phone && (
                  <Button variant="link" onPress={resendPhoneCode}>
                    {resendingPhoneCode ? 'Resending code ...' : 'Resend code'}
                  </Button>
                )}
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <Text style={{marginHorizontal: 16}}>
                  * If you do not receive your verification code:
                </Text>
                <Button
                  variant="ghost"
                  onPress={() => {
                    initiateSMS('954-449-6239', 'START');
                  }}>
                  {`Text START to 954-449-6239          `}
                </Button>
                <View
                  style={[
                    codeEntryStyles.inputContainer,
                    {marginTop: -16, marginBottom: -20},
                  ]}>
                  <TextInput
                    //@ts-ignore
                    control={control}
                    name="phone"
                    placeholder="e.g (XXX) XXX-XXXX"
                    label="Phone  "
                    value={phone}
                    onChangeText={text => setPhone(text)}
                    isDisabled={!editingPhone}
                    isReadOnly={!editingPhone}
                  />
                  <Button
                    py={3}
                    style={{marginLeft: 10}}
                    isLoading={updatingPhone}
                    isLoadingText="Confirming ..."
                    onPress={() => {
                      if (editingPhone) {
                        setUpdatingPhone(true);
                        updateUserDetails({phone1: phone})
                          .then(res => {
                            if (!!res) {
                              showSuccessMessage('Phone updated sucessfully');
                              resendPhoneCode();
                            }
                          })
                          .finally(() => {
                            setUpdatingPhone(false);
                            setEditingPhone(false);
                          });
                      } else {
                        setEditingPhone(true);
                      }
                    }}>
                    {editingPhone ? 'Update' : 'Edit'}
                  </Button>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <HStack style={{margin: 24, marginTop: 32}}>
          <RegisterPrevButton />
          <Button onPress={onNext} style={{marginHorizontal: 8, flex: 1}}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      {phoneErrors?.length > 0 && (
        <ConfirmationDialog
          isOpen={open}
          onClose={onClose}
          onNoPressed={onNoPressed}
          onYesPressed={onYesPressed}
          negativeLabel="UNBLOCK "
          positiveLabel="CANCEL"
          description={
            phoneErrors[0] === 'Attempt to send to unsubscribed recipient'
              ? 'This phone number has block our SMS service.Press UNBLOCK to restart or click skip button, and you will not receive any SMS messages.'
              : 'Invalid phone number'
          }
        />
      )}
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        onNoPressed={closeDialog}
        onYesPressed={() => handleNext()}
        title="Skip verification ?"
        description="Are you sure you want to skip verification of phone and email ?"
        negativeLabel="NO "
        positiveLabel="YES "
      />
    </ScreenWrapper>
  );
};

const codeEntryStyles = StyleSheet.create({
  inputContainer: {
    marginTop: 8,
    flex: 1,
    marginHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusText: {
    fontSize: 16,
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
  sectionContainer: {
    marginBottom: 20,
    paddingBottom: 0,
    borderWidth: 0.5,
    borderColor: colorSwatches.gray.color,
    borderRadius: 4,
  },
});

export default ConfirmationCodesEntry;
