import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import DateTimeInput from '../../../components/DateTimeInput';
import {styles} from '../../../styles';
import Button from '../../../components/Button';
import HStack from '../../../components/HStack';
import {Modal} from 'native-base';
import {showErrorMessage} from '../../../service/flashMessage';

export default function FilterPanel({panelActive, setPanelActive, setDates}) {
  const [selectedStartDate, setSelectedStartDate] = useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = useState(undefined);

  const setFilters = () => {
    const startTime = selectedStartDate
      ? new Date(selectedStartDate).toISOString()
      : null;
    const endTime = selectedEndDate
      ? new Date(selectedEndDate).toISOString()
      : null;

    if (new Date(endTime).getTime() < new Date(startTime).getTime()) {
      showErrorMessage('End date must be greater than start time');
      return;
    }

    const dates = {
      startDate: startTime,
      endDate: endTime,
    };

    setDates(dates);
    setPanelActive(false);
  };

  const resetFilters = () => {
    setDates({startDate: null, endDate: null});
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setPanelActive(false);
  };

  const VisitorLogsFilterSection = () => {
    return (
      <View>
        <Text style={styles.panelTitle}>Filter visitor logs</Text>
        <DateTimeInput
          label="From"
          value={selectedStartDate ? new Date(selectedStartDate) : null}
          onConfirm={date => {
            setSelectedStartDate(date);
          }}
          description=""
          mode="date"
          maximumDate={new Date()}
        />
        <DateTimeInput
          label="To"
          value={selectedEndDate ? new Date(selectedEndDate) : null}
          onConfirm={date => {
            setSelectedEndDate(date);
          }}
          description=""
          mode="date"
          maximumDate={new Date()}
        />
        <HStack style={localStyles.buttonContainer}>
          <Button
            colorScheme="primary"
            mr={2}
            py={4}
            style={localStyles.button}
            onPress={() => setFilters()}>
            Apply
          </Button>
          <Button
            onPress={() => resetFilters()}
            colorScheme="primary"
            variant="outline"
            py={4}
            style={localStyles.button}>
            Reset
          </Button>
        </HStack>
      </View>
    );
  };

  return (
    <Modal isOpen={panelActive} onClose={() => setPanelActive(false)}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Filter</Modal.Header>
        <Modal.Body>
          <VisitorLogsFilterSection />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const localStyles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    marginTop: 24,
    flexDirection: 'row',
  },
  button: {
    flex: 1,
  },
});
