import React from 'react';
import {
  Alert,
  PermissionsAndroid,
  Platform,
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
  Linking,
  StyleSheet,
} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import Contacts from 'react-native-contacts';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';

const AddContact = ({navigation}) => {
  const {width} = useWindowDimensions();

  const saveContact = async () => {
    const newContact = {
      emailAddresses: [
        {
          label: 'home',
          email: 'info@communityemail.info',
        },
      ],
      phoneNumbers: [
        {
          label: 'mobile',
          number: '(954) 449-6239',
        },
      ],
      familyName: 'Office',
      givenName: 'Management',
    };

    const contacts = await Contacts.getAll();

    for (let index = 0; index < contacts.length; index++) {
      const element = contacts[index];
      if (
        element.givenName + element.familyName ===
        newContact.givenName + newContact.familyName
      ) {
        navigation.navigate('Success');
        return;
      }
    }

    const contact = await Contacts.addContact(newContact);
    if (contact) {
      showSuccessMessage('Contact added to phone book successfully');
      navigation.navigate('Success');
    }
  };

  const showPermissionDialog = () => {
    Alert.alert(
      'Alert',
      'Please grant Contact permission in settings or skip to continue.',
      [
        {
          text: 'SKIP',
          onPress: () => navigation.navigate('Success'),
        },
        {
          text: 'OK',
          onPress: () => {
            Linking.openSettings();
          },
        },
      ],
    );
  };

  const onNext = async () => {
    if (Platform.OS === 'android') {
      try {
        const writePermission = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.WRITE_CONTACTS,
          {
            title: 'Contacts',
            message: 'This app would like to view your contacts.',
            buttonPositive: 'Accept',
          },
        );
        const readPermission = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
        );
        if (writePermission === 'granted' && readPermission === 'granted') {
          await saveContact();
        } else if (
          writePermission === 'never_ask_again' ||
          readPermission === 'never_ask_again'
        ) {
          showPermissionDialog();
        }
      } catch (e) {
        showErrorMessage('Permission error' + e);
      }
    }
    if (Platform.OS === 'ios') {
      console.log("ios")
      try{
        const permission = await Contacts.requestPermission();
        console.log("permission", permission);
        if (permission === 'undefined') {
          const requestedPermission = await Contacts.requestPermission();
          if (requestedPermission === 'authorized') {
            await saveContact();
          }
          if (requestedPermission === 'denied') {
            showErrorMessage(
              'Contact permission denied, please enable in settings',
            );
            showPermissionDialog();
          }
        }
        if (permission === 'authorized') {
          await saveContact();
        }
        if (permission === 'denied') {
          showErrorMessage(
            'Contact permission denied, please enable in settings',
          );
          showPermissionDialog();
        }
      }
      catch(e){
        console.log("e", e)
        showErrorMessage('Permission error' + e);
      }
      finally{
        console.log("finally")
      }
     
    }
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeArea,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={3} />
        <ScrollView keyboardShouldPersistTaps="handled">
          <View style={styles.container}>
            <Heading size="lg">Add Contact</Heading>
            <Text style={[styles.text, {fontWeight: '600'}]}>
              Grant this permission so you do not get Unknown Caller from the
              office and block us.
            </Text>
            <Text style={styles.text}>Name: Management Office</Text>
            <Text style={styles.text}>Phone: 954-449-6239</Text>
            <Text style={styles.text}>Email: info@communityemail.info</Text>
            <Text style={styles.text}>
              This app keeps you informed about events and changes. You will
              always receive an app message and an email. In important
              situations, you may also get a text or call.
            </Text>
            <Text style={styles.text}>
              To ensure you receive these messages, we need to add a phone
              number and email to your contacts.
            </Text>
            <Text style={styles.text}>
              We do this so you will always know who is calling or emailing you.
              We will add this contact for you:
            </Text>
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <RegisterPrevButton />
          <Button onPress={onNext} style={styles.button}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    marginVertical: 16,
  },
  container: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  text: {
    marginTop: 16,
    fontSize: 18,
  },
  hStack: {
    margin: 24,
    marginTop: 32,
    marginBottom: 36
  },
  button: {
    flex: 1,
  },
});

export default AddContact;
