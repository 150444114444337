// @ts-nocheck
import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import Text from '../../../components/Text';
import Icon from 'react-native-vector-icons/Feather';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {emailUsername, resetPassword} from '../api';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import Input from '../../../components/Input';
import IconButton from '../../../components/IconButton';
import ScreenWrapper from '../components/ScreenWrapper';
import ScreenContent from '../components/ScreenContent';

const ForgotUsername = ({navigation}) => {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');

  async function sendUsernamae(email: string) {
    if (!email) {
      showErrorMessage('Email is required');
      return;
    }
    setSubmitting(true);
    try {
      const res = await emailUsername(email);
      showSuccessMessage('Username sent successfully');
      navigation.goBack();
    } catch (error) {
      showSuccessMessage('USERNAME SENT SUCCESSFULLY');
    }
    setSubmitting(false);
  }

  return (
    <ScreenWrapper>
      <ScreenContent>
        <IconButton
          icon={'close-outline'}
          size={30}
          style={styles.iconButton}
          onPress={() => navigation.goBack()}
        />
        <View style={styles.container}>
          <Heading size="lg">Forgot Username</Heading>
          <Text style={styles.text}>Type your email</Text>
          <Input
            style={styles.input}
            placeholder="e.g hello@xyz.com"
            onChangeText={text => setEmail(text)}
            value={email}
            autoCorrect={false}
            keyboardType="email-address"
            autoCapitalize="none"
            InputLeftElement={
              <TouchableOpacity style={styles.inputLeftElement}>
                <Icon name={'mail'} size={24} color="grey" />
              </TouchableOpacity>
            }
          />
          <Button
            onPress={() => sendUsernamae(email)}
            isLoadingText="Submitting ..."
            isLoading={submitting}
            style={styles.resetButton}
            py="4">
            Send Username
          </Button>
          <Button
            onPress={() => navigation.navigate('Sign In')}
            variant="link"
            isLoadingText="Submitting ..."
            style={styles.signInButton}>
            Already have an account? Sign in
          </Button>
        </View>
      </ScreenContent>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  iconButton: {
    marginTop: 16,
  },
  container: {
    marginHorizontal: 16,
    marginTop: 24,
    flex: 1,
    justifyContent: 'space-around',
  },
  text: {
    marginVertical: 32,
    fontSize: 18,
  },
  input: {
    flex: 1,
    marginLeft: 12,
    fontSize: 14,
  },
  inputLeftElement: {
    paddingHorizontal: 8,
  },
  resetButton: {
    marginTop: 48,
  },
  signInButton: {
    marginTop: 32,
  },
});

export default ForgotUsername;
