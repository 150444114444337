import React, {useCallback, useState} from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {Navbar} from '../../../components';
import {styles} from '../../../styles';
import {
  fetchDateFramesByUnit,
  updatePersonDateFrames,
} from '../../../api/dataframe';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {DateFrame} from '../../../@types/DateFrame';
import {useFocusEffect} from '@react-navigation/native';
import CreateDateFramePanel from '../../../components/CreateDateFramePanel';
import Select from '../../../components/Select';
import HStack from '../../../components/HStack';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

const CreatePersonDateFrame = ({navigation, route}) => {
  const {unitPeopleLinkId, unitId} = route.params;

  const [dateFrames, setDateFrames] = useState<Array<DateFrame>>([]);
  const [selectedDateFrame, setSelectedDateFrame] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [panelActive, setPanelActive] = useState(false);

  const fetchDateFrames = async () => {
    try {
      let data = await fetchDateFramesByUnit(1, unitId);
      setDateFrames(data);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchDateFrames();
    }, []),
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const peopleLinkDateFrame = {
      unitPeopleLinkId,
      description,
      dateFrameIds: [selectedDateFrame],
    };
    try {
      await updatePersonDateFrames(peopleLinkDateFrame);
      showSuccessMessage('Dateframe added successfully');
      navigation.navigate('People');
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  return (
    <View style={localStyles.container}>
      <Navbar navigation={navigation} title="Add date frame to person" />
      <ScrollView
        style={localStyles.scrollView}
        showsVerticalScrollIndicator={false}>
        <Text style={localStyles.dateFrameText}>Date frame</Text>
        <HStack>
          <Select
            placeholder="Select dateframe"
            minWidth={240}
            selectedValue={selectedDateFrame}
            onValueChange={val => setSelectedDateFrame(val)}>
            {dateFrames.map(item => (
              <Select.Item
                label={item.shortDescription}
                value={item.id}
                key={item.id}
              />
            ))}
          </Select>
          <Button onPress={() => setPanelActive(true)}>
            Create new and link
          </Button>
        </HStack>
        <Text style={styles.textInputLabel}>Description</Text>
        <Input
          placeholder="Enter short description"
          value={description}
          onChangeText={text => setDescription(text)}
        />
      </ScrollView>
      <Button mx="2" py="4" isDisabled={isSubmitting} onPress={handleSubmit}>
        Submit
      </Button>
      <CreateDateFramePanel
        editMode={false}
        initialState={null}
        unitId={unitId}
        unitPeopleLinkId={unitPeopleLinkId}
        navigation={navigation}
        panelActive={panelActive}
        setPanelActive={(state: boolean) => setPanelActive(state)}
        onSubmitSuccess={() => navigation.navigate('People')}
        isLoggedInUser={false}
      />
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 16,
  },
  scrollView: {
    paddingHorizontal: 8,
    paddingVertical: 16,
  },
  dateFrameText: {
    marginTop: 16,
    marginBottom: 8,
    flex: 1,
  },
});

export default CreatePersonDateFrame;
