import React from 'react';
import {ScrollView, View, useWindowDimensions, StyleSheet} from 'react-native';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {SafeAreaView} from 'react-native-safe-area-context';
import IconButton from '../../../components/IconButton';
import Text from '../../../components/Text';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';

const Success = ({navigation}) => {
  const {width} = useWindowDimensions();
  
  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeArea,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView style={styles.scrollView}>
          <IconButton
            icon={'close-outline'}
            size={30}
            style={styles.iconButton}
            onPress={() => navigation.goBack()}
          />
          <View style={styles.content}>
            <Heading>Success </Heading>
            <View style={styles.row}>
              <Ionicons
                name="checkmark-circle-outline"
                color="#47B275"
                size={24}
                style={styles.icon}
              />
              <Text style={styles.text}>
                Congratulations, your account has been set up successfully.
              </Text>
            </View>
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <Button
            onPress={() => navigation.navigate('Sign In')}
            style={styles.button}>
            Sign In
          </Button>
        </HStack>
      </SafeAreaView>
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    marginBottom: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  iconButton: {},
  content: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  row: {
    flexDirection: 'row',
    marginTop: 32,
  },
  icon: {
    marginTop: 4,
  },
  text: {
    marginBottom: 16,
    marginLeft: 8,
    fontSize: 18,
  },
  hStack: {
    margin: 24,
    marginTop: 32,
    marginBottom: 36
  },
  button: {
    flex: 1,
    paddingVertical: 8,
  },
});

export default Success;
