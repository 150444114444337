import React, {useCallback, useState} from 'react';
import {ScrollView, Image, View, useWindowDimensions, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import {IMAGE_OPTIONS} from '../../../constants';
import {styles as globalStyles} from '../../../styles';
import {RegisterActionBar, RegisterPrevButton} from '../../../components';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useDispatch} from 'react-redux';
import {setRegisterData} from '../registerReducer';
import {residentApi} from '../../../api/resident';
import FeatherIcons from 'react-native-vector-icons/Feather';
import {IDAnalyzer, RegistrationData} from '../types';
import {showErrorMessage} from '../../../service/flashMessage';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import WebcamCapture from '../components/WebcamCapture';
import { handlePermissionError } from '../../../utils';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const DlCapture = ({navigation}) => {
  const [loading, setLoading] = useState(false);
  const [driversLicenseImage, setDriversLicenseImage] = useState(null);
  const dispatch = useDispatch();

  const {width} = useWindowDimensions();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeDialog = () => setDialogOpen(false);

  const decodeDl = async (dlImage: string) => {
    setLoading(true);
    try {
      const res = await residentApi().post('/idAnalyzer', {
        imageBase64: dlImage,
      });
      const result = res.data;

      if (result.errors) {
        throw new Error(result?.errors[0]);
      } else {
        const dlData: IDAnalyzer = result.data;
        if (dlData) {
          const registrationData: RegistrationData = {
            firstName: dlData?.firstName,
            lastName: dlData?.lastName,
            driversLicenseImage: driversLicenseImage,
            profilePicture: dlData?.croppedface,
            addressLine1: dlData?.address1,
            addressLine2: dlData?.address2,
            dlNumber: dlData?.documentNumber,
            dateOfBirth: dlData?.dob,
            expiration: dlData?.expiry,
            gender: dlData?.sex,
          };
          // @ts-ignore
          dispatch(setRegisterData(registrationData));
          navigation.navigate('Name');
        } else {
          showErrorMessage(
            "Couldn't extract data from the license. Please try again.",
          );
        }
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const showGalleryPicker = () => {
    if (isWeb) {
      //@ts-ignore
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/png, image/jpeg';

      input.onchange = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = event => {
          const data = event.target.result;
          //@ts-ignore
          setDriversLicenseImage(data.split(',')[1]);
        };
      };

      input.click();
    } else {
      const ImagePicker = require('react-native-image-crop-picker').default;
      ImagePicker.openPicker(IMAGE_OPTIONS).then(image => {
        //@ts-ignore
        setDriversLicenseImage(image.data);
      }).catch((e) => handlePermissionError(e, 'gallery'));
    }
  };

  const showCameraPicker = () => {
    const ImagePicker = require('react-native-image-crop-picker').default;
    ImagePicker.openCamera(IMAGE_OPTIONS).then(image => {
      //@ts-ignore
      setDriversLicenseImage(image.data);
    }).catch((e) => handlePermissionError(e, 'camera'));
  };

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.flex1,
          styles.margin4,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <RegisterActionBar step={5} />
        <ScrollView style={styles.marginX2}>
          <View style={styles.marginX6}>
            <Heading size="lg">Driver's License Photo</Heading>
            <Text style={[styles.marginTop8, styles.textLg]}>
              Please load or take a picture of the front face of your Drivers
              License. From your drivers licence information we will save:
            </Text>
            <HStack style={styles.marginLeft4}>
              <FeatherIcons
                style={styles.marginTop3}
                name="check-square"
                size={15}
                color="green"
              />
              <Text style={styles.marginTop2}>Your name.</Text>
            </HStack>
            <HStack style={styles.marginLeft4}>
              <FeatherIcons
                style={styles.marginTop3}
                name="lock"
                size={15}
                color="green"
              />
              <Text style={styles.marginTop2}>
                Your drivers licence number (Encrypted).
              </Text>
            </HStack>
            <HStack style={styles.marginLeft4}>
              <FeatherIcons
                style={styles.marginTop3}
                name="calendar"
                size={15}
                color="green"
              />
              <Text style={styles.marginTop2}>
                Month and day of your birthday (no year, we just want to say
                happy birthday to you).
              </Text>
            </HStack>
            <HStack style={styles.marginLeft4}>
              <FeatherIcons
                style={styles.marginTop3}
                name="check-square"
                size={15}
                color="green"
              />
              <Text style={styles.marginTop2}>
                Drivers license expiration (encrypted, so we know when it needs
                to be renewed).
              </Text>
            </HStack>
            <Text
              style={{
                marginTop: 8,
                fontSize: 20,
                fontWeight: 'bold',
              }}>
              This profile will not be approved by management if the driver's
              license is not provided.
            </Text>
            <View style={styles.marginTop8}>
              {driversLicenseImage ? (
                <View>
                  <View style={styles.licenseUploadContainer}>
                    {driversLicenseImage !== null && (
                      <Image
                        source={{
                          uri: `data:image/jpeg;base64,${driversLicenseImage}`,
                        }}
                        style={styles.roundedImage}
                        resizeMode="contain"
                      />
                    )}
                  </View>
                </View>
              ) : (
                <View style={styles.licenseUploadContainer} />
              )}
            </View>
          </View>
        </ScrollView>
        <HStack style={styles.margin2}>
          {isWeb ? (
            <View style={{flex: 1}}>
              <WebcamCapture
                onCapture={imgData => setDriversLicenseImage(imgData)}
              />
            </View>
          ) : (
            <Button
              style={[styles.flex1, styles.marginTop6]}
              variant="ghost"
              size="lg"
              py="4"
              onPress={() => showCameraPicker()}>
              Take picture
            </Button>
          )}
          <Button
            style={[styles.flex1, styles.marginTop4]}
            variant="ghost"
            size="lg"
            py="4"
            onPress={() => showGalleryPicker()}>
            {isWeb ? 'Choose file' : 'Choose from gallery'}
          </Button>
        </HStack>
        <HStack 
        style={styles.margin2}
        >
          <RegisterPrevButton />
          <Button
            isLoading={loading}
            py="4"
            isLoadingText="Extracting data from DL. Please wait ..."
            onPress={() =>
              !driversLicenseImage
                ? setDialogOpen(true)
                : decodeDl(driversLicenseImage)
            }
            style={[styles.flex1]}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      <ConfirmationDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        onNoPressed={closeDialog}
        onYesPressed={() => navigation.navigate('Name')}
        title="Are you sure you wish to skip this step?"
        description="This profile will not be approved by management if this information is not provided."
      />
    </ScreenWrapper>
  );
};
const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
  margin4: {
    margin: 16,
  },
  marginX2: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  marginX6: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  marginTop8: {
    marginTop: 32,
  },
  textLg: {
    fontSize: 18,
  },
  marginLeft4: {
    marginLeft: 16,
    marginTop: 8,
  },
  marginTop3: {
    marginTop: 12,
    marginRight: 8,
  },
  marginTop2: {
    marginTop: 8,
    fontSize: 18,
  },
  licenseUploadContainer: {
    height: 256,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
  },
  roundedImage: {
    borderRadius: 8,
    height: 256,
    width: '99%',
  },
  margin2: {
    margin: 8,
  },
  marginTop6: {
    marginTop: 24,
  },
  marginTop4: {
    marginTop: 16,
  },
});

export default DlCapture;
