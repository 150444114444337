// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';
import {useEffect} from 'react';
import {showErrorMessage} from '../../../service/flashMessage';
import {Vehicle} from '../types';
import {fetchVehicleById} from '../../../api/vehicles';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';

const VehicleDetail = ({navigation, route}) => {
  const {vehicleId} = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [vehicle, setVehicle] = useState<Vehicle>({});

  const fetchVehicle = async (id: string) => {
    try {
      const res: Vehicle = await fetchVehicleById(id);
      setVehicle(res);
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchVehicle(vehicleId);
  }, [vehicleId]);

  return (
    <Container>
      <Navbar navigation={navigation} title="Vehicle details" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Card style={localStyles.card}>
            <View style={localStyles.cardHeader}>
              <ZoomableImage
                title={vehicle.licensePlate}
                source={{
                  uri: vehicle.vehicleImage
                    ? `data:image/jpeg;base64,${vehicle.vehicleImage}`
                    : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
                }}
                style={styles.image}
              />
              <View style={localStyles.cardHeaderText}>
                <Text style={styles.userName}>{vehicle.vehicleOwner}</Text>
                <Text style={styles.email}>{vehicle.licensePlate}</Text>
              </View>
            </View>
          </Card>
          <ScrollView style={localStyles.scrollView} showsVerticalScrollIndicator={false}>
            <Card style={localStyles.detailsCard}>
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>License Plate state</Text>
                <Text style={styles.detailsText}>{vehicle.licenseState}</Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Make</Text>
                <Text style={styles.detailsText}>{vehicle.makeOfVehicle}</Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Model</Text>
                <Text style={styles.detailsText}>{vehicle.modelOfVehicle}</Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Year</Text>
                <Text style={styles.detailsText}>{vehicle.yearOfVehicle}</Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Color</Text>
                <Text style={styles.detailsText}>{vehicle.colorOfVehicle}</Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Vehicle Security Pass</Text>
                <Text style={styles.detailsText}>{vehicle.vehicleSecurityPass}</Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Dateframe StartDate</Text>
                <Text style={styles.detailsText}>
                  {new Date(vehicle.dateFrame.startDate).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Dateframe EndDate</Text>
                <Text style={styles.detailsText}>
                  {new Date(vehicle.dateFrame.endDate).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Dateframe Description</Text>
                <Text style={styles.detailsText}>
                  {vehicle.dateFrame.description}
                </Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Valet Vehicle No</Text>
                <Text style={styles.detailsText}>{vehicle.valetVehicleNo}</Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Valet information</Text>
                <Text style={styles.detailsText}>
                  {vehicle.valetInformation}
                </Text>
              </View>
              <Divider />
              <View style={localStyles.detailRow}>
                <Text style={styles.textLabel}>Valet status</Text>
                <Text style={styles.detailsText}>{vehicle.valetStatus}</Text>
              </View>
            </Card>
          </ScrollView>
        </>
      )}
    </Container>
  );
};

const localStyles = StyleSheet.create({
  card: {
    marginHorizontal: 8,
    marginTop: 16,
    paddingBottom: 8,
  },
  cardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
  },
  cardHeaderText: {
    flex: 1,
    marginLeft: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    paddingVertical: 16,
  },
  detailsCard: {
    padding: 8,
    marginBottom: 32,
  },
  detailRow: {
    flexDirection: 'row',
    marginVertical: 12,
  },
});

export default VehicleDetail;
