import {Toast} from 'native-base';
import {colorSwatches} from '../styles';
import { StyleSheet, Dimensions } from 'react-native';

const deviceWidth = Dimensions.get('window').width;

export const showErrorMessage = (description: string) => {
  Toast.show({
    title: `${description}`,
    variant: 'solid',
    style: flashStyles.flashMessage,
    backgroundColor: colorSwatches.red[400],
    fontSize: 14,
    placement: 'bottom',
    avoidKeyboard: true,
  });
};

export const showSuccessMessage = (description: string) => {
  Toast.show({
    title: description,
    backgroundColor: '#3AA76D',
    avoidKeyboard: true,
    style: flashStyles.flashMessage,
    placement: 'bottom',
  });
};

export const showInformationMessage = (description: string) => {
  Toast.show({
    title: description,
    avoidKeyboard: true,
    style: flashStyles.flashMessage
  });
};

export const showMessageWithCopy = (description: string) => {
  Toast.show({
    title: description,
    avoidKeyboard: true,
    style: flashStyles.flashMessage
  });
};

export const showPermissionMessage = () => {
  Toast.show({
    title: 'No permission to perform this operation',
    avoidKeyboard: true,
    style: flashStyles.flashMessage
  });
};

const flashStyles = StyleSheet.create({
  flashMessage: {
    position: 'absolute',
    bottom: 60,
    // left: '50%',
    transform: [{ translateX: -deviceWidth / 3 }],
  },
})
