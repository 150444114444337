import {FormControl, IInputProps, Input, Keyboard} from 'native-base';
import React, {useState} from 'react';
import {Control, Controller} from 'react-hook-form';
import {View} from 'react-native';
import Text from './Text';
import formatPhoneNumber from '../utils/phoneNumberFormatter';

interface I_TextInput_Props {
  control: Control;
  name: string;
  label: string;
  placeholder?: string;
}

export default function TextInput(props: I_TextInput_Props & IInputProps) {
  const {control, name, label, placeholder = '', ...otherProps} = props;

  return (
    <View style={{marginVertical: 0, flex: 1}}>
      <Controller
        control={control}
        render={({
          field: {onChange, onBlur, value},
          fieldState: {isTouched, invalid, error, isDirty},
        }) => {
          const [] = useState();
          return (
            <FormControl isInvalid={isTouched && invalid}>
              <FormControl.Label fontStyle={400}>{label}</FormControl.Label>
              <Input
                placeholder={placeholder}
                style={{marginTop: 8, fontSize: 18}}
                onChangeText={text =>
                  name == 'phone1'
                    ? onChange(formatPhoneNumber(text))
                    : onChange(text)
                }
                onBlur={onBlur}
                onFocus={() => console.log('focus')}
                value={name == 'phone1' ? formatPhoneNumber(value) : value}
                {...otherProps}
              />
              <Text style={{marginVertical: 8, color: 'red'}}>
                {error?.message}
              </Text>
            </FormControl>
          );
        }}
        name={name}
      />
    </View>
  );
}
