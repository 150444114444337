// @ts-nocheck
import React, {useEffect} from 'react';
import {
  EmptyList,
  LoadingSpinner,
  MySearchbar,
  NavbarWithAction,
} from '../../../components';
import {FlatList} from 'react-native';
import {
  E_NAVIGATION_DESTINATIONS,
  VisitorLogsQueryParams,
} from '../../../@types';
import {useNavigation} from '@react-navigation/native';
import VisitorLogsItem from './VisitorLogsItem';
import {usePaginatedVisitorLogsByUnit} from '../../../hooks/logs';
import FilterPanel from './FilterPanel';
import Container from '../../../components/Container';

export default function VisitorLogs() {
  const navigation = useNavigation();

  const initialQueryParams: VisitorLogsQueryParams = {
    pageNumber: 1,
    pageSize: 15,
    dateFrom: null,
    dateTo: null,
    thumbWidth: 46,
    thumbHeight: 46,
    searchKey: null,
  };
  const [queryParams, setQueryParams] =
    React.useState<VisitorLogsQueryParams>(initialQueryParams);
  const {
    isLoading,
    data,
    refetch,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = usePaginatedVisitorLogsByUnit(queryParams);

  const [isNavbarShown, setIsNavbarShown] = React.useState(true);
  const [swipeablePanelActive, setSwipeablePanelActive] = React.useState(false);

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    refetch();
  }, [queryParams]);

  return (
    <>
      <Container>
        <>
          {isNavbarShown ? (
            <NavbarWithAction
              navigation={navigation}
              hasBackArrow
              hasSearch={!isLoading && !isFetchingNextPage}
              title="Visitor Logs"
              showCurrentUnit
              onClickSearch={() => setIsNavbarShown(false)}
              hasFilter
              onClickFilter={() => setSwipeablePanelActive(true)}
              fullScreen
            />
          ) : (
            <MySearchbar
              placeholder="Search by name"
              setValue={(searchKey: string) => {
                setQueryParams({
                  ...queryParams,
                  searchKey,
                });
              }}
              resetSearch={() => {
                setIsNavbarShown(true);
              }}
            />
          )}
        </>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <FlatList
            contentContainerStyle={{paddingHorizontal: 16}}
            data={data?.pages.map(pageItem => pageItem.data).flat()}
            ListEmptyComponent={<EmptyList message="No logs at the moment" />}
            renderItem={({item}) => (
              <VisitorLogsItem
                visitorLog={item}
                itemClickHandler={() =>
                  navigation.navigate(
                    E_NAVIGATION_DESTINATIONS.VISITOR_LOG_DETAILS,
                    {
                      visitorLog: item,
                    },
                  )
                }
              />
            )}
            onRefresh={refetch}
            refreshing={isRefetching}
            onEndReached={loadMore}
            onEndReachedThreshold={0.3}
            ListFooterComponent={isFetchingNextPage ? <LoadingSpinner /> : null}
          />
        )}
      </Container>
      <FilterPanel
        panelActive={swipeablePanelActive}
        setPanelActive={(newState: boolean) =>
          setSwipeablePanelActive(newState)
        }
        setDates={(filters: {startDate: string; endDate: string}) =>
          setQueryParams({
            ...queryParams,
            ...{dateFrom: filters.startDate, dateTo: filters.endDate},
          })
        }
      />
    </>
  );
}
