// @ts-nocheck
import Button from './Button';
import HStack from './HStack';
import Select from './Select';
import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {fetchLinksByUnit} from '../api/links';
import {UnitLink} from '../@types/UnitLink';
import {deriveDisplayName} from '../utils';
import {useDispatch} from 'react-redux';
import {FilterApprovals} from '../redux/reducers/approvalsReducer';
import Text from '../components/Text';
import DateTimeInput from './DateTimeInput';

const ApprovalFilterSection = ({units, closePanel}) => {
  const [selectedUnit, setSelectedUnit] = useState('');
  const [approversShown, setApproversShown] = useState(false);
  const [approvers, setApprovers] = useState<Array<{name: string; id: string}>>(
    [],
  );
  const [selectedApprover, setSelectedApprover] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const yesterday = new Date().setDate(new Date().getDate() - 1);
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date(yesterday),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  // const [showPending, setShowPending] = useState(false);
  const dispatch = useDispatch();

  const setFilters = () => {
    dispatch(
      FilterApprovals({
        unit: selectedUnit,
        type: selectedType,
        approver: selectedApprover,
        requestedFrom: selectedStartDate,
        requestedTo: selectedEndDate,
      }),
    );
    closePanel();
  };

  const resetFilters = () => {
    closePanel();
    //dispatch approvals reset filter action
  };

  const fetchApproversInUnit = async (unitId: string) => {
    try {
      let unitLinks: UnitLink[] = await fetchLinksByUnit(unitId);

      let peopleInUnit = unitLinks.filter(
        unitLink => unitLink.allowedtoApprove,
      );
      peopleInUnit = peopleInUnit.map(item => {
        return {
          name: deriveDisplayName(item),
          id: item.id,
        };
      });

      setApprovers(peopleInUnit);
    } catch (error) {}
  };

  return (
    <View>
      <View style={filterStyles.selectContainer}>
        <Text style={{marginTop:16}}>Unit</Text>
        <Select
          maxWidth={350}
          minWidth={200}
          selectedValue={selectedUnit}
          onValueChange={value => {
            setSelectedUnit(value);
            if (value !== '') {
              setApproversShown(true);
              fetchApproversInUnit(value);
            }
          }}>
          <Select.Item label="*Select unit *" value="" />
          {units.map(unit => (
            <Select.Item label={unit.name} value={unit.id} key={unit.id} />
          ))}
        </Select>
      </View>
      {approversShown && (
        <View style={filterStyles.selectContainer}>
          <Text style={{marginTop:16}}>Approver</Text>
          <Select
            maxWidth={350}
            minWidth={200}
            selectedValue={selectedApprover}
            onValueChange={value => {
              setSelectedApprover(value);
            }}>
            <Select.Item label="*Select approver *" value="" />
            {approvers.map(approver => (
              <Select.Item
                label={approver.name}
                value={approver.id}
                key={approver.id}
              />
            ))}
          </Select>
        </View>
      )}
      <View style={filterStyles.selectContainer}>
        <Text style={{marginTop:16}}>Type</Text>
        <Select
          maxWidth={350}
          minWidth={200}
          selectedValue={selectedType}
          onValueChange={value => setSelectedType(value)}>
          <Select.Item label="*Select type *" value="" />
          <Select.Item label="UnitLink DateFrames" value="UnitLinkDateFrames" />
          <Select.Item label="Unit People Link" value="UnitPeopleLink" />
        </Select>
      </View>
      <DateTimeInput
        label="Requested from"
        value={selectedStartDate}
        onConfirm={date => {
          setSelectedStartDate(date);
        }}
        mode='date'
      />
      <DateTimeInput
        label="Requested to"
        value={selectedEndDate}
        onConfirm={date => {
          setSelectedEndDate(date);
        }}
        mode='date'
      />
  
      <HStack style={{flex:1, marginTop:24}}>
        <Button
          colorScheme="primary"
          mr={2}
          py="4"
          style={{flex: 1}}
          onPress={() => setFilters()}>
          Apply
        </Button>
        <Button
          onPress={() => resetFilters()}
          colorScheme="primary"
          py="4"
          variant="outline"
          style={{flex: 1}}>
          Reset
        </Button>
      </HStack>
    </View>
  );
};

const filterStyles = StyleSheet.create({
  selectContainer: {
    margin: 8,
    marginBottom: 0,
    justifyContent: 'space-between',
  },
});

export default ApprovalFilterSection;
