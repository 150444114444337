// @ts-nocheck
import React from 'react';
import {SafeAreaView, ScrollView, View, StyleSheet} from 'react-native';
import AsterixSwitch from '../../../components/AsterixSwitch';
import Text from '../../../components/Text';
import {styles} from '../../../styles';
import ZoomableImage from '../../../components/ZoomableImage';

const UnitDetails = ({navigation, route}) => {
  const {unit} = route.params;
  const imageUrl = unit.profilePicture
    ? `data:image/jpeg;base64,${unit.profilePicture}`
    : 'https://dummyimage.com/110x88/cccccc/cccccc.png';

  return (
    <SafeAreaView style={customStyles.container}>
      <ScrollView style={customStyles.scrollView}>
        <View style={customStyles.innerContainer}>
          <ZoomableImage
            title={unit.unit}
            style={styles.detailsImage}
            source={{uri: imageUrl}}
          />
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Unit :</Text>
            <Text style={customStyles.flex1}>{unit.unit}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Unit Display:</Text>
            <Text style={customStyles.flex1}>{unit.unitDisplay}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Status :</Text>
            <Text style={customStyles.flex1}>{unit.status}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Floor :</Text>
            <Text style={customStyles.flex1}>{unit.floor}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Line :</Text>
            <Text style={customStyles.flex1}>{unit.line}</Text>
          </View>
          <View style={customStyles.switchRow}>
            <Text style={styles.textLabel}>Community Delinquent:</Text>
            <AsterixSwitch
              style={customStyles.switch}
              value={unit.communityDelinquent}
            />
          </View>
          <View style={customStyles.switchRow}>
            <Text style={styles.textLabel}>Building Delinquent:</Text>
            <AsterixSwitch
              style={customStyles.switch}
              value={unit.buildingDelinquent}
            />
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Alternate unit :</Text>
            <Text style={customStyles.flex1}>{unit.alternateUnit}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Account Number :</Text>
            <Text style={customStyles.flex1}>{unit.accountNumber}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Address Line 1 :</Text>
            <Text style={customStyles.flex1}>{unit.addressLine1}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Address Line 2 :</Text>
            <Text style={customStyles.flex1}>{unit.addressLine2}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>City :</Text>
            <Text style={customStyles.flex1}>{unit.city}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>State :</Text>
            <Text style={customStyles.flex1}>{unit.state}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Zip :</Text>
            <Text style={customStyles.flex1}>{unit.zip}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Key In Keybox Named :</Text>
            <Text style={customStyles.flex1}>{unit.keyInKeyboxNamed}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Key Tag :</Text>
            <Text style={customStyles.flex1}>{unit.keyTag}</Text>
          </View>
          <View style={customStyles.row}>
            <Text style={styles.textLabel}>Number Of Keys On Ring :</Text>
            <Text style={customStyles.flex1}>{unit.numberOfKeysOnRing}</Text>
          </View>
          <Text style={[styles.textLabel, customStyles.marginTop]}>
            Notes:
          </Text>
          <Text style={customStyles.marginBottom}>{unit.notes}</Text>
          <Text style={[styles.textLabel, customStyles.marginTop]}>
            Directions:
          </Text>
          <Text style={customStyles.marginBottom}>{unit.directions}</Text>
          <Text style={[styles.textLabel, customStyles.marginTop]}>
            Call restriction start time:
          </Text>
          <Text style={customStyles.marginBottom}>
            {unit.startBlockAutomatedCalls}
          </Text>
          <Text style={[styles.textLabel, customStyles.marginTop]}>
            Call restriction end time:
          </Text>
          <Text style={customStyles.marginBottom}>
            {unit.endBlockautomatedCalls}
          </Text>
          <Text style={[styles.textLabel, customStyles.marginTop]}>
            Special Instructions
          </Text>
          <Text style={customStyles.marginBottom}>
            {unit.specialInstructions}
          </Text>
          <Text style={[styles.textLabel, customStyles.marginTop]}>
            Unit code
          </Text>
          <Text style={customStyles.marginBottom}>
            {unit.linkCode ? unit.linkCode : 'No Unit code'}
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const customStyles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 16,
    paddingBottom: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  innerContainer: {
    marginTop: 8,
    marginHorizontal: 16,
  },
  row: {
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 16,
  },
  switchRow: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 8,
  },
  switch: {
    marginBottom: 16,
    alignSelf: 'flex-end',
  },
  flex1: {
    flex: 1,
  },
  marginTop: {
    marginTop: 16,
  },
  marginBottom: {
    marginBottom: 8,
  },
});

export default UnitDetails;
