import React from 'react';
import {FlatList, View, StyleSheet} from 'react-native';
import {Navbar} from '../../../components';
import ApprovalItem from '../../Alerts/components/ApprovalItem';

export default function ApprovalsHistory({navigation, route}) {
  const {personName, isLoggedInUser, approvals, type, recordName} =
    route?.params;
  const extraTitlePart = `${
    type === 'history' ? 'approvals history' : 'pending approvals'
  }`;
  const TITLE = `${
    isLoggedInUser ? 'Your' : `${personName}'s`
  } ${extraTitlePart} for ${recordName}`;

  return (
    <View style={styles.container}>
      <Navbar navigation={navigation} title={TITLE} />
      <FlatList
        style={styles.list}
        data={approvals}
        renderItem={({item}) => (
          <ApprovalItem
            approval={item}
            approvalClickHandler={() =>
              navigation.navigate('Approval Detail', {
                id: item.id,
                unitId: item.unitId,
              })
            }
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
    paddingTop: 8,
  },
});
