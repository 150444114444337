var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = _interopRequireDefault(require("react"));
var _reactNative = require("react-native");
var _reactRedux = require("react-redux");
var _store = require("./src/redux/store");
var _react2 = require("redux-persist/integration/react");
var _components = require("./src/components");
var _Application = _interopRequireWildcard(require("./Application"));
var _pushySdkWeb = _interopRequireDefault(require("pushy-sdk-web"));
var _alertsReducer = require("./src/modules/Alerts/alertsReducer");
var _approvalsReducer = require("./src/redux/reducers/approvalsReducer");
var _unitsReducer = require("./src/modules/Units/unitsReducer");
var _pushy = require("./src/api/pushy");
var _buildAlertObject = _interopRequireDefault(require("./src/utils/buildAlertObject"));
var _notistack = require("notistack");
var _xDatePickers = require("@mui/x-date-pickers");
var _AdapterDayjs = require("@mui/x-date-pickers/AdapterDayjs");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var App = function App() {
  _pushySdkWeb.default.setNotificationListener(function () {
    var _ref = (0, _asyncToGenerator2.default)(function* (data) {
      console.log('Received notification: ' + data);
      var alert = (0, _buildAlertObject.default)(data);
      _store.store.dispatch((0, _alertsReducer.ReceiveAlert)(data.Id, data.ParentAlertId, data.EventName, data.Action));
      _store.store.dispatch((0, _approvalsReducer.GetApprovals)());
      _store.store.dispatch((0, _unitsReducer.GetUnitScreenData)());
      _store.store.dispatch((0, _approvalsReducer.DeleteAllApprovals)());
      _store.store.dispatch((0, _approvalsReducer.GetApprovals)());
      _Application.queryClient.invalidateQueries();
      (0, _notistack.enqueueSnackbar)(alert.data.message, {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        preventDuplicate: true
      });
    });
    return function (_x) {
      return _ref.apply(this, arguments);
    };
  }());
  return (0, _jsxRuntime.jsx)(_reactNative.View, {
    style: {
      flex: 1,
      alignSelf: 'stretch'
    },
    children: (0, _jsxRuntime.jsx)(_xDatePickers.LocalizationProvider, {
      dateAdapter: _AdapterDayjs.AdapterDayjs,
      children: (0, _jsxRuntime.jsx)(_reactRedux.Provider, {
        store: _store.store,
        children: (0, _jsxRuntime.jsx)(_react2.PersistGate, {
          loading: (0, _jsxRuntime.jsx)(_components.LoadingSpinner, {}),
          persistor: _store.persistor,
          children: (0, _jsxRuntime.jsx)(_Application.default, {})
        })
      })
    })
  });
};
var _default = exports.default = App;