// @ts-nocheck
import React, {useState} from 'react';
import {View, StyleSheet} from 'react-native';
import {Approval} from '../../../@types/Approval';
import {Card, TouchableRipple} from 'react-native-paper';
import {ApprovalSection, LoadingSpinner} from '../../../components';
import {useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {residentApiWithAuth} from '../../../api/resident';
import Text from '../../../components/Text';

export default function ApprovalItem({
  approval,
  approvalClickHandler,
  refreshList,
}: {
  approval: Approval;
  approvalClickHandler: Function;
}) {
  const [_approval, _setApproval] = useState(approval);
  const unitLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  )?.filter(link => link.unit.id === _approval?.unit?.unitId)[0];
  const [requiresResidentApproval, setRequiresResidentApproval] = useState(
    unitLink?.allowedtoApprove &&
      _approval.residentApprovalStatus === 'Pending',
  );
  const [loading, setLoading] = useState(false);
  const {user} = useSelector((state: RootStore) => state.auth);

  async function handleApprove(id: string, approve: boolean) {
    setLoading(true);
    try {
      const res = await residentApiWithAuth().post('/approvals', {
        id,
        approve,
      });
      const result = res.data;
      console.log('results', result);
      if (result.errors) {
        showErrorMessage(result.errors[0]);
      } else {
        !!refreshList && refreshList();
        _setApproval({
          ...approval,
          residentApprovalStatus: approve ? 'Approved' : 'Denied',
          residentApprovalDateTime: new Date().toISOString(),
          residentApprovalName: `${user.firstName} ${user.lastName}`,
        });
        setRequiresResidentApproval(false);
        showSuccessMessage('Your response has been sent');
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card onPress={() => approvalClickHandler()} style={styles.card}>
      <View style={styles.container}>
        <Text>{`Unit ${_approval.unit.unitName}`}</Text>
        <View style={styles.row}>
          <View style={styles.row}>
            <Text style={styles.textXs}>
              {`${_approval?.type?.toUpperCase()} - ${
                _approval?.message !== null ? _approval.description : ''
              }`}
            </Text>
          </View>
        </View>
        {_approval?.message !== null && <Text>{_approval.message}</Text>}
        <Text style={styles.mt1}>{`Requested by ${_approval.approvalRequesterName}`}</Text>
        <Text style={styles.mt1}>{`${
          _approval.approvalRequesteeName
            ? `Requestee ${_approval.approvalRequesteeName}`
            : 'No requestee'
        }`}</Text>
        <ApprovalSection approval={_approval} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {requiresResidentApproval && (
              <View style={styles.buttonContainer}>
                <TouchableRipple onPress={() => handleApprove(_approval.id, true)}>
                  <View style={[styles.button, styles.approveButton]}>
                    <Text style={styles.buttonText}>Approve</Text>
                  </View>
                </TouchableRipple>
                <TouchableRipple onPress={() => handleApprove(_approval.id, false)}>
                  <View style={[styles.button, styles.notApproveButton]}>
                    <Text style={styles.buttonText}>Not Approve</Text>
                  </View>
                </TouchableRipple>
              </View>
            )}
          </>
        )}
        <Text numberOfLines={1} style={styles.dateText}>
          {new Date(_approval.approvalRequestDateTime).toLocaleString()}
        </Text>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    margin: 8,
  },
  container: {
    flexDirection: 'column',
    padding: 8,
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textXs: {
    fontSize: 12,
  },
  mt1: {
    marginTop: 4,
    fontSize: 14,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 8,
  },
  button: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderRadius: 4,
  },
  approveButton: {
    backgroundColor: 'green',
  },
  notApproveButton: {
    backgroundColor: 'red',
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    marginRight: 8,
  },
  dateText: {
    alignSelf: 'flex-end',
    marginTop: 4,
    fontSize: 10,
  },
});
