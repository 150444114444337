/**
 * Global constants
 * TODO: Move to related module
 */

import {Dimensions} from 'react-native';

export const VISITOR_SCREEN_TIMEOUT_DURATION = 15 * 60 * 1000; //15 mins

export const IMAGE_OPTIONS = {
  cropping: false,
  includeBase64: true,
  quality: 0.8,
  mediaType: 'photo',
  // compressImageQuality: 0.99,
  width: 600,
  height: 800,
  maxWidth: 1200,
  maxHeight: 1600,
  includeExif: true,
};

export const NOTIFICATION_STATUS = {
  READ: 'Read',
};

export const SYSTEM_FOREVER_DATEFRAME_ID =
  '11111111-1111-1111-1111-111111111111';

let windowHeight: number = Dimensions.get('window').height;
let windowWidth: number = Dimensions.get('window').width;

export const isSmallWindow = windowWidth <= 340;
