// @ts-nocheck
import React, {useState} from 'react';
import {View, FlatList, LogBox, Alert, StyleSheet} from 'react-native';
import {ActivityIndicator, Menu, Card} from 'react-native-paper';
import Center from '../../../components/Center';
import Text from '../../../components/Text';
import {useSelector} from 'react-redux';
import {styles as globalStyles} from '../../../styles';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {MySearchbar, NavbarWithAction} from '../../../components';
import {useFocusEffect} from '@react-navigation/native';
import {fetchParkingByUnit, deleteParkingById} from '../../../api/parking';
import {RootStore} from '../../../redux/store';
import {permissionToBool} from '../../../utils';
import IconButton from '../../../components/IconButton';
import Container from '../../../components/Container';

const ParkingList = ({navigation}) => {
  const [parkings, setParkings] = useState([]);
  const [parkingsHolder, setParkingsHolder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentUnitLink = useSelector(
    (state: RootStore) => state?.units?.unitLinks,
  ).filter(link => link.isDefault === true)[0];

  const [searchTerm, setSearchTerm] = useState('');
  const [isNavbarShown, setIsNavbarShown] = useState(true);

  const fetchParkings = async unit => {
    setIsLoading(true);
    try {
      const response = await fetchParkingByUnit(unit);
      setParkings(response);
      setParkingsHolder(response);
    } catch (error) {
      showErrorMessage(error.toString());
    }
    setIsLoading(false);
  };

  const ignoreListsWarnings = () => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  };

  useFocusEffect(
    React.useCallback(() => {
      ignoreListsWarnings();
      if (currentUnitLink) {
        fetchParkings(currentUnitLink.unit.id);
      }
    }, [currentUnitLink]),
  );

  const showDeleteUnitPrompt = parkingId => {
    Alert.alert(
      '',
      'Do you really want to delete parking ?',
      [
        {
          text: 'NO',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: 'YES',
          onPress: () => deleteParking(parkingId),
        },
      ],
      {
        cancelable: true,
      },
    );
  };

  const deleteParking = async parkingId => {
    try {
      await deleteParkingById(parkingId);
      showSuccessMessage('Parking deleted successfully');
    } catch (error) {
      showErrorMessage(error.message);
    }
    await fetchParkingByUnit(currentUnitLink.unit.id);
  };

  const searchHandler = searchString => {
    setSearchTerm(searchString);
    const newParkings = parkingsHolder.filter(pet => {
      const parkingData = `${pet.name.toUpperCase()}`;
      const searchTermData = searchString.toUpperCase();
      return parkingData.indexOf(searchTermData) > -1;
    });
    setParkings(newParkings);
  };

  return (
    <Container>
      <>
        {isNavbarShown ? (
          <NavbarWithAction
            navigation={navigation}
            hasBackArrow
            title="Parkings"
            showCurrentUnit
            hasSearch={parkings.length > 0}
            onClickSearch={() => setIsNavbarShown(false)}
            hasAdd={permissionToBool(
              currentUnitLink?.parkingPermission?.charAt(3),
            )}
            onClickAdd={() =>
              navigation.navigate('Add Parking', {parking: null})
            }
            fullScreen
          />
        ) : (
          <MySearchbar
            placeholder="Search  name"
            value={searchTerm}
            setValue={val => searchHandler(val)}
            resetSearch={() => {
              setIsNavbarShown(true);
              setSearchTerm('');
              setParkings(parkingsHolder);
            }}
          />
        )}
      </>
      <MainComponent
        isLoading={isLoading}
        parkings={parkings}
        navigation={navigation}
        showDeleteUnitPrompt={parkingId => showDeleteUnitPrompt(parkingId)}
        currentUnitLink={currentUnitLink}
      />
    </Container>
  );
};

const MainComponent = ({
  isLoading,
  parkings,
  navigation,
  showDeleteUnitPrompt,
  currentUnitLink,
}) => {
  return (
    <>
      {isLoading ? (
        <ActivityIndicator
          animating={true}
          color={styles.primaryColor.color}
          style={styles.activityIndicator}
        />
      ) : (
        <>
          <FlatList
            data={parkings}
            style={styles.flatList}
            ListFooterComponent={<View style={styles.footerComponent} />}
            ListEmptyComponent={<Center>No parkings found</Center>}
            renderItem={({item}) => (
              <ParkingListItem
                parking={item}
                onSelectDelete={() => showDeleteUnitPrompt(item.id)}
                itemClickHandler={() =>
                  navigation.navigate('Parking Detail', {parkingId: item.id})
                }
                onSelectEdit={() =>
                  navigation.navigate('Add Parking', {parking: item})
                }
                currentUnitLink={currentUnitLink}
              />
            )}
          />
        </>
      )}
    </>
  );
};

const ParkingListItem = ({
  parking,
  onSelectEdit,
  onSelectDelete,
  itemClickHandler,
  currentUnitLink,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <Card style={styles.card} onPress={() => itemClickHandler()}>
      <View style={styles.cardContent}>
        <View style={styles.cardTextContainer}>
          <View style={styles.cardTextRow}>
            <Text style={styles.cardTitle}>
              {parking.name ? parking.name : 'Name not provided'}
            </Text>
          </View>
          <Text style={styles.cardSubtitle}>
            {parking.parkingSpace
              ? parking.parkingSpace
              : 'Parking space not provided'}
          </Text>
          <View style={styles.cardDateContainer}>
            <View style={styles.cardDateRow}>
              <Text>
                {new Date(parking.dateFrame?.startDate).toLocaleDateString()} -{' '}
                {new Date(parking.dateFrame?.endDate).toLocaleDateString()}
              </Text>
            </View>
          </View>
        </View>
        {(permissionToBool(currentUnitLink?.parkingPermission.charAt(1)) ||
          permissionToBool(currentUnitLink?.parkingPermission.charAt(4))) && (
          <View style={styles.menuContainer}>
            <Menu
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <IconButton
                  icon="ellipsis-vertical"
                  iconColor={'#718096'}
                  size={24}
                  onPress={() => setMenuVisible(true)}
                />
              }>
              {permissionToBool(
                currentUnitLink?.parkingPermission.charAt(1),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectEdit();
                  }}
                  title="Edit"
                />
              )}
              {permissionToBool(
                currentUnitLink?.storagePermission.charAt(4),
              ) && (
                <Menu.Item
                  onPress={() => {
                    setMenuVisible(false);
                    onSelectDelete();
                  }}
                  title="Delete"
                />
              )}
            </Menu>
          </View>
        )}
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  flatList: {
    marginVertical: 12,
    marginHorizontal: 8,
    flex: 1,
  },
  footerComponent: {
    height: 32,
  },
  card: {
    borderRadius: 8,
    marginVertical: 8,
  },
  cardContent: {
    flexDirection: 'row',
    padding: 8,
    marginVertical: 4,
    justifyContent: 'space-between',
  },
  cardTextContainer: {
    flex: 1,
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  cardTextRow: {
    flexDirection: 'row',
  },
  cardTitle: {
    fontSize: 18,
  },
  cardSubtitle: {
    fontSize: 14,
  },
  cardDateContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  },
  cardDateRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuContainer: {
    marginTop: 12,
    marginRight: 16,
  },
});

export default ParkingList;
