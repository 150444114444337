import React from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {Card, Divider} from 'react-native-paper';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';

const DeliveryDetail = ({navigation, route}) => {
  const {delivery} = route.params;

  return (
    <View style={localStyles.container}>
      <Navbar navigation={navigation} title="Delivery details" />
      <ScrollView
        style={localStyles.scrollView}
        showsVerticalScrollIndicator={false}>
        <Card style={localStyles.card}>
          <View style={localStyles.flexRow}>
            <View style={localStyles.row}>
              <Text style={styles.textLabel}>Dateframe start date</Text>
              <Text style={styles.detailsText}>
                {new Date(delivery?.dateFrame?.startDate).toLocaleString()}
              </Text>
            </View>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Dateframe end date</Text>
            <Text style={styles.detailsText}>
              {new Date(delivery?.dateFrame?.endDate).toLocaleString()}
            </Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Dateframe description</Text>
            <Text style={styles.detailsText}>
              {delivery?.dateFrame?.description}
            </Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Company</Text>
            <Text style={styles.detailsText}>{delivery.company}</Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Required</Text>
            <Text style={styles.detailsText}>{delivery.required}</Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Expected time</Text>
            <Text style={styles.detailsText}>{delivery.expectedTime}</Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Authorization to</Text>
            <Text style={styles.detailsText}>{delivery.authorizationTo}</Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Person authorizing</Text>
            <Text style={styles.detailsText}>{delivery.authorizationTo}</Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Created at</Text>
            <Text style={styles.detailsText}>
              {new Date(delivery.createdTimeDate).toLocaleDateString()}
            </Text>
          </View>
          <Divider />
          <View style={localStyles.row}>
            <Text style={styles.textLabel}>Instructions</Text>
            <Text style={styles.detailsText}>{delivery.instructions}</Text>
          </View>
        </Card>
      </ScrollView>
    </View>
  );
};

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    marginHorizontal: 8,
    paddingVertical: 16,
  },
  card: {
    padding: 8,
    marginBottom: 32,
  },
  flexRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    marginVertical: 12,
  },
});

export default DeliveryDetail;
