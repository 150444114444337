// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View, StyleSheet} from 'react-native';
import {useDispatch} from 'react-redux';
import Ionicon from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {User} from '../@types';
import {residentApiWithAuth} from '../api/resident';
import {showErrorMessage, showSuccessMessage} from '../service/flashMessage';
import Button from './Button';
import Input from './Input';
import Modal from './Modal';
import {VerifyCode} from '../modules/Auth/reducer';
import {colorSwatches} from '../styles';
import Text from '../components/Text';
import {pasteFromClipboard} from '../service/clipboard';
import ConfirmationDialog from './ConfirmationDialog';
import { initiateSMS } from '../modules/App/service';

export default function VerifyEmailAndPhone({
  person,
}: {
  person: User;
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationType, setVerificationType] = useState<
    'Phone' | 'Email' | ''
  >('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState(null);

  useEffect(() => {
    if(phoneErrors?.length > 0){
      setModalVisible(false);
      setOpen(true)
    }
  }, [phoneErrors]);

  const sendCode = async (user: User, codeType: 'Phone' | 'Email' | '') => {
    try {
      const res = await residentApiWithAuth().post(
        `/people/send${codeType}code/${user.id}`,
      );
      const result = res.data;
      if (result.errors) {
        showErrorMessage(result.errors[0]);
      } else {
        showSuccessMessage(
          `${codeType} verification code sent to ${
            codeType === 'Email' ? user.email : user.phone1
          }`,
        );
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (
        codeType === 'Phone' &&
        errors[0] === 'Attempt to send to unsubscribed recipient'
      ) {
        setPhoneErrors(errors);
      }
      else {
        showErrorMessage("Invalid phone number")
      }
    }
    setModalVisible(true);
  };

  const fetchCopiedText = async () => {
    const text = await pasteFromClipboard();
    setVerificationCode(text);
  };

  const onClose = () => {
    setOpen(false);
    setVerificationType('');
    setVerificationCode('');
  };

  const onYesPressed = () => {
  };

  const onNoPressed = () => {
    // Send text message to [954-449-6239]
    initiateSMS('954-449-6239', 'START');
  };

  return (
    <>
      <View style={styles.flexRow}>
        <TouchableOpacity
          disabled={person?.emailConfirmed}
          onPress={() => {
            setVerificationType('Email');
            setModalVisible(true);
          }}
          style={[
            styles.button,
            {
              backgroundColor: person?.emailConfirmed
                ? colorSwatches.green.color
                : colorSwatches.yellow.color,
            },
          ]}>
          <Ionicon
            name={`${
              person?.emailConfirmed ? 'checkmark-outline' : 'warning-outline'
            }`}
            size={18}
            style={styles.icon}
          />
          <Text style={styles.text}>
            {person?.emailConfirmed ? 'Email verified' : 'Verify email'}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          disabled={person?.cellPhoneConfirmed}
          onPress={() => {
            setVerificationType('Phone');
            setModalVisible(true);
          }}
          style={[
            styles.button,
            {
              backgroundColor: person?.cellPhoneConfirmed
                ? colorSwatches.green.color
                : colorSwatches.yellow.color,
            },
          ]}>
          <Ionicon
            name={`${
              person?.cellPhoneConfirmed
                ? 'checkmark-outline'
                : 'warning-outline'
            }`}
            size={18}
            style={styles.icon}
          />
          <Text style={styles.text}>
            {person?.cellPhoneConfirmed
              ? 'Cell Phone verified'
              : 'Verify cell phone'}
          </Text>
        </TouchableOpacity>
      </View>
      <Modal isOpen={modalVisible} onClose={() => {
        setModalVisible(false);
        setVerificationCode('');
        setVerificationType('');
      }}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Enter verification Code</Modal.Header>
          <Modal.Body _scrollview={{keyboardShouldPersistTaps: "handled"}}>
            <Input
              mt={4}
              mb={4}
              value={verificationCode}
              onChangeText={text => setVerificationCode(text)}
              InputLeftElement={
                <Button
                  variant="ghost"
                  startIcon={
                    <MaterialCommunityIcons name="content-paste" size={24} />
                  }
                  onPress={fetchCopiedText}
                />
              }
              InputRightElement={
                <Button
                  isLoading={loading}
                  isLoadingText="Continue"
                  onPress={() => {
                    setLoading(true);

                    dispatch(
                      VerifyCode(verificationType, verificationCode, person.id),
                    )
                      .then(result => {})
                      .catch(err => {})
                      .finally(() => {
                        setVerificationCode('');
                        setLoading(false);
                        setModalVisible(!modalVisible);
                      });
                  }}>
                  Continue
                </Button>
              }
            />
            <Button
              onPress={() => sendCode(person, verificationType)}>
              Resend Code
            </Button>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      {phoneErrors?.length > 0 && (
        <ConfirmationDialog
          isOpen={open}
          onClose={onClose}
          onNoPressed={onNoPressed}
          onYesPressed={onYesPressed}
          negativeLabel="UNBLOCK "
          positiveLabel="CANCEL"
          description={
            phoneErrors[0] === 'Attempt to send to unsubscribed recipient'
              ? 'This phone number has block our SMS service.Press UNBLOCK to restart or click skip button, and you will not receive any SMS messages.'
              : 'Invalid phone number'
          }
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  button: {
    margin: 8,
    padding: 4,
    flexDirection: 'row',
    borderRadius: 4,
  },
  icon: {
    marginTop: 4,
  },
  text: {
    margin: 4,
    textAlign: 'center',
  },
});
