import React, {useState} from 'react';
import {View, Linking, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {Menu, Card} from 'react-native-paper';
import {styles} from '../../../styles';
import {deriveDisplayName, getCurrentUnitLink} from '../../../utils';
import {
  showErrorMessage,
  showInformationMessage,
} from '../../../service/flashMessage';
import {ApprovalStatus, RequireApproval} from '../../../components';
import IconButton from '../../../components/IconButton';
import {initiateSMS, initiateSendEmail} from '../../App/service';
import isWeb from '../../../utils/isWeb';
import ZoomableImage from '../../../components/ZoomableImage';

const PeopleListItem = ({
  unitPeopleLink,
  itemClickHandler,
  currentUser,
  openCalendar,
  onClickApprovalsRequired,
  openPermissions,
  onSelectDelete,
  onSelectEditOwnUnitLink,
  sendBarCode,
  onSelectEdit,
  onAddToContacts,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const imageUrl = unitPeopleLink?.profilePicture
    ? `data:image/jpeg;base64,${unitPeopleLink?.profilePicture}`
    : 'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png';
  const phoneNumber = unitPeopleLink?.person.phone
    ? unitPeopleLink?.person.phone
    : unitPeopleLink.phone;
  const phoneAvailable = !!phoneNumber;
  const email = unitPeopleLink?.person.email
    ? unitPeopleLink?.person.email
    : unitPeopleLink.email;
  const emailAvailable = !!email;
  const isCurrentUser = currentUser === unitPeopleLink?.person?.id;
  const currentUnitLink = getCurrentUnitLink();

  const canEditOwnUnitPeopleLink = isCurrentUser;
  const canModifyUnitPeopleLink = isCurrentUser
    ? unitPeopleLink?.peopleType?.residentCanAdd
    : currentUnitLink?.canManagePeople;
  const linkApproved = unitPeopleLink.unitLinkApproved;

  return (
    <Card
      style={[
        localStyles.card,
        !linkApproved && {backgroundColor: 'gray'},
      ]}
      onPress={() =>
        unitPeopleLink?.person?.id
          ? itemClickHandler()
          : showInformationMessage("This person doesn't have a profile")
      }>
      <View style={localStyles.container}>
        <ZoomableImage
          title={deriveDisplayName(unitPeopleLink)}
          style={styles.userImage}
          source={{
            uri: imageUrl,
          }}
        />
        <View style={localStyles.infoContainer}>
          <View style={localStyles.nameContainer}>
            <Text style={localStyles.nameText}>
              {deriveDisplayName(unitPeopleLink)}
            </Text>
            {isCurrentUser && (
              <View style={styles.currentUser}>
                <Text style={localStyles.currentUserText} numberOfLines={1}>
                  You
                </Text>
              </View>
            )}
          </View>
          {deriveDisplayName(unitPeopleLink?.person) !== ' ' && (
            <Text style={localStyles.profileNameText}>
              {`Profile name-  ${deriveDisplayName(unitPeopleLink?.person)}`}
            </Text>
          )}
          <Text style={localStyles.peopleTypeText}>
            {unitPeopleLink?.peopleType?.name?.toUpperCase()}
          </Text>
          <ApprovalStatus unitPeopleLink={unitPeopleLink} />
          <Text style={localStyles.linkedProfileText}>
            {unitPeopleLink?.person?.id
              ? 'Linked profile'
              : 'No linked profile'}
          </Text>
          <View style={localStyles.dateFrameContainer}>
            <View
              style={[
                localStyles.dateFrame,
                {
                  backgroundColor: unitPeopleLink.dateFrameDay.allowedToday
                    ? 'green'
                    : 'red',
                },
              ]}>
              <Text style={localStyles.dateFrameText} numberOfLines={1}>
                Today
              </Text>
            </View>
            <View
              style={[
                localStyles.dateFrame,
                {
                  backgroundColor: unitPeopleLink.dateFrameDay.allowedTomorrow
                    ? 'green'
                    : 'red',
                },
              ]}>
              <Text style={localStyles.dateFrameText} numberOfLines={1}>
                Tomorrow
              </Text>
            </View>
          </View>
        </View>
        <View style={localStyles.menuContainer}>
          <Menu
            visible={menuVisible}
            onDismiss={() => setMenuVisible(false)}
            anchor={
              <IconButton
                icon="ellipsis-vertical"
                iconColor={'#718096'}
                size={24}
                onPress={() => setMenuVisible(true)}
              />
            }>
            <>
              <Menu.Item
                onPress={() => {
                  if (!phoneAvailable) {
                    showErrorMessage('Failed to call, phone number N/A');
                    setMenuVisible(false);
                    return;
                  }
                  setMenuVisible(false);
                  Linking.openURL(`tel:${unitPeopleLink?.person?.phone}`);
                }}
                title={'Call'}
              />
              {!isWeb && (
                <Menu.Item
                  onPress={() => {
                    if (!phoneAvailable) {
                      showErrorMessage('Failed to send text, phone number N/A');
                      setMenuVisible(false);
                      return;
                    }
                    setMenuVisible(false);
                    initiateSMS(unitPeopleLink?.person?.phone);
                  }}
                  title={'Send Text'}
                />
              )}
            </>
            <Menu.Item
              onPress={() => {
                if (!emailAvailable) {
                  showErrorMessage('Failed to send email, email address N/A');
                  setMenuVisible(false);
                  return;
                }
                setMenuVisible(false);
                initiateSendEmail(unitPeopleLink?.person?.email, '', '');
              }}
              title={'Send Email'}
            />
            {canModifyUnitPeopleLink && (
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  onSelectEdit();
                }}
                title="Edit"
              />
            )}
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                openCalendar();
              }}
              title="Calendar"
            />
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                openPermissions();
              }}
              title="Permissions"
            />
            <Menu.Item
              onPress={() => {
                if (!emailAvailable && !phoneAvailable) {
                  showErrorMessage("Can't send code. No phone number/email.");
                  setMenuVisible(false);
                  return;
                } else if (!currentUnitLink.allowedToAuthorizeEntry) {
                  showErrorMessage("You don't have permission to send code.");
                  setMenuVisible(false);
                  return;
                }
                setMenuVisible(false);
                sendBarCode();
              }}
              title="Send QR Code"
            />
            <Menu.Item
              onPress={() => {
                setMenuVisible(false);
                onAddToContacts();
              }}
              title="Add to contacts"
            />
            {canModifyUnitPeopleLink && (
              <Menu.Item
                onPress={() => {
                  setMenuVisible(false);
                  setTimeout(() => {
                    onSelectDelete();
                  }, 1000);
                }}
                title="Remove from unit"
              />
            )}
          </Menu>
        </View>
      </View>
      <RequireApproval
        approvals={unitPeopleLink.approvals}
        onClickApprovalsRequired={() => onClickApprovalsRequired()}
      />
    </Card>
  );
};

const localStyles = StyleSheet.create({
  card: {
    margin: 8,
  },
  container: {
    flexDirection: 'row',
    padding: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoContainer: {
    flex: 1,
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  nameContainer: {
    flexDirection: 'row',
  },
  nameText: {
    fontSize: 18,
    flex: 1,
  },
  currentUserText: {
    fontSize: 12,
    paddingHorizontal: 8,
    marginHorizontal: 4,
  },
  profileNameText: {
    flex: 1,
  },
  peopleTypeText: {
    fontSize: 12,
    marginVertical: 4,
  },
  linkedProfileText: {
    fontSize: 12,
    marginVertical: 4,
  },
  dateFrameContainer: {
    flexDirection: 'row',
    marginVertical: 4,
  },
  dateFrame: {
    borderRadius: 9999,
    marginHorizontal: 4,
  },
  dateFrameText: {
    fontSize: 12,
    color: 'white',
    paddingHorizontal: 4,
    marginRight: 4,
  },
  menuContainer: {
    marginTop: 12,
    marginRight: 16,
  },
});

export default PeopleListItem;
