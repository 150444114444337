// @ts-nocheck
import React, {useState} from 'react';
import {Pressable, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {Card} from 'react-native-paper';
import {styles as globalStyles} from '../../../styles';
import {
  deriveDisplayName,
  deriveUnitName,
} from '../../../utils/deriveDisplayName';
import {useSelector} from 'react-redux';
import {
  showInformationMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {permissionToBool, toLocalDateTime} from '../../../utils';
import {RootStore} from '../../../redux/store';
import {UnitLink} from '../../../@types/UnitLink';
import {EventNames} from '../../../@types/EventNames';
import {useQueryClient} from 'react-query';
import Menu from '../../../components/Menu';
import Actionsheet from '../../../components/Actionsheet';
import useDisclose from '../../../components/useDisclose';
import Box from '../../../components/Box';
import {ToggleAutomatedCalls} from '../unitsReducer';
import {useAppDispatch} from '../../../hooks/redux';
import {
  ApprovalStatus,
  LoadingSpinner,
  RequireApproval,
} from '../../../components';
import Ionicons from 'react-native-vector-icons/Ionicons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ZoomableImage from '../../../components/ZoomableImage';
import {E_CONFIRM_MODES} from '../types';
import {buildTodayAlertArgs, buildTommorowAlertArgs} from '../utils';

type SelectDay = {
  alertArguments: string;
  eventName: EventNames;
  personId: string;
  image: string;
  unitId: string;
  startDate: string;
  endDate: string;
  description: string;
};

interface I_UnitListItemProps {
  unitPeopleLink: UnitLink;
  onOpenCalendar: () => void;
  onSelectRemoveSelf: () => void;
  onSelectUnitCode: () => void;
  selectUnitHandler: () => void;
  onSelectEdit: () => void;
  onSelectDay: (args: SelectDay) => void;
  onSelectCustom: () => void;
  onClickApprovalsRequired: () => void;
  onOpenPermissions: () => void;
  onSelectEditUnitLink: () => void;
  onSelectEditUnit: () => void;
}

const UnitListItem = (props: I_UnitListItemProps) => {
  const {
    unitPeopleLink,
    onOpenCalendar,
    onSelectRemoveSelf,
    selectUnitHandler,
    onSelectDay,
    onSelectCustom,
    onClickApprovalsRequired,
    onOpenPermissions,
    onSelectEditUnit,
  } = props;
  const imageUrl = `data:image/jpeg;base64,${unitPeopleLink.unit.unitPicture}`;

  const person = useSelector((allState: RootStore) => allState.auth.user);
  const personId = person.id;
  const personName = deriveDisplayName(person);
  const profilePicture = unitPeopleLink.person.profilePicture;
  const unitName = unitPeopleLink.unit.unitName;

  const {todayArguments, todayEndDate, todayStartDate, todayDescription} =
    buildTodayAlertArgs(personName, unitName);

  const {
    tommorowArguments,
    tommorowStartDate,
    tommorowEndDate,
    tommorowDescription,
  } = buildTommorowAlertArgs(personName, unitName);

  const [confirmDialogShown, setConfirmDialogShown] = useState(false);
  const [confirmMode, setConfirmMode] = useState<E_CONFIRM_MODES>(null);

  const {isOpen, onOpen, onClose} = useDisclose();

  const message = unitPeopleLink.unit.allowAutomatedCalls
    ? 'disable'
    : 'enable';
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const [automatedCallsOpen, setAutomatedCallsOpen] = useState(false);
  const onAutomatedCallsClose = () => {
    setAutomatedCallsOpen(false);
  };
  const onAutomatedCallsConfirm = () => {
    setLoading(true);
    dispatch(
      ToggleAutomatedCalls(
        unitPeopleLink.unit.id,
        !unitPeopleLink.unit.allowAutomatedCalls,
      ),
    )
      .then(() =>
        showSuccessMessage(
          `Automated calls has been ${
            !unitPeopleLink.unit.allowAutomatedCalls ? 'enabled' : 'disabled'
          }`,
        ),
      )
      .finally(() => {
        setLoading(false);
        if (
          !unitPeopleLink.unit.allowAutomatedCalls &&
          permissionToBool(unitPeopleLink?.unitPermission?.charAt(1))
        ) {
          setEditDialogOpen(true);
        }
      });
  };

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const onEditDialogClose = () => {
    setEditDialogOpen(false);
  };
  const onEditDialogConfirm = () => {
    onSelectEditUnit();
  };

  const onNoPressed = () => {
    console.log('Cancel Pressed');
  };

  const isApproved =
    unitPeopleLink.unitLinkApproved && unitPeopleLink.peopleLinkApproved;
  const queryClient = useQueryClient();

  const handleApproved = () => {
    queryClient.invalidateQueries();
    selectUnitHandler();
  };

  const onConfirmRequestAccess = () => {
    if (confirmMode === E_CONFIRM_MODES.TODAY) {
      if (unitPeopleLink.dateFrameDay.allowedToday) {
        showInformationMessage('You already have access for today');
      } else {
        onSelectDay({
          alertArguments: todayArguments,
          eventName: EventNames.REQUEST_ACCESS,
          personId,
          image: profilePicture,
          unitId: unitPeopleLink.unit.id,
          startDate: todayStartDate,
          endDate: todayEndDate,
          description: todayDescription,
        });
      }
    } else if (confirmMode === E_CONFIRM_MODES.TOMMOROW) {
      if (unitPeopleLink.dateFrameDay.allowedTomorrow) {
        showInformationMessage('You already have access for tommorow');
      } else {
        onSelectDay({
          alertArguments: tommorowArguments,
          eventName: EventNames.REQUEST_ACCESS,
          personId,
          image: profilePicture,
          unitId: unitPeopleLink.unit.id,
          startDate: tommorowStartDate,
          endDate: tommorowEndDate,
          description: tommorowDescription,
        });
      }
    }
  };

  return (
    <Card
      style={[
        styles.card,
        isApproved ? null : styles.bgGray,
        styles.rounded,
        styles.margin,
      ]}
      onPress={() =>
        isApproved
          ? handleApproved()
          : showInformationMessage(
              'Sorry, you are not approved to access this unit',
            )
      }>
      <View
        style={[
          styles.rounded,
          unitPeopleLink.isDefault ? styles.borderGreen : null,
        ]}>
        <View style={[styles.flexRow, styles.justifyBetween, styles.itemsCenter, styles.flex1]}>
          <ZoomableImage
            title={deriveUnitName(unitPeopleLink)}
            style={styles.unitsCardImage}
            source={
              unitPeopleLink.unit.unitPicture
                ? {uri: imageUrl}
                : require('../../../images/unit.jpeg')
            }
            message="This is your people picture - go to people find your name, edit and upload image"
          />
          <View style={[styles.flex1, styles.marginLeft, styles.flexCol, styles.justifyAround]}>
            {unitPeopleLink.isDefault && (
              <Text
                numberOfLines={1}
                style={[styles.textGreen, styles.textXs, styles.flex1, styles.marginTop]}>
                DEFAULT
              </Text>
            )}
            <Text
              numberOfLines={1}
              style={[styles.textXs, styles.roundedFull, styles.marginTop]}>
              {unitPeopleLink.peopleType.id
                ? unitPeopleLink?.peopleType?.name.toUpperCase()
                : 'UNKNOWN'}
            </Text>
            <Text style={[styles.marginTop, styles.textLg]}>
              {deriveUnitName(unitPeopleLink)}
            </Text>
            <Text>{`Status - ${unitPeopleLink.unit.status}`}</Text>
            <Text numberOfLines={1}>
              {unitPeopleLink.unit.building
                ? unitPeopleLink.unit.building.name
                : ''}
            </Text>
            <Text numberOfLines={1}>
              {unitPeopleLink.unit.community
                ? unitPeopleLink.unit.community.name
                : ''}
            </Text>
            <Text>
              {unitPeopleLink.unit.addressLine1
                ? unitPeopleLink.unit.addressLine1
                : unitPeopleLink.unit.addressLine2}
            </Text>
            <Text>{unitPeopleLink.unit.state}</Text>
            <Text>{unitPeopleLink.unit.zip}</Text>
            {!isApproved && <ApprovalStatus unitPeopleLink={unitPeopleLink} />}
            <View style={[styles.flexRow, styles.marginY]}>
              <View
                style={[
                  styles.roundedFull,
                  unitPeopleLink.dateFrameDay.allowedToday ? styles.bgGreen : styles.bgRed,
                ]}>
                <Text
                  style={[styles.textXs, styles.textWhite, styles.marginX, styles.marginRight, styles.marginTop]}
                  numberOfLines={1}>
                  Today
                </Text>
              </View>
              <View
                style={[
                  styles.roundedFull,
                  styles.marginX,
                  styles.marginRight,
                  unitPeopleLink.dateFrameDay.allowedTomorrow ? styles.bgGreen : styles.bgRed,
                ]}>
                <Text
                  style={[styles.textXs, styles.textWhite, styles.paddingX, styles.marginRight, styles.padding]}
                  numberOfLines={1}>
                  Tommorow
                </Text>
              </View>
            </View>
            <RequireApproval
              approvals={unitPeopleLink.approvals}
              onClickApprovalsRequired={() => onClickApprovalsRequired()}
            />
          </View>
          {isApproved && (
            <Menu
              placement="right top"
              trigger={triggerProps => {
                return (
                  <Pressable
                    {...triggerProps}
                    style={[
                      isApproved ? styles.paddingTop : styles.paddingTop16,
                      styles.paddingRight,
                      styles.paddingLeft,
                    ]}>
                    <Ionicons name="ellipsis-vertical" size={22} />
                  </Pressable>
                );
              }}>
              {unitPeopleLink.allowedToRequestAccess && (
                <Menu.Item onPress={() => onOpen()}>Request Access</Menu.Item>
              )}
              <Menu.Item onPress={() => onOpenCalendar()}>Calendar</Menu.Item>
              {permissionToBool(unitPeopleLink?.unitPermission?.charAt(1)) && (
                <Menu.Item onPress={() => onSelectEditUnit()}>
                  Edit Unit
                </Menu.Item>
              )}
              <Menu.Item onPress={() => onOpenPermissions()}>
                Permissions
              </Menu.Item>
              {unitPeopleLink.peopleType.residentCanAdd && (
                <Menu.Item onPress={() => onSelectRemoveSelf()}>
                  Remove Self
                </Menu.Item>
              )}
            </Menu>
          )}
        </View>
      </View>

      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Box w="100%" h={60} px={4} justifyContent="center">
            <Text
              style={{
                fontSize: 16,
              }}>
              Request access for
            </Text>
          </Box>
          <Actionsheet.Item
            onPress={() => {
              onClose();
              setConfirmMode(E_CONFIRM_MODES.TODAY);
              setConfirmDialogShown(true);
            }}>
            Today
          </Actionsheet.Item>
          <Actionsheet.Item
            onPress={() => {
              onClose();
              setConfirmMode(E_CONFIRM_MODES.TOMMOROW);
              setConfirmDialogShown(true);
            }}>
            Tommorow
          </Actionsheet.Item>
          <Actionsheet.Item
            onPress={() => {
              onClose();
              onSelectCustom();
            }}>
            Custom time frame
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>

      <ConfirmationDialog
        isOpen={automatedCallsOpen}
        onClose={onAutomatedCallsClose}
        onYesPressed={onAutomatedCallsConfirm}
        onNoPressed={onNoPressed}
        description={`Do you really want to ${message} automated calls ?`}
      />

      <ConfirmationDialog
        isOpen={editDialogOpen}
        onClose={onEditDialogClose}
        onYesPressed={onEditDialogConfirm}
        onNoPressed={onNoPressed}
        description={'Would you like to set calls start and stop time ?'}
      />
      <ConfirmationDialog
        isOpen={confirmDialogShown}
        onClose={() => setConfirmDialogShown(false)}
        onYesPressed={onConfirmRequestAccess}
        onNoPressed={() => setConfirmDialogShown(false)}
        description={`Would you like to request access for ${confirmMode} ?`}
      />
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    margin: 8,
  },
  unitsCardImage: {
    width: 156,
    height: 156,
    marginLeft: 4,
    borderRadius: 6,
    marginTop: 0,
  },
  bgGray: {
    backgroundColor: '#d1d5db',
  },
  rounded: {
    borderRadius: 8,
  },
  margin: {
    margin: 8,
  },
  borderGreen: {
    borderWidth: 2,
    borderColor: '#34d399',
  },
  flexRow: {
    flexDirection: 'row',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  itemsCenter: {
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
  },
  marginLeft: {
    marginLeft: 8,
  },
  flexCol: {
    flexDirection: 'column',
  },
  justifyAround: {
    justifyContent: 'space-around',
  },
  textGreen: {
    color: '#34d399',
  },
  textXs: {
    fontSize: 12,
  },
  flex1: {
    flex: 1,
  },
  marginTop: {
    marginTop: 4,
  },
  roundedFull: {
    borderRadius: 9999,
  },
  textLg: {
    fontSize: 18,
  },
  marginY: {
    marginVertical: 4,
  },
  bgGreen: {
    backgroundColor: '#34d399',
  },
  bgRed: {
    backgroundColor: '#f87171',
  },
  textWhite: {
    color: '#ffffff',
  },
  marginX: {
    marginHorizontal: 4,
  },
  marginRight: {
    marginRight: 4,
  },
  paddingX: {
    paddingHorizontal: 4,
  },
  padding: {
    padding: 4,
  },
  paddingTop: {
    paddingTop: 16,
  },
  paddingTop16: {
    paddingTop: 64,
  },
  paddingRight: {
    paddingRight: 16,
  },
  paddingLeft: {
    paddingLeft: 8,
  },
});

export default UnitListItem;
