import React, {useState} from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import {styles as appStyles} from '../../../styles';
import {Navbar} from '../../../components';
import {useEffect} from 'react';
import {showErrorMessage} from '../../../service/flashMessage';
import {Pet} from '../types';
import {fetchPetById} from '../../../api/pets';
import Container from '../../../components/Container';
import ZoomableImage from '../../../components/ZoomableImage';

const PetDetail = ({navigation, route}) => {
  const {petId} = route.params;
  const [isLoading, setIsLoading] = useState(true);
  const [pet, setPet] = useState<Pet>({});

  const fetchPet = async (id: string) => {
    try {
      const res: Pet = await fetchPetById(id);
      setPet(res);
      setIsLoading(false);
    } catch (error) {
      showErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchPet(petId);
  }, [petId]);

  return (
    <Container>
      <Navbar navigation={navigation} title="Pet details" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Card style={styles.card}>
            <View style={styles.cardHeader}>
              <ZoomableImage
                title={pet.petName}
                source={{
                  uri: pet.picture
                    ? `data:image/jpeg;base64,${pet.picture}`
                    : 'https://nichemodels.co/wp-content/uploads/2019/03/user-dummy-pic.png',
                }}
                style={appStyles.image}
              />
              <View style={styles.cardHeaderText}>
                <Text style={appStyles.userName}>{pet.petName}</Text>
                <Text style={appStyles.email}>{pet.petOwnerName}</Text>
              </View>
            </View>
          </Card>
          <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
            <Card style={styles.detailsCard}>
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Type of pet</Text>
                <Text style={appStyles.detailsText}>{pet.typeOfPet}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Color</Text>
                <Text style={appStyles.detailsText}>{pet.colorOfPet}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Weight</Text>
                <Text style={appStyles.detailsText}>{pet.colorOfPet}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Weight</Text>
                <Text style={appStyles.detailsText}>{pet.petWeight}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vetinerian</Text>
                <Text style={appStyles.detailsText}>{pet.vetinerian}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vet Phone Number</Text>
                <Text style={appStyles.detailsText}>{pet.vetPhoneNumber}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Pet Tag Number</Text>
                <Text style={appStyles.detailsText}>{pet.petTagNumber}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vaccine one</Text>
                <Text style={appStyles.detailsText}>{pet.vacineOne}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vacine One Expiration</Text>
                <Text style={appStyles.detailsText}>
                  {new Date(pet.vacineOneExpiration).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vaccine two</Text>
                <Text style={appStyles.detailsText}>{pet.vacineTwo}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vacine Two Expiration</Text>
                <Text style={appStyles.detailsText}>
                  {new Date(pet.vacineTwoExpiration).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vaccine three</Text>
                <Text style={appStyles.detailsText}>{pet.vacineThree}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vacine Three Expiration</Text>
                <Text style={appStyles.detailsText}>
                  {new Date(pet.vacineThreeExppiration).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vaccine four</Text>
                <Text style={appStyles.detailsText}>{pet.vacineFour}</Text>
              </View>
              <Divider />
              <View style={styles.detailRow}>
                <Text style={appStyles.textLabel}>Vacine Four Expiration</Text>
                <Text style={appStyles.detailsText}>
                  {new Date(pet.vacineFourExpiration).toLocaleDateString()}
                </Text>
              </View>
            </Card>
          </ScrollView>
        </>
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  card: {
    marginHorizontal: 8,
    marginTop: 16,
    paddingBottom: 8,
  },
  cardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
  },
  cardHeaderText: {
    flex: 1,
    marginLeft: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    paddingVertical: 16,
  },
  detailsCard: {
    padding: 8,
    marginBottom: 32,
  },
  detailRow: {
    flexDirection: 'row',
    marginVertical: 12,
  },
});

export default PetDetail;
