import React, {useEffect, useState} from 'react';
import Container from '../../components/Container';
import {LoadingSpinner, Navbar} from '../../components';
import {useNavigation} from '@react-navigation/core';
import {ScrollView} from 'react-native';
import useColorModeValue from '../../components/useColorModeValue';
import {Box} from 'native-base';
import PermissionRow from './components/PermissionRow';
import { useDispatch } from 'react-redux';
import { SetLastPermissionCheck } from '../../modules/Auth/reducer';

const DevicePermissions = () => {
  const navigation = useNavigation();
  const dispatch  = useDispatch();
  const [loading, setLoading] = useState(true);

  const [permissions, setPermissions] = useState([
    {
      id: 'Notifications',
      label: 'Notifications',
      message: null,
      description:
        'Allows us to let you know you have a Visitor and or Package.',
    },
    {
      id: 'Camera',
      label: 'Camera',
      message: null,
      description: 'For taking your picture and other pictures',
    },
  ]);

  const requestPermission = (permissionId: string) => {
    if (permissionId === 'Notifications') {
      // @ts-ignore
      Notification.requestPermission().then((result) => {
        setPermissions((prevPermissions) =>
          prevPermissions.map((permission) =>
            permission.id === 'Notifications'
              ? { ...permission, message: result }
              : permission
          )
        );
      });
    } else if (permissionId === 'Camera') {
      // @ts-ignore
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          setPermissions((prevPermissions) =>
            prevPermissions.map((permission) =>
              permission.id === 'Camera'
                ? { ...permission, message: 'granted' }
                : permission
            )
          );
        })
        .catch(() => {
          setPermissions((prevPermissions) =>
            prevPermissions.map((permission) =>
              permission.id === 'Camera'
                ? { ...permission, message: 'denied' }
                : permission
            )
          );
        });
    }
  };

  const checkMultiplePermissions = async() => {
    setLoading(true);
    // @ts-ignore
   await navigator.permissions.query({ name: 'notifications' }).then((result) => {
      setPermissions((prevPermissions) =>
        prevPermissions.map((permission) =>
          permission.id === 'Notifications'
            ? { ...permission, message: result.state }
            : permission
        )
      );
    });

    // @ts-ignore
   await navigator.permissions.query({ name: 'camera' }).then((result) => {
      setPermissions((prevPermissions) =>
        prevPermissions.map((permission) =>
          permission.id === 'Camera'
            ? { ...permission, message: result.state }
            : permission
        )
      );
    });
    // @ts-ignore
    dispatch(SetLastPermissionCheck(new Date().getTime()));

    setLoading(false);
  }

  useEffect(()=>{
    checkMultiplePermissions();
  },[]);

  return (
    <Container>
      <Navbar title="Device Permissions" navigation={navigation} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ScrollView>
          <Box
            bg={useColorModeValue('gray.50', 'gray.700')}
            style={{marginHorizontal: 16, marginTop: 16}}>
            {permissions.map((permission, index) => {
              return (
                <PermissionRow
                  permission={permission}
                  key={index}
                  onPressRequest={() => requestPermission(permission.id)}
                />
              );
            })}
          </Box>
        </ScrollView>
      )}
    </Container>
  );
};

export default DevicePermissions;
