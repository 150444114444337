import React from 'react';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import {View} from 'react-native';
import {Accordion, Box} from 'native-base';
import Ionicon from 'react-native-vector-icons/Ionicons';
import {colorSwatches} from '../../../styles';
import {DevicePermission} from '../types';

const PermissionRow = ({
  permission,
  onPressRequest,
}: {
  permission: DevicePermission;
  onPressRequest: (permission) => void;
}) => {
  return (
    <View
      key={permission.id}
      style={{
        padding: 16,
        borderWidth: 1,
        borderColor: colorSwatches.gray.color,
        marginVertical: 4,
        borderRadius: 6,
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          columnGap: 16,
        }}>
        {permission.message === 'granted' ? (
          <Ionicon
            name="checkmark-circle"
            size={20}
            color={colorSwatches.green.color}
          />
        ) : (
          <Ionicon
            name="close-circle"
            size={20}
            color={colorSwatches.red[400]}
          />
        )}
        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 2,
          }}>
          <Text fontWeight={600}>{permission.label}</Text>
          <Text style={{}}>{permission.description}</Text>
        </View>
        {permission.message !== 'granted' && (
          <Button onPress={onPressRequest}>Request</Button>
        )}
      </View>
    </View>
  );
};

export default PermissionRow;
