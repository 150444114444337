import React from 'react';
import {TouchableOpacity, View, StyleSheet} from 'react-native';
import {ThemedSwipeablePanel} from '.';
import {styles} from '../styles';
import Text from '../components/Text';

const OptionsSheet = ({
  isPanelActive,
  setIsActive,
  title,
  labelOne,
  handlerOne,
  labelTwo,
  handlerTwo,
  labelThree,
  handlerThree,
}: {
  isPanelActive: boolean;
  setIsActive: any;
  title: string;
  labelOne: string;
  handlerOne: any;
  labelTwo: string;
  handlerTwo: any;
  labelThree?: string;
  handlerThree?: any;
}) => {
  return (
    <ThemedSwipeablePanel
      onlySmall
      fullWidth
      isActive={isPanelActive}
      onClose={() => setIsActive(false)}
      showCloseButton
      closeOnTouchOutside={true}
      closeIconStyle={styles.closeIcon}
      closeRootStyle={styles.closeRoot}
      noBar
      style={{height: 680}}>
      <View style={localStyles.container}>
        <Text style={styles.sheetTitle}>{title}</Text>
        <View style={localStyles.buttonContainer}>
          <TouchableOpacity
            onPress={() => {
              setIsActive(false);
              handlerOne();
            }}>
            <Text style={styles.sheetLabel}>{labelOne}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIsActive(false);
              handlerTwo();
            }}>
            <Text style={styles.sheetLabel}>{labelTwo}</Text>
          </TouchableOpacity>
          {labelThree && handlerThree && (
            <TouchableOpacity
              onPress={() => {
                setIsActive(false);
                handlerThree();
              }}>
              <Text style={styles.sheetLabel}>{labelThree}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ThemedSwipeablePanel>
  );
};

const localStyles = StyleSheet.create({
  container: {
    marginTop: 12,
    marginLeft: 12,
  },
  buttonContainer: {
    marginTop: 12,
  },
  closeIcon: {
    backgroundColor: 'gray',
  },
  closeRoot: {
    backgroundColor: 'white',
  },
});

export default OptionsSheet;
