// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {View, ScrollView, Image, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {Navbar} from '../../../components';
import {useSelector} from 'react-redux';
import {fields, initialPetState} from '../utils';
import DateTimeInput from '../../../components/DateTimeInput';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/Feather';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import {createPet, updatePet} from '../../../api/pets';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import {RootStore} from '../../../redux/store';
import {Pet} from '../../../@types';
import FormContainer from '../../../components/FormContainer';
import Container from '../../../components/Container';
import Content from '../../../navigation/Content';
import PhotoCaptureSheet from '../../../components/PhotoCaptureSheet';
import formatPhoneNumber from '../../../utils/phoneNumberFormatter';

const CreatePet = ({route, navigation}) => {
  const {pet} = route.params;
  const isInEditMode = pet != null;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userId = useSelector((state: RootStore) => state.auth.user.id);
  const unitId = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0].unit.id;
  const [petState, setPetState] = useState({
    ...initialPetState,
    createdLogon: userId,
    lastLogon: userId,
    unitId: unitId,
  });
  const [imagePanelActive, setImagePanelActive] = useState(false);

  const updateStateWithImages = (image, stateToUpdate) => {
    setPetState({
      ...petState,
      [stateToUpdate]: image,
    });
  };

  useEffect(() => {
    if (isInEditMode) {
      setPetState({...petState, ...pet});
      navigation.setOptions({
        title: 'Edit Pet',
      });
    }
  }, []);

  const collectUserInput = async (data: Pet) => {
    //Convert petWeight to number
    const petData: Pet = {
      ...data,
      petWeight: parseInt(data.petWeight.toString(), 10),
    };
    setIsSubmitting(true);
    try {
      isInEditMode
        ? await updatePet(petData, petData.id)
        : await createPet(petData);
      showSuccessMessage('Pet saved successfully');
      navigation.goBack();
    } catch (e) {
      showErrorMessage(e.message);
    }
    setIsSubmitting(false);
  };

  return (
    <Container>
      <Content>
        <Navbar
          navigation={navigation}
          title={isInEditMode ? 'Edit pet' : 'Add pet'}
        />
        <FormContainer>
          <ScrollView
            style={styles.scrollView}
            showsVerticalScrollIndicator={false}>
            <>
              {fields.map(field => {
                if (field.type === 'textInput') {
                  return (
                    <View key={field.fieldName}>
                      <Text style={styles.fieldLabel}>{field.fieldName}</Text>
                      <Input
                        placeholder={`Enter ${field.fieldName}`}
                        value={petState[field.state]}
                        onChangeText={text => {
                          const _text =
                            field.fieldName === 'Vet Phone Number'
                              ? formatPhoneNumber(text)
                              : text;
                          setPetState({...petState, [field.state]: _text});
                        }}
                        keyboardType={field.keyboardType}
                      />
                    </View>
                  );
                }
                if (field.type === 'imageCapture') {
                  return (
                    <View key={field.fieldName}>
                      <Text style={styles.fieldLabel}>{field.fieldName}</Text>
                      <TouchableOpacity
                        onPress={() => setImagePanelActive(true)}
                        style={styles.pictureUploadContainer}>
                        {petState[field.state] ? (
                          <Image
                            source={{
                              uri: `data:image/jpeg;base64,${
                                petState[field.state]
                              }`,
                            }}
                            style={styles.image}
                          />
                        ) : (
                          <Icon
                            name="image"
                            size={24}
                            style={styles.pictureUploadIcon}
                          />
                        )}
                      </TouchableOpacity>
                    </View>
                  );
                }
              })}
            </>
            <>
              <Text style={styles.fieldLabel}>Vaccine one </Text>
              <Input
                placeholder={'Enter vacine one'}
                value={petState.vacineOne}
                onChangeText={text =>
                  setPetState({...petState, vacineOne: text})
                }
              />
              <DateTimeInput
                label="Vacine One Expiration"
                value={new Date(petState.vacineOneExpiration)}
                onConfirm={date => {
                  setPetState({
                    ...petState,
                    vacineOneExpiration: new Date(date).toISOString(),
                  });
                }}
                mode="date"
              />
            </>
            <>
              <Text style={styles.fieldLabel}>Vaccine two </Text>
              <Input
                placeholder={'Enter vacine two'}
                value={petState.vacineTwo}
                onChangeText={text =>
                  setPetState({...petState, vacineTwo: text})
                }
              />
              <DateTimeInput
                label="Vacine Two Expiration"
                value={new Date(petState.vacineTwoExpiration)}
                onConfirm={date => {
                  setPetState({
                    ...petState,
                    vacineTwoExpiration: new Date(date).toISOString(),
                  });
                }}
                mode="date"
              />
            </>
            <>
              <Text style={styles.fieldLabel}>Vaccine three </Text>
              <Input
                placeholder={'Enter vacine two'}
                value={petState.vacineThree}
                onChangeText={text =>
                  setPetState({...petState, vacineThree: text})
                }
              />
              <DateTimeInput
                label="Vacine Three Expiration"
                value={new Date(petState.vacineThreeExppiration)}
                onConfirm={date => {
                  setPetState({
                    ...petState,
                    vacineThreeExppiration: new Date(date).toISOString(),
                  });
                }}
                mode="date"
              />
            </>
            <>
              <Text style={styles.fieldLabel}>Vaccine four </Text>
              <Input
                placeholder={'Enter vacine four'}
                value={petState.vacineFour}
                onChangeText={text =>
                  setPetState({...petState, vacineFour: text})
                }
              />
              <DateTimeInput
                label="Vacine Four Expiration"
                value={new Date(petState.vacineFourExpiration)}
                onConfirm={date => {
                  setPetState({
                    ...petState,
                    vacineFourExpiration: new Date(date).toISOString(),
                  });
                }}
                mode="date"
              />
            </>
          </ScrollView>
          <PhotoCaptureSheet
            isPanelActive={imagePanelActive}
            setIsActive={(val: boolean) => setImagePanelActive(val)}
            onConfirmImage={(imgData: string) => {
              updateStateWithImages(imgData, 'picture');
            }}
          />
          <Button
            onPress={() => {
              setIsSubmitting(true);
              collectUserInput(petState);
            }}
            style={styles.submitButton}
            isLoading={isSubmitting}
            py="4"
            isLoadingText="Submitting ...">
            {isInEditMode ? 'Update ' : 'Save'}
          </Button>
        </FormContainer>
      </Content>
    </Container>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    marginHorizontal: 8,
    marginVertical: 16,
  },
  fieldLabel: {
    marginVertical: 8,
  },
  pictureUploadContainer: {
    // Add your styles here
  },
  image: {
    borderRadius: 8,
    width: 128,
    height: 96,
  },
  pictureUploadIcon: {
    // Add your styles here
  },
  submitButton: {
    marginHorizontal: 8,
    marginBottom: 80,
  },
});

export default CreatePet;
