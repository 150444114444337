// @ts-nocheck
import React from 'react';
import {usePackagesByPerson} from '../../../hooks/packages';
import {LoadingSpinner} from '../../../components';
import {FlatList, Platform} from 'react-native';
import Text from '../../../components/Text';
import {E_NAVIGATION_DESTINATIONS} from '../../../@types';
import {useFocusEffect, useNavigation} from '@react-navigation/native';
import PackagesItem from '../components/PackageItem';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {setDeliveriesTabSelected} from '../deliveriesReducer';
import {Package} from '../types';
import isWeb from '../../../utils/isWeb';

export default function Packages() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const personId = useSelector((state: RootStore) => state.auth.user.id);
  const [status, setStatus] = React.useState<'Unclaimed' | 'Claimed' | 'All'>(
    'Unclaimed',
  );
  const {isLoading, data, refetch, isRefetching} = usePackagesByPerson(
    personId,
    status === 'All' || status === 'Claimed',
    status === 'Claimed',
  );
  const packages: Array<Package> = data?.data;

  const enabledRef = React.useRef(false);

  useFocusEffect(
    React.useCallback(() => {
      if (enabledRef.current) {
        refetch();
      } else {
        enabledRef.current = true;
      }
    }, [refetch]),
  );

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(setDeliveriesTabSelected(false));
    });

    return unsubscribe;
  }, [dispatch, navigation]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <FlatList
      style={{
        marginBottom: Platform.OS === 'ios' ? 160 : isWeb ? 0 : 100,
        paddingHorizontal: 16,
      }}
      data={packages}
      ListEmptyComponent={<Text>No packages for you at the moment</Text>}
      renderItem={({item}) => (
        <PackagesItem
          packageItem={item}
          itemClickHandler={() =>
            navigation.navigate(E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS, {
              packageItem: item,
            })
          }
        />
      )}
      onRefresh={refetch}
      refreshing={isRefetching}
    />
  );
}
