// @ts-nocheck
import React, {useState} from 'react';
import {ScrollView, View, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {ActivityIndicator, Card, Divider} from 'react-native-paper';
import {styles} from '../../../styles';
import {Navbar} from '../../../components';
import {showErrorMessage} from '../../../service/flashMessage';
import {Storage} from '../types';
import {residentApiWithAuth} from '../../../api/resident';
import Container from '../../../components/Container';

const StorageDetail = ({navigation, route}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [storage, setStorage] = useState<Storage>(route.params.storage);

  const fetchStorage = async (id: string) => {
    try {
      let res = await residentApiWithAuth().get(`/storage/${id}`);
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        setStorage(result.data);
        setIsLoading(false);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <Container>
      <Navbar navigation={navigation} title="Storage details" />
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <ScrollView
            style={localStyles.scrollView}
            showsVerticalScrollIndicator={false}>
            <Card style={localStyles.card}>
              <View style={localStyles.flexRowBetween} />
              <View style={localStyles.flexRow}>
                <Text style={styles.textLabel}>Storage bin</Text>
                <Text style={styles.detailsText}>{storage.storageBin}</Text>
              </View>
              <Divider />
              <View style={localStyles.flexRow}>
                <Text style={styles.textLabel}>Bin Location</Text>
                <Text style={styles.detailsText}>{storage.binLocation}</Text>
              </View>
              <Divider />
              <View style={localStyles.flexRow}>
                <Text style={styles.textLabel}>Dateframe start date</Text>
                <Text style={styles.detailsText}>
                  {new Date(storage?.dateFrame?.startDate).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={localStyles.flexRow}>
                <Text style={styles.textLabel}>Dateframe end date</Text>
                <Text style={styles.detailsText}>
                  {new Date(storage?.dateFrame?.endDate).toLocaleDateString()}
                </Text>
              </View>
              <Divider />
              <View style={localStyles.flexRow}>
                <Text style={styles.textLabel}>Dateframe description</Text>
                <Text style={styles.detailsText}>
                  {storage?.dateFrame?.description}
                </Text>
              </View>
              <Divider />
              <View style={localStyles.flexRow}>
                <Text style={styles.textLabel}>Description</Text>
                <Text style={styles.detailsText}>{storage.description}</Text>
              </View>
              <Divider />
              <View style={localStyles.flexRow}>
                <Text style={styles.textLabel}>Monthly cost</Text>
                <Text style={styles.detailsText}>{storage.monthlyCost}</Text>
              </View>
              <Divider />
            </Card>
          </ScrollView>
        </>
      )}
    </Container>
  );
};

const localStyles = StyleSheet.create({
  scrollView: {
    marginHorizontal: 8,
    paddingVertical: 16,
  },
  card: {
    padding: 8,
    marginBottom: 32,
  },
  flexRowBetween: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexRow: {
    flexDirection: 'row',
    marginVertical: 12,
  },
});

export default StorageDetail;
