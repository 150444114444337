import React from 'react';
import {useWindowDimensions, View} from 'react-native';
import Container from '../../components/Container';
import {LoadingSpinner, Navbar} from '../../components';
import {useNavigation} from '@react-navigation/native';
import WebView from 'react-native-webview';
import isWeb from '../../utils/isWeb';

const PrivacyPolicy = () => {
  const navigation = useNavigation();
 const [loading, setLoading] = React.useState(true);
 const {height} = useWindowDimensions();

  return (
    <Container>
      <Navbar navigation={navigation} title="Privacy Policy" />
      {loading && (
        <View style={{ marginTop: height * 0.2, paddingBottom: 20}}>
          <LoadingSpinner />
        </View>
      )}

      {isWeb ? (
        <iframe
          src="https://asterixsoftware.com/privacy/myworxs"
          style={{width: '100%', height: '100%', border: 'none'}}
          onLoad={() => setLoading(false)}
        />
      ) : (
        <WebView
          source={{uri: 'https://asterixsoftware.com/privacy/myworxs'}}
          style={{flex: 1}}
          onLoad={() => setLoading(false)}
        />
      )}
    </Container>
  );
};

export default PrivacyPolicy;
