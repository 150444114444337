import React, {useState, useEffect} from 'react';
import {
  SafeAreaView,
  ScrollView,
  View,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';
import Text from '../../../components/Text';
import * as yup from 'yup';
import Button from '../../../components/Button';
import Heading from '../../../components/Heading';
import HStack from '../../../components/HStack';
import {
  RegisterActionBar,
  RegisterPrevButton,
} from '../../../components';
import {useSelector} from 'react-redux';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {RootStore} from '../../../redux/store';
import {residentApiWithAuth} from '../../../api/resident';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import ScreenWrapper from '../components/ScreenWrapper';
import isWeb from '../../../utils/isWeb';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextInput from '../../../components/TextInput';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { initiateSMS } from '../../App/service';

const phoneConfirmationValidationSchema = yup.object().shape({
  phoneConfirmationCode: yup.string().required('Code is required'),
});

const PhoneConfirmation = ({navigation, route}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const registerValues = useSelector((state: RootStore) => state.register);
  const {personId, errors} = route.params;
  const {control, handleSubmit} = useForm({
    defaultValues: {
      phoneConfirmationCode: '',
    },
    resolver: yupResolver(phoneConfirmationValidationSchema),
  });

  const {width} = useWindowDimensions();

  const resendPhoneCode = async () => {
    setIsResending(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/sendPhoneCode/${personId}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('The code has been sent to your cell phone');
      }
    } catch (error) {
      showErrorMessage(error.message);
    } finally {
      setIsResending(false);
    }
  };

  const confirmPhoneCode = async ({
    phoneConfirmationCode,
  }: {
    phoneConfirmationCode: string;
  }) => {
    setIsConfirming(true);
    try {
      const res = await residentApiWithAuth().post(
        `/people/confirmPhone/${personId}/${phoneConfirmationCode}`,
      );
      const result = res.data;
      if (result.errors) {
        throw new Error(result.errors[0]);
      } else {
        showSuccessMessage('Phone confirmed successfully');
        navigation.navigate('Email Confirmation', {personId});
      }
    } catch (error) {
      console.log('error', error.response);
      showErrorMessage('Invalid code');
    } finally {
      setIsConfirming(false);
    }
  };

  const onClose = () => setIsOpen(false);

  const onYesPressed = () => {
    navigation.navigate('Email Confirmation', {personId});
  };

  const onNoPressed = () => {
    // Send text message to [954-449-6239]
    initiateSMS('954-449-6239', 'START');
  };

  useEffect(() => {
    if(errors?.length > 0){
      setIsOpen(true)
    }
  }, [errors]);

  return (
    <ScreenWrapper>
      <SafeAreaView
        style={[
          styles.safeAreaView,
          isWeb && {backgroundColor: '#ffffff', width: width / 3},
        ]}>
        <ScrollView
          style={styles.scrollView}
          keyboardShouldPersistTaps="handled">
          <RegisterActionBar
            step={5}
            hasSkip={true}
            onSkip={() => navigation.navigate('Email Confirmation', {personId})}
          />
          <View style={styles.container}>
            <Heading size="lg">Phone Confirmation</Heading>
            {isResending && (
              <View style={styles.resendingContainer}>
                <Text style={styles.resendingText}>
                  Sending code ...
                </Text>
              </View>
            )}
            <View style={styles.codeContainer}>
              <Ionicons
                name="checkmark-circle-outline"
                color="#47B275"
                size={24}
              />
              <Text style={styles.codeText}>
                {`Type the code you received via ${registerValues.phone1}.`}
              </Text>
            </View>
            <View style={styles.inputContainer}>
              <TextInput
                //@ts-ignore
                control={control}
                name="phoneConfirmationCode"
                placeholder="e.g 1234X"
                label="Phone Code"
              />
              <Button
                onPress={() => resendPhoneCode()}
                style={styles.resendButton}
                variant="link">
                Resend Code
              </Button>
            </View>
          </View>
        </ScrollView>
        <HStack style={styles.hStack}>
          <RegisterPrevButton />
          <Button
            // isDisabled={!isValid}
            onPress={handleSubmit(confirmPhoneCode)}
            isLoading={isConfirming}
            isLoadingText="Confirming code ...."
            style={styles.nextButton}>
            Next
          </Button>
        </HStack>
      </SafeAreaView>
      {errors?.length > 0 && (
        <ConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onNoPressed={onNoPressed}
          onYesPressed={onYesPressed}
          negativeLabel='UNBLOCK '
          positiveLabel='SKIP'
          description={
            errors[0] === 'Attempt to send to unsubscribed recipient'
              ? 'This phone number has block our SMS service.Press UNBLOCK to restart or click skip button, and you will not receive any SMS messages.'
              : 'Invalid phone number'
          }
        />
      )}
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    marginVertical: 16,
  },
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  container: {
    marginHorizontal: 24,
    marginTop: 16,
  },
  resendingContainer: {
    flexDirection: 'row',
    marginTop: 32,
  },
  resendingText: {
    marginBottom: 16,
    marginLeft: 8,
    fontSize: 18,
  },
  codeContainer: {
    flexDirection: 'row',
    marginTop: 32,
    marginBottom: 8,
  },
  codeText: {
    marginLeft: 8,
    fontSize: 18,
  },
  inputContainer: {
    marginTop: 8,
    flex: 1,
  },
  resendButton: {
    marginTop: 24,
    flex: 1,
  },
  hStack: {
    margin: 24,
    marginTop: 32,
    marginBottom: 36
  },
  nextButton: {
    marginHorizontal: 8,
    flex: 1,
  },
});

export default PhoneConfirmation;
