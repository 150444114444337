// @ts-nocheck
import React, {useState, useEffect, useCallback} from 'react';
import {ScrollView, View, TouchableOpacity, Image, StyleSheet} from 'react-native';
import Text from '../../../components/Text';
import {DateFrameChooser, Navbar} from '../../../components';
import {styles as globalStyles} from '../../../styles';
import {useDispatch, useSelector} from 'react-redux';
import {initialVehicleState} from '../utils';
import {showErrorMessage} from '../../../service/flashMessage';
import Icon from 'react-native-vector-icons/Feather';
import {fetchDateFramesByUnit} from '../../../api/dataframe';
import {RootStore} from '../../../redux/store';
import {useFocusEffect} from '@react-navigation/native';
import {AddVehicle, UpdateVehicle} from '../vehicleReducer';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Container from '../../../components/Container';
import PhotoCaptureSheet from '../../../components/PhotoCaptureSheet';
import FormContainer from '../../../components/FormContainer';
import licenseStates from '../../../utils/licenseStates';

const CreateVehicle = ({route, navigation}) => {
  const {vehicle} = route.params;
  const isInEditMode = vehicle != null;
  const userId = useSelector((state: RootStore) => state.auth.user.id);
  const unitId = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0].unit.id;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vehicleState, setVehicleState] = useState({
    ...initialVehicleState,
    createdLogon: userId,
    lastLogon: userId,
    unitId: unitId,
  });

  const [imagePanelActive, setImagePanelActive] = useState(false);
  const [dateFrames, setDateFrames] = useState([]);
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      fetchDateFrames();
    }, []),
  );

  const fetchDateFrames = async () => {
    try {
      let res = await fetchDateFramesByUnit(1, unitId);
      setDateFrames(res);
      // selectNewestDateFrame(dateFrames, res);
    } catch (error) {
      console.log('Failed to get dateframes' + error);
    }
  };

  const selectNewestDateFrame = (prevDateFrames, currentDateFrames) => {
    console.log('navigation', navigation.getState());
    const prevIds = prevDateFrames.map(prevDateFrame => prevDateFrame.id);
    const currentIds = currentDateFrames.map(
      currentDateFrame => currentDateFrame.id,
    );
    if (prevDateFrames.length === currentDateFrames.length) {
      console.log('No DateFrame added');
    } else {
      const newestDateFrame = currentIds.filter(
        currentId => !prevIds.includes(currentId),
      );
      if (!!newestDateFrame) {
        console.log('newestDateFrame[0]', newestDateFrame[0]);
        setVehicleState({...vehicleState, dateFrameId: newestDateFrame[0]});
      }
    }
  };

  useEffect(() => {
    if (isInEditMode) {
      setVehicleState({...vehicle, dateFrameId: vehicle.dateFrameId});
      navigation.setOptions({title: 'Edit Vehicle'});
    }
  }, []);

  const collectUserInput = async vehicleData => {
    try {
      if (isInEditMode) {
        dispatch(UpdateVehicle(vehicleData, vehicleData.id)).finally(() => {
          setIsSubmitting(false);
        });
      } else {
        dispatch(AddVehicle(vehicleData)).finally(() => {
          setIsSubmitting(false);
        });
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  return (
    <Container>
      <Navbar
        navigation={navigation}
        title={isInEditMode ? 'Edit vehicle' : 'Add vehicle'}
      />
      <FormContainer>
        <ScrollView style={styles.scrollView}>
          <Text style={styles.my2}>Vehicle owner</Text>
          <Input
            placeholder="Enter vehicle owner"
            value={vehicleState.vehicleOwner}
            onChangeText={text =>
              setVehicleState({...vehicleState, vehicleOwner: text})
            }
          />
          <View>
            <Text style={styles.my2}>Vehicle picture</Text>
            <TouchableOpacity
              onPress={() => setImagePanelActive(true)}
              style={styles.pictureUploadContainer}>
              {vehicleState.vehicleImage ? (
                <Image
                  source={{
                    uri: `data:image/jpeg;base64,${vehicleState.vehicleImage}`,
                  }}
                  style={styles.vehicleImage}
                />
              ) : (
                <Icon name="image" size={24} style={styles.pictureUploadIcon} />
              )}
            </TouchableOpacity>
          </View>
          <Text style={styles.my2}>License plate</Text>
          <Input
            placeholder="Enter license plate"
            value={vehicleState.licensePlate}
            onChangeText={text =>
              setVehicleState({...vehicleState, licensePlate: text})
            }
          />
          <Text style={styles.mt4}>License plate state</Text>
          <Select
            minWidth={200}
            selectedValue={vehicleState.licenseState}
            placeholder="Select state"
            onValueChange={val =>
              setVehicleState({...vehicleState, licenseState: val})
            }>
            {licenseStates.map(state => (
              <Select.Item key={state} label={state} value={state} />
            ))}
          </Select>
          <Text style={styles.my2}>Make of vehicle</Text>
          <Input
            placeholder="Enter make of vehicle"
            value={vehicleState.makeOfVehicle}
            onChangeText={text =>
              setVehicleState({...vehicleState, makeOfVehicle: text})
            }
          />
          <Text style={styles.my2}>Model of vehicle</Text>
          <Input
            placeholder="Enter model of vehicle"
            value={vehicleState.modelOfVehicle}
            onChangeText={text =>
              setVehicleState({...vehicleState, modelOfVehicle: text})
            }
          />
          <Text style={styles.my2}>Year of vehicle</Text>
          <Input
            placeholder="Enter year of vehicle"
            value={vehicleState.yearOfVehicle}
            keyboardType="number-pad"
            onChangeText={text =>
              setVehicleState({...vehicleState, yearOfVehicle: text})
            }
          />
          <Text style={styles.my2}>Color of vehicle</Text>
          <Input
            placeholder="Enter color of vehicle"
            value={vehicleState.colorOfVehicle}
            onChangeText={text =>
              setVehicleState({...vehicleState, colorOfVehicle: text})
            }
          />
          <Text style={styles.my2}>Vehicle security pass</Text>
          <Input
            placeholder="Enter vehicle security pass"
            value={vehicleState.vehicleSecurityPass}
            onChangeText={text =>
              setVehicleState({...vehicleState, vehicleSecurityPass: text})
            }
          />
          {isInEditMode ? (
            <View>
              <Text style={styles.my2}>Dateframe</Text>
              <Input
                placeholder="Enter dateframe"
                value={
                  vehicleState.dateFrame?.shortDescription
                    ? vehicleState.dateFrame?.shortDescription
                    : `${new Date(
                        vehicleState.dateFrame?.startDate,
                      ).toLocaleDateString()}-${new Date(
                        vehicleState.dateFrame?.endDate,
                      ).toLocaleDateString()}`
                }
                isDisabled={false}
              />
            </View>
          ) : (
            <DateFrameChooser
              dateFrames={dateFrames}
              selectHandler={(dateFrame: string) =>
                setVehicleState({...vehicleState, dateFrameId: dateFrame})
              }
              addClickHandler={() =>
                navigation.navigate('Create Date Frame', {unitId})
              }
              selectedDateFrame={vehicleState.dateFrameId}
            />
          )}
          <Text style={styles.my2}>Valet Vehicle No</Text>
          <Input
            placeholder="Enter valet Vehicle No"
            value={vehicleState.valetVehicleNo}
            onChangeText={text =>
              setVehicleState({...vehicleState, valetVehicleNo: text})
            }
          />
          <Text style={styles.my2}>Valet information</Text>
          <Input
            placeholder="Enter valet information"
            value={vehicleState.valetInformation}
            onChangeText={text =>
              setVehicleState({...vehicleState, valetInformation: text})
            }
          />
          <Text style={styles.my2}>Valet status</Text>
          <Input
            placeholder="Enter valet status"
            value={vehicleState.valetStatus}
            onChangeText={text =>
              setVehicleState({...vehicleState, valetStatus: text})
            }
          />
        </ScrollView>
        <Button
          onPress={() => {
            setIsSubmitting(true);
            collectUserInput(vehicleState);
          }}
          style={styles.button}
          isLoading={isSubmitting}
          py="4"
          isLoadingText="Submitting ...">
          {isInEditMode ? 'Update ' : 'Save'}
        </Button>
      </FormContainer>
      <PhotoCaptureSheet
        isPanelActive={imagePanelActive}
        setIsActive={(val: boolean) => setImagePanelActive(val)}
        onConfirmImage={(imgData: string) =>
          setVehicleState({...vehicleState, vehicleImage: imgData})
        }
      />
    </Container>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    marginHorizontal: 8,
    marginBottom: 16,
  },
  my2: {
    marginVertical: 8,
  },
  mt4: {
    marginTop: 16,
  },
  pictureUploadContainer: {
    // Add your styles here
  },
  vehicleImage: {
    borderRadius: 8,
    width: 128,
    height: 96,
  },
  pictureUploadIcon: {
    // Add your styles here
  },
  button: {
    marginHorizontal: 8,
    marginBottom: 80,
  },
});

export default CreateVehicle;
